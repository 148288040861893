import "./DownloadMobileApp.scss";
import * as React from "react";
import QRCode from "../../assets/images/qr-code.png";

interface DownloadMobileAppProps {
    isComponentVisible: boolean;
}

export const DownloadMobileApp: React.FC<DownloadMobileAppProps> = (props: DownloadMobileAppProps) => {

    return(
        <div className='download-mobile-app-component' >
            <div className='download-mobile'>
                <h2>Download our Mobile App</h2>
                <div className="qr-code">
                    <a href={`https://qrco.de/bd20er`} title="QR Code" target="_blank" style={{display: "inline"}}>
                        <img src={QRCode} alt="QR code" />
                    </a>
                </div>
            </div>
        </div>
    )
}
