import { Link } from "react-router-dom";
import "./ResourcesTile.scss";
import React from "react";

export type ResourcesTileProps = {
    link: string;
    text?: string;
    image?: string;
    className?: string;
    displayBorder?: boolean;
    color?: string;
    alignTextLeft?: boolean;
};

export function ResourcesTile(props: ResourcesTileProps) {
    return(
        <Link to={`${props.link}`} style={{ textDecoration: 'none' }} className={`resources-tile-component ${props.className ?? ''}`}>
            <div className={`resources-tile ${props.displayBorder ? 'bordered': ''} ${props.color ?? ''}`}>
                {props.image && <img className={"resources-tile-image"} src={props.image}/>}
                {props.text && <div className={`native-name-container body-medium-variant ${props.alignTextLeft ? "align-left" : ""}`}>{props.text}</div>}
            </div>
        </Link>
    )
}