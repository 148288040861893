import {IonButton} from "@ionic/react";
import greenCheckmark from "../../assets/images/green-circle-checkmark.svg";
import * as React from "react";
import {ExitButton} from "../ExitButton/ExitButton";

interface assessmentFinishedStepProps {
    closeModal: () => void;
}

export const AssessmentFinishedStep: React.FC<assessmentFinishedStepProps> = (props: assessmentFinishedStepProps) => {

    return(
        <div className="assessment-finished-step">
            <div className="exit-button-container"><ExitButton onClick={props.closeModal}/></div>
            <div className="journey-checkmark-container"><img alt={"Checkmark Complete"} src={greenCheckmark}/></div>
            <div className="assessment-title header-4">Assessment Complete!</div>
            <div className="assessment-text body-medium">Keep an eye out for more personalized content based on your responses.</div>
            <div className="assessment-continue-button-container">
                <IonButton className="assessment-continue-button button-medium-variant" onClick={props.closeModal}>
                    Done!
                </IonButton>
            </div>
        </div>
    )
}