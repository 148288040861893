import './SignUpForm.scss';
import React, {useContext} from 'react';
import {useState} from "react";
import { useHistory } from "react-router-dom";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {JourneyApiClient} from 'journey-shared/journey/JourneyApiClient';
import {Company} from "journey-shared/journey/ApiTypes";
import {IonItem, IonInput, IonButton, IonSelect, SelectChangeEventDetail, IonSelectOption, IonLabel} from "@ionic/react";
import {HOME_PATH} from "journey-shared/journey/utils";

interface Props {
    setIsLoading: (isLoading: boolean) => void;
    company: Company;
}

export const SignUpForm: React.FC<Props> = (props) => {

    const history = useHistory();
    const {handleUserError, handleGeneralMessage} = useContext(ApplicationContext);

    //register fields
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [workEmail, setWorkEmail] = useState<string>('');
    const [departmentId, setDepartmentId] = useState<any>('');
    const [tierId, setTierId] = useState<any>('');
    const [locationId, setLocationId] = useState<any>('');

    const submitDisabled = (props.company.departments?.length && !departmentId ? true : false)
        || (props.company.tiers?.length && !tierId ? true : false)
        || (props.company.locations?.length && !locationId ? true : false);

    const submitForm = async () => {
        if(workEmail && validateEmail(workEmail)) {
            props.setIsLoading(true);
            try {
                const user = await JourneyApiClient.registerUser({
                    firstName,
                    lastName,
                    email: workEmail,
                    marketingOptIn: true,
                    companyCode: props.company ? props.company.code : '',
                    departmentId: departmentId !== '' ? departmentId : null,
                    tierId: tierId !== '' ? tierId : null,
                    locationId: locationId !== '' ? locationId : null,
                    isDeleted: false
                });
                //register was successful
                history.replace(HOME_PATH);
                props.setIsLoading(false);
                handleGeneralMessage("Please login", "Registration was successful. Use your email to login.");
            } catch (error: any) {
                handleUserError("Application Error",`Your registration was unsuccessful. ${error?.message ?? ''}`);
                props.setIsLoading(false);
            }
        }
    };

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

        return (
            <div className='sign-up-form-component paper-container'>
                <form className='form' onSubmit={submitForm}>


                    <h1 className='sign-up-title'> Self Registration Form </h1>

                    <IonItem className="ion-input" mode="md" fill="outline">
                        <IonLabel position="floating">First Name *</IonLabel>
                        <IonInput required placeholder="First Name" value={firstName} onIonChange={e => setFirstName(e.detail.value!)} />
                    </IonItem>

                    <IonItem className="ion-input" mode="md" fill="outline">
                        <IonLabel position="floating">Last Name *</IonLabel>
                        <IonInput required placeholder="Last Name" value={lastName} onIonChange={e => setLastName(e.detail.value!)} />
                    </IonItem>

                    <IonItem className="ion-input" mode="md" fill="outline">
                        <IonLabel position="floating">Email *</IonLabel>
                        <IonInput required placeholder="Email" type="email" autocomplete="email" inputmode="email" value={workEmail} onIonChange={e => setWorkEmail(e.detail.value!)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" />
                    </IonItem>

                    <IonItem className="ion-input" mode="md" fill="outline">
                        <IonLabel position="floating">Company</IonLabel>
                        <IonInput disabled={true} placeholder="Company" value={props.company ? props.company.name : ""} />
                    </IonItem>

                    {props.company && props.company.departments?.length ? (
                        <IonSelect
                            placeholder='Department *'
                            onIonChange={(e: CustomEvent<SelectChangeEventDetail<number>>) => setDepartmentId(e.detail.value)}
                            value={departmentId}
                            className="ion-select">
                            {
                                props.company.departments.map((dept, index) => {
                                    return <IonSelectOption key={index} value={dept.id}>{dept.name}</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    ) : null}
                    {props.company && props.company.tiers?.length ? (
                        <IonSelect
                            placeholder='Tier *'
                            onIonChange={(e: CustomEvent<SelectChangeEventDetail<number>>) => setTierId(e.detail.value)}
                            value={tierId}
                            className="ion-select">
                            {
                                props.company.tiers.map((tier, index) => {
                                    return <IonSelectOption key={index} value={tier.id}>{tier.name}</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    ) : null}
                    {props.company && props.company.locations?.length ? (
                        <IonSelect
                            placeholder='Location *'
                            onIonChange={(e: CustomEvent<SelectChangeEventDetail<number>>) => setLocationId(e.detail.value)}
                            value={locationId}
                            className="ion-select">
                            {
                                props.company.locations.map((location, index) => {
                                    return <IonSelectOption key={index} value={location.id}>{location.name}</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    ) : null}

                    <div className="email-notice">
                        Check your email inbox for instructions on how to start using Journey LIVE!
                    </div>

                    <IonButton type='submit' className='field paper-submit-button' disabled={submitDisabled} >
                        Submit
                    </IonButton>

                </form>
            </div>
        );
};
