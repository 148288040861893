import "./DailyJourney.scss";
import * as React from 'react';
import {useEffect} from "react";
import {ApiDailyJourney} from "journey-shared/journey/ApiTypes";
import {DailyVideo} from "../DailyVideo/DailyVideo";
import { DAILY_JOURNEY_PATH } from 'journey-shared/journey/utils';
import { ApplicationContext } from '../../misc/ApplicationContext';
import {useState} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import {LoginModalComponent} from "../LoginModalComponent/LoginModalComponent";
import AnalyticsService from "../../misc/AnalyticsService";
import ClientConfig from "journey-shared/journey/ClientConfig";
import {useLocation} from "react-router-dom";
import {ResourcesHelpTile} from "../ResourcesHelpTile/ResourcesHelpTile";
import MoreSupport from "../../assets/images/more-support-image.svg";
import ExpandMore from "../../assets/images/expand-more.svg";
import ScheduleAppointment from "../../assets/images/schedule-appointment-image.svg";
import AskAQuestion from "../../assets/images/ask-a-question.svg";
import {MuxAudioPlayer} from "../MuxAudioPlayer/MuxAudioPlayer";
import {ShareComponent} from "../ShareComponent/ShareComponent";
import {MoreVideosByTeacher} from "../MoreVideosByTeacher/MoreVideosByTeacher";
import { GetCompanyBenefitsInfoResponse } from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";

interface DailyJourneyProps {
    dailyJourney: ApiDailyJourney;
    showTruncatedArticle?: boolean;
    isComponentVisible: boolean;
    onAudioEnd?: () => void;
    playAudio?: boolean;
}

export const DailyJourney: React.FC<DailyJourneyProps> = (props: DailyJourneyProps) => {

    const {
        isMobileWidth,
        currentUser,
        openResourcesEmailFormModal,
        openScheduleAppointmentModal,
        openEapSupportPhonesModal,
    } = React.useContext(ApplicationContext);
    const formattedDate = new Date(props.dailyJourney.publicationDate);
    const [isReadMorePressed, setIsReadMorePressed] = useState<boolean>(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [benefitsInfo, setBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);
    const location = useLocation();
    const dateString = formattedDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
    const djRef = React.useRef<any>(null);
    const shareComponentUrl = currentUser ? `${ClientConfig.newFrontendRoot}${DAILY_JOURNEY_PATH}/${props.dailyJourney.id}?shared=${currentUser.id}`
                                : `${ClientConfig.newFrontendRoot}${DAILY_JOURNEY_PATH}/${props.dailyJourney.id}`;

    useEffect(() => {
        if (props.playAudio) {
            djRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [props.playAudio]);

    useEffect(() => {
        getResources();
    }, [props.isComponentVisible]);

    const getResources = async () => {
        const benefitsInfo = await JourneyApiClient.getCompanyBenefitsInfo();
        setBenefitsInfo(benefitsInfo);
    }

    async function handleButtonPressed () {
        await trackReadMoreButtonClicked();
        if(!currentUser){
            setIsLoginModalOpen(true);
            return;
        }
        setIsReadMorePressed(true);
    }

    async function trackReadMoreButtonClicked () {
        await AnalyticsService.trackUserAction("daily_journey_read_more_button_clicked",
          location.pathname, {daily_journey_id: props.dailyJourney.id});
    }

    return(
        <div className='daily-journey-component' ref={djRef}>
            <LoginModalComponent
                isComponentVisible={props.isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />
            <div className="two-panel-container">
                <div className="left-panel">
                    <div className='title-date-container'>
                        <div className={`title ${isMobileWidth ? "header-6" : "header-4"}`}>
                            {props.dailyJourney.theme}
                        </div>
                        <div className='date caption'>
                            Daily Journey: {dateString}
                        </div>

                    </div>

                    {props.dailyJourney.muxAudio &&
                        <div className={`mux-audio-player ${!props.showTruncatedArticle || isReadMorePressed ? "less-padding" : ""}
                        `}>
                            <MuxAudioPlayer
                                videoId={props.dailyJourney.muxAudio.id}
                                duration={props.dailyJourney.muxAudio.duration}
                                isLoading={false}
                                isComponentVisible={props.isComponentVisible}
                                muxPlaybackId={props.dailyJourney.muxAudio.muxPlaybackId}
                                muxVideoId={props.dailyJourney.muxAudio.muxVideoId}
                                title={props.dailyJourney.muxAudio.title}
                                currentUser={currentUser}
                                thumbnail={props.dailyJourney.muxAudio.thumbnailUrl}
                                autoplay={props.playAudio ?? false}
                                onAudioEnd={() => props.onAudioEnd?.()}/>
                        </div>
                    }

                    {(!props.showTruncatedArticle || isReadMorePressed) ?
                        <div className={`inner ${isMobileWidth ? "body-small" : "body-medium"}`}  dangerouslySetInnerHTML={{__html: props.dailyJourney.body ?? ""}} />
                        :
                        <div>
                            <div className='inner body-small'  dangerouslySetInnerHTML={{__html: props.dailyJourney.truncatedBody ?? ""}} />
                            <div className="more-text-button-container">
                                <IonButton fill={"clear"} onClick={handleButtonPressed} className="more-text-button button-medium-variant">
                                    read more <IonIcon className={"expand-more-icon"} src={ExpandMore}/>
                                </IonButton>
                            </div>
                        </div>
                    }

                    {
                        (props.dailyJourney.videoDescriptionNewApp && props.dailyJourney.videoCoverImageUrl) &&
                        <div className='inner'>
                            <h1>Got a few more?</h1>

                            <div className='video-info'>

                                <div className='column'>
                                    <img src={props.dailyJourney.videoCoverImageUrl} alt='Daily Journey VideoStyle Cover' />
                                </div>

                                <div className='column info' dangerouslySetInnerHTML={{__html: props.dailyJourney.videoDescriptionNewApp ?? ""}} />
                            </div>

                        </div>

                    }
                </div>
                <div className="right-panel">
                    {props.dailyJourney.muxVideo &&
                    <DailyVideo
                        className='daily-journey-video'
                        playVideoOnMute={!props.showTruncatedArticle}
                        muxVideo={props.dailyJourney.muxVideo}
                        currentUser={currentUser}
                        isDailyJourneyVideo={true}
                    />}
                    {props.dailyJourney.muxVideo?.teacher && 
                        <MoreVideosByTeacher teacher={props.dailyJourney.muxVideo?.teacher}/>
                    }
                    <ShareComponent
                        link={shareComponentUrl}
                        text={`Did you find this Daily Journey with ${props.dailyJourney.muxVideo?.teacher?.firstName ?? 'this teacher'} helpful? 
                            Share this practice with others who you think would enjoy it!`}
                    />
                    {currentUser && <div className="daily-journey-resources-help-tiles-container">
                        {currentUser.company?.hasEAP && <>
                            {currentUser.featureFlags.includes('EAPSupport') && <ResourcesHelpTile
                                topText={"Want More Support?"}
                                bottomText={"Call (866) 695 8862"}
                                image={MoreSupport}
                                onClick={() =>  window.open('tel:+18666958622',"_self")}
                            />}
                            {currentUser.featureFlags.includes('EAPScheduling') && <ResourcesHelpTile
                                topText={"Schedule"}
                                bottomText={"Appointment"}
                                image={ScheduleAppointment}
                                onClick={openScheduleAppointmentModal}
                            />}
                            {currentUser.featureFlags.includes('EAPQuestion') && <ResourcesHelpTile
                                topText={"Ask a Question"}
                                bottomText={""}
                                image={AskAQuestion}
                                onClick={openResourcesEmailFormModal}
                            />}
                        </>}

                        {benefitsInfo?.supportPhones.map((phone, i) => {
                            return <ResourcesHelpTile
                                key={i}
                                topText={phone.title}
                                bottomText={phone.subtitle}
                                image={MoreSupport}
                                onClick={openEapSupportPhonesModal}
                            />
                        })}
                    </div>}
                </div>
            </div>

            <div className='divider' />
        </div>
    );
}
