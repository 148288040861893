import React, {useContext} from "react";
import {IonButton, IonButtons, IonHeader, IonToolbar} from "@ionic/react";
import './Toolbar.scss';
import Logo from "../../assets/images/logo-journey.svg";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {
    HOME_PATH,
    RESOURCES_PATH,
    DAILY_JOURNEY_PATH,
    RECOMMENDATIONS_PATH,
    SEARCH_PATH,
    PRIVACY_POLICY_PATH, BENEFITS_PATH, PERSONAL_PLAN_PATH
}
    from "journey-shared/journey/utils";
import {useHistory, useLocation} from "react-router-dom";
import NavigationHelper from "../../misc/NavigationHelper";
import { ProfileHeader } from "../Profile/ProfileHeader";
import { ProfileMenu } from "../Profile/ProfileMenu";
import { LanguagesHeader } from "../Languages/LanguagesHeader";
import { LanguagesMenu } from "../Languages/LanguagesMenu";

interface ToolbarProps{
    handleOpenLoginModal?: () => void;
}

export const Toolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {

    const {handleLogout, currentUser, isProfileMenuOpen, isLanguagesMenuOpen, isEnglishLanguage } = useContext(ApplicationContext);
    const loc = useLocation();
    const history = useHistory();
    const profileHeaderRef = React.useRef<any>(null);
    const languagesRightMargin = profileHeaderRef?.current?.clientWidth;

    const handleLoginLogoutButtonClick = () => {
        if(!currentUser && props.handleOpenLoginModal){
            props.handleOpenLoginModal();
        } else {
            handleLogout();
        }
    }

    const handleToolbarButtonClick = async (path: string) => {
        const currentPath: string = "/" + NavigationHelper.getParam(1, window.location.pathname);
        if(path === DAILY_JOURNEY_PATH){
            history.push(DAILY_JOURNEY_PATH);
            return;
        }
        if(!currentUser){
            if(currentPath === PRIVACY_POLICY_PATH){
                history.push(HOME_PATH);
            }
            if(props.handleOpenLoginModal){
                props.handleOpenLoginModal();
            }
        } else {
            history.push(path);
        }
    }

    const newHomePage = currentUser?.featureFlags?.includes('personalised-homepage-v1');
    const includePersonalPlan = currentUser?.featureFlags.includes('personal-plan');
    return(
        <IonHeader className={`header`}>
            {isProfileMenuOpen && <ProfileMenu />}
            {isLanguagesMenuOpen && <LanguagesMenu rightMargin={languagesRightMargin} />}
            <IonToolbar className={`toolbar-component ${newHomePage ? 'new-homepage' : ''}`}>
                <div className="toolbar-container">
                    <div className="toolbar-logo-container">
                        <img src={Logo} className="logo-image" alt="Logo" onClick={() => history.push("/")}/>
                    </div>
                    {isEnglishLanguage &&
                        <IonButtons className="toolbar-buttons">
                            <IonButton className={`toolbar-button button-small ${loc.pathname === DAILY_JOURNEY_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(DAILY_JOURNEY_PATH)}>Daily Journey</IonButton>
                            <IonButton className={`button-small ${loc.pathname === SEARCH_PATH ? 'selected button-small-variant' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(SEARCH_PATH)}>Search</IonButton>
                            <IonButton className={`button-small ${loc.pathname === BENEFITS_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(BENEFITS_PATH)}>Benefits</IonButton>
                            <IonButton className={`button-small ${loc.pathname === RESOURCES_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(RESOURCES_PATH)}>Resources</IonButton>
                            {includePersonalPlan && <IonButton className={`button-small ${loc.pathname === PERSONAL_PLAN_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(PERSONAL_PLAN_PATH)}>Personal Plan</IonButton>}
                        </IonButtons>
                    }

                    <IonButtons className="toolbar-buttons toolbar-buttons-auth">
                        {currentUser ?
                            <div className="row">
                                {/* <ToolbarSearch/> */}
                                <LanguagesHeader />
                                <ProfileHeader reference={profileHeaderRef}/>
                            </div>
                        :
                            <IonButton className="toolbar-button button-small login-button" fill="clear" onClick={handleLoginLogoutButtonClick}>
                                Log In
                            </IonButton>
                        }
                    </IonButtons>
                </div>
            </IonToolbar>
        </IonHeader>
    )
}