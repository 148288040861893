import "./AssessmentModal.scss";
import * as React from "react";
import {IonButton, IonContent, IonIcon, IonModal} from "@ionic/react";
import {useContext, useEffect, useState} from "react";
import {BackButton} from "../BackButton/BackButton";
import {ExitButton} from "../ExitButton/ExitButton";
import {AssessmentQuestion} from "./AssessmentQuestion";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {NumberInputAssessmentQuestion} from "./NumberInputAssessmentQuestion";
import {CheckboxAssessmentQuestion} from "./CheckboxAssessmentQuestion";
import {AssessmentWelcomeStep} from "./AssessmentWelcomeStep";
import {AssessmentFinishedStep} from "./AssessmentFinishedStep";
import {User, ApiMonthlyCheckinAnswerV101} from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";
import {ProgressIndicator} from "../ProgressIndicator/ProgressIndicator";
import rightArrow from "../../assets/images/right-arrow-white.svg";


interface assessmentModalProps {
    progress: number,
    isModalOpen: boolean;
    handleModalDismiss: () => void;
    currentUser: User;
    hasAnsweredCareerAndInterests: boolean;
    submitAssessment: (form: ApiMonthlyCheckinAnswerV101) => void;
    onMonthlyAssessmentCompleted: () => void;
}

const contentTopics: string[] = ["AAPI", "BIPOC", "Latinx", "LGBTQ+", "Differently-Abled", "Men", "Women", "Parents", "Caregiver", "Nutrition", "Finance", "Digital Hygiene"];

export const AssessmentModal: React.FC<assessmentModalProps> = (props: assessmentModalProps) => {

    const {isMobileWidth, handleGeneralError} = useContext(ApplicationContext);
    const [progress, setProgress] = useState<number>(props.progress);
    const [isCurrentAnswerNull, setIsCurrentAnswerNull] = useState<boolean>(true);
    const [isMultipleChoiceAssessment, setIsMultipleChoiceAssessment] = useState<boolean>(false);
    const [userCareer, setUserCareer] = useState<string | null>(null);
    const [userInterests, setUserInterests] = useState<string | null>(null);
    let showBackAndCloseButtons: boolean = progress >= 0 && progress <= 7;
    const lastStepNumber = 8;
    const [formValues, setFormValues] = useState<ApiMonthlyCheckinAnswerV101>({
        stressLevel: null,
        feelingsFrequency: null,
        manageStressLevel: null,
        feelingsFrequencyAffectWork: null,
        daysEmotionallyChallenging: null,
        priorities: null,
    });

    async function trackAssessmentStepCompleted (step: number){
        const eventName = `monthly_assessment_step_completed_${step.toString()}`;
        await AnalyticsService.trackUserAction(eventName);
    }

    async function trackAssessmentDismissedEarly (){
        await AnalyticsService.trackUserAction("monthly_assessment_dismissed_early");
    }

    function handleValueChange (name: string, value: number | null, event?: any)  {
        setFormValues({...formValues, [name]: value});
        if((value !== null && value >=0 && value <= 31)){
            setIsCurrentAnswerNull(false);
        } else {
            setIsCurrentAnswerNull(true);
        }
        //If the device is mobile width or multiple choice assessment the user needs to press continue button otherwise
        //we automatically move them forward for the next question
        if(!isMobileWidth && !isMultipleChoiceAssessment){
            showNextStep();
        }
    }

    function handlePrioritiesValueChange (stringedArray: string) {
        setFormValues({...formValues, ["priorities"]: stringedArray});
        if((JSON.parse(stringedArray).length === 0)){
            setIsCurrentAnswerNull(true);
        } else {
            setIsCurrentAnswerNull(false);
        }
    }

    function handleCareerSelection (career: string){
        if(career !== ""){
            //Formatting career to not be a string[]
            let careerString: string = JSON.parse(career)[0];
            setUserCareer(careerString);
            setIsCurrentAnswerNull(false);
            showNextStep();
            saveUserCareer(careerString);
        } else {
            setIsCurrentAnswerNull(true);
        }
    }

    async function saveUserCareer (career: string){
        try{
            await JourneyApiClient.saveUserCareer(career);
        } catch(e){
            handleGeneralError("Could not save user career", e);
        }
    }

    function handleContentTopicChange (interests: string) {
        if((JSON.parse(interests).length === 0)){
            setIsCurrentAnswerNull(true);
        } else {
            saveUserInterests(interests);
            setIsCurrentAnswerNull(false);
        }
    }

    async function saveUserInterests (interests: string){
        try{
            await JourneyApiClient.saveUserInterests(interests);
        } catch(e){
            handleGeneralError("Could not save user interests", e);
        }
    }

    useEffect(() => {
        //The questions that correspond to progress 4,5 and 7 are considered multiple choice and therefore the user needs to press continue button
        if(progress === 4 || progress === 5 || progress === 7){
            setIsMultipleChoiceAssessment(true);
        } else {
            setIsMultipleChoiceAssessment(false);
        }
        if(progress > 0){
            props.submitAssessment(formValues);
        }
        if(progress === lastStepNumber){
            props.onMonthlyAssessmentCompleted();
        }
    }, [progress]);

    useEffect( () => {
        //Do not remove this since this allows users to continue were they left off
        setProgress(props.progress);
    },[props.progress])

    function handleBackButtonPressed () {
        setProgress(progress - 1);
        setIsCurrentAnswerNull(true);
    }

    function handleExitButtonPressed () {
        trackAssessmentDismissedEarly();
        props.handleModalDismiss();
    }

    function showNextStep () {
        if(props.hasAnsweredCareerAndInterests && progress === 5){
            setProgress(lastStepNumber)
        } else {
            setProgress(progress + 1);
        }
        setIsCurrentAnswerNull(true);
        trackAssessmentStepCompleted(progress+1);
    }

    function renderAssessment (step: number){
        switch (step){
            case -1:
                return(
                    <AssessmentWelcomeStep handleNextStepNavigation={showNextStep} handleModalDismiss={handleExitButtonPressed}/>
                )
            case 0:
                return(
                    <AssessmentQuestion
                        assessmentQuestion={"During the last month, how would you rate your stress?"}
                        assessmentLowerBoundText={"Low"}
                        assessmentUpperBoundText={"High"}
                        onValueChange={(value) => handleValueChange("stressLevel", value)}
                        assessmentValueOptions={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                        isAlignmentVertical={true}
                        wideButtons={true}/>
                )
            case 1:
                return(
                    <AssessmentQuestion
                        assessmentQuestion={"How often did you feel sad, anxious, worried, or irritable?"}
                        assessmentLowerBoundText={null}
                        assessmentUpperBoundText={null}
                        onValueChange={(value) => handleValueChange("feelingsFrequency", value)}
                        assessmentValueOptions={["Never", "Almost never", "Sometimes", "Fairly often", "Very often"]}
                        isAlignmentVertical={true}
                        wideButtons={true}/>
                )
            case 2:
                return(
                    <AssessmentQuestion
                        assessmentQuestion={"How would you rate your ability to manage your stress and feelings?"}
                        assessmentLowerBoundText={"Very Poor"}
                        assessmentUpperBoundText={"Exceptional"}
                        onValueChange={(value) => handleValueChange("manageStressLevel", value)}
                        assessmentValueOptions={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                        isAlignmentVertical={true}
                        wideButtons={true}/>
                )
            case 3:
                return(
                    <AssessmentQuestion
                        assessmentQuestion={"How often did your stress or feelings get in the way of work?"}
                        assessmentLowerBoundText={null}
                        assessmentUpperBoundText={null}
                        onValueChange={(value) => handleValueChange("feelingsFrequencyAffectWork", value)}
                        assessmentValueOptions={["Never", "Almost never", "Sometimes", "Fairly often", "Very often"]}
                        isAlignmentVertical={true}
                        wideButtons={true}/>
                )
            case 4:
                return(
                    <NumberInputAssessmentQuestion
                        assessmentQuestion={"How many days were emotionally challenging (you didn’t feel like yourself, low energy, etc.)?"}
                        onValueChange={(value) => handleValueChange("daysEmotionallyChallenging", value)} hasLabel={true}/>
                )
            case 5:
                return(
                    <CheckboxAssessmentQuestion
                        assessmentQuestion={"What would you like to work on or improve over the next 30 days? Pick your top 3."}
                        onValueChange={(value) => handlePrioritiesValueChange(JSON.stringify((value)))}
                        assessmentValueOptions={["Stress", "Sleep", "Anxiety", "Relationships", "Happiness", "Productivity", "Communication", "Work-Life Balance", "Other"]}
                        checkboxLimit={3}/>
                )
            case 6:
                return(
                    <CheckboxAssessmentQuestion
                        assessmentQuestion={"What kind of work do you do?"}
                        onValueChange={(career) => handleCareerSelection(JSON.stringify(career))}
                        assessmentValueOptions={["Administrative", "Creative", "Customer Service", "Engineer/Product", "Executive/C - Suite", "Food Services", "Healthcare", "HR", "Industrial", "Manager", "Lawyer", "Retail", "Sales", "Other"]}
                        checkboxLimit={1}/>
                )
            case 7:
                return(
                    <CheckboxAssessmentQuestion
                        assessmentQuestion={"What content is interesting to you? Check all that apply"}
                        onValueChange={(interests) => handleContentTopicChange(JSON.stringify(interests))}
                        assessmentValueOptions={contentTopics}
                        checkboxLimit={contentTopics.length}/>
                )
            case 8:
                return(
                    <AssessmentFinishedStep closeModal={props.handleModalDismiss}/>
                )
        }
    }

    return(
        <IonModal
            className={`assessment-modal-component fit-content`}
            showBackdrop={true}
            isOpen={props.isModalOpen}
            initialBreakpoint={.98}
            onWillDismiss={props.handleModalDismiss}>
            <IonContent className="assessment-modal-wrapper">
                {showBackAndCloseButtons && <div className="top-container">
                    <div className="back-button-container"><BackButton onClick={handleBackButtonPressed}/></div>
                    <div className="progress-bar-container">
                        <ProgressIndicator currentStep={progress} totalSteps={props.hasAnsweredCareerAndInterests ? 6 : 8}/>
                    </div>
                    <div className="exit-button-container"><ExitButton onClick={handleExitButtonPressed}/></div>
                </div>}
                <div className="bottom-container">
                    {renderAssessment(progress)}
                </div>
                {(isMobileWidth || isMultipleChoiceAssessment) && progress >= 0 && progress !==lastStepNumber &&
                <div className="assessment-continue-button-container">
                    <IonButton className="assessment-continue-button button-medium-variant" onClick={showNextStep} disabled={isCurrentAnswerNull}>
                        Continue<IonIcon src={rightArrow}/>
                    </IonButton>
                </div>
                }
            </IonContent>
        </IonModal>
    )
}
