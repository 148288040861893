import React, {useEffect, useState} from "react";
import {IonButton} from "@ionic/react";
import "./AssessmentQuestion.scss";

interface assessmentQuestionProps {
    assessmentQuestion: string,
    assessmentLowerBoundText: string | null,
    assessmentUpperBoundText: string | null,
    onValueChange: (value: number) => void,
    assessmentValueOptions: any[],
    isAlignmentVertical: boolean,
    wideButtons: boolean,
}

export const AssessmentQuestion: React.FC<assessmentQuestionProps> = (props: assessmentQuestionProps) => {

    const [buttonSelected, setButtonSelected] = useState<number | null>(null);
    const [buttonHovered, setButtonHovered] = useState<number | null>(null);

    useEffect(() => {
        setButtonSelected(null);
    },[props.assessmentQuestion])

    function handleValueChange (indexSelected: number) {
        setButtonSelected(indexSelected);
        //Values stored in the db start on 1, the integer value is the index + 1 since its shifted
        props.onValueChange(indexSelected + 1);
    }

    return(
        <div className="assessment-question-component">
            <div className="assessment-question-copy header-6">{props.assessmentQuestion}</div>
            <div className={`assessment-buttons-container ${props.isAlignmentVertical && "vertical"}`}>

                {props.isAlignmentVertical && <div className="assessment-bound-copy caption">{props.assessmentLowerBoundText}</div>}
                {props.assessmentValueOptions.map((value: any, index: number) => {
                    return(
                        <IonButton
                            key={index}
                            onMouseEnter={ () => setButtonHovered(index)}
                            onMouseLeave={ () => setButtonHovered(null)}
                            className={`assessment-button ${index === buttonSelected && "active"} ${index === buttonHovered && "hover"} ${props.wideButtons && "wide"} ${props.isAlignmentVertical && "vertical"}`}
                            onClick={() => handleValueChange(index)}>
                            {value}
                        </IonButton>
                    )
                })}
                {props.isAlignmentVertical && <div className="assessment-bound-copy caption">{props.assessmentUpperBoundText}</div>}
            </div>

            {!props.isAlignmentVertical &&
            <div className="assessment-bound-copy-container">
                <div>{props.assessmentLowerBoundText}</div>
                <div>{props.assessmentUpperBoundText}</div>
            </div>}
        </div>
    );
}
