import * as React from 'react';
import {useContext} from "react";
import "./DailyVideo.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import {ApiUser, VideoResponse} from "journey-shared/journey/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import {MuxVideoPlayer} from "../MuxVideoPlayer/MuxVideoPlayer";
import {useLocation} from "react-router-dom";

interface DailyVideoProps {
    muxVideo: VideoResponse;
    currentUser: ApiUser | null;
    playVideoOnMute: boolean;
    isDailyJourneyVideo?: boolean;
    className?: string
}

export const DailyVideo: React.FC<DailyVideoProps> = (props: DailyVideoProps) => {

    const {handleApplicationError, isMobileWidth} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();

    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_video_pause", location.pathname,  eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("daily_video_checkpoint", location.pathname,  eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_video_unmute", location.pathname, eventProperties);
    }

    const handleError = (description: string) => {
        handleApplicationError(description);
    }

    return(
        <div className={`daily-video-component ${props.className ?? ''}`}>
            { props.muxVideo &&
                <>
                    <div className="daily-video-container">
                        <MuxVideoPlayer
                            videoId={props.muxVideo.id}
                            duration={props.muxVideo.duration}
                            isLoading={false}
                            isComponentVisible={isComponentVisible}
                            muxPlaybackId={props.muxVideo.muxPlaybackId}
                            muxVideoId={props.muxVideo.muxVideoId}
                            title={props.muxVideo.title}
                            currentUser={props.currentUser}
                            thumbnail={props.muxVideo.thumbnailUrl}
                            trackCuePointEvent={trackCuePointEvent}
                            trackPlayEvent={trackPlayEvent}
                            trackPauseEvent={trackPauseEvent}
                            handleApplicationError={handleError}
                            trackUnmute={trackUnmute}
                            autoplay={props.playVideoOnMute}
                            isInitialMuted={props.playVideoOnMute}
                            numberOfRecommendationsToShow={isMobileWidth ? 1 : 2}/>
                    </div>
                    <div className="daily-video-description body-large">{props.muxVideo.description}</div>
                </>
            }
        </div>
    )
};
