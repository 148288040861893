import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage,
    IonSpinner
} from "@ionic/react";
import {useHistory} from "react-router-dom";
import "./ChampionRegistrationPage.scss";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import AnalyticsService from "../../misc/AnalyticsService";
import {
    HOME_PATH
} from "journey-shared/journey/utils";
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';

export const ChampionRegistrationPage: React.FC = () => {
    const [isOptInCompleted, setOptInCompleted] = useState<boolean>(false);
    const {isMobileWidth, handleApplicationError} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();

    useEffect(() => {
        (async function() {
            if(isComponentVisible){
                try {
                    await JourneyApiClient.setUserAsMhamChampion();
                    setOptInCompleted(true);
                } catch(e) {
                    handleApplicationError("Unable to opt in as a Champion. Try again!");
                    history.push(HOME_PATH);
                }
            }
        })();
    }, [isComponentVisible]);

    return(
        <IonPage className={`championship-registration-page`}>
            {isMobileWidth ? <SideMenuButton title={"Championship Opt In"}/> : <Toolbar/>}
            <IonContent className="championship-page-content">
                {isOptInCompleted ?
                    <SuccessMessage 
                        isComponentVisible={isComponentVisible} 
                        redirectToHome={true} 
                        message={`Thank you for being a Champion for the Mental Health Awareness Month Challenge! Please check your email for more information.`}
                    />
                    :
                    <PleaseWait/>
                }
            </IonContent>
        </IonPage>
    )
};
