import "./AssessmentModal.scss";
import * as React from "react";
import {ApiMonthlyCheckinAnswerV101, User} from "journey-shared/journey/ApiTypes";
import {AssessmentModal} from "./AssessmentModal";
import {useContext, useEffect, useState} from "react";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import AnalyticsService from "../../misc/AnalyticsService";

interface assessmentComponentProps {
    isComponentVisible: boolean;
    currentUser: User;
    isMonthlyAssessmentCompleted: (isCompleted: boolean) => void;
    handleOpenAssessment: boolean;
    handleModalDismiss: () => void;
}

export const AssessmentComponent: React.FC<assessmentComponentProps> = (props: assessmentComponentProps) => {

    const {handleGeneralError, silentlyRefreshUserData} = useContext(ApplicationContext);
    const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState<boolean>(false);
    const [assessmentProgress, setAssessmentProgress] = useState<number>(-1);

    useEffect(() => {
        getLatestAssessment()
    },[props.isComponentVisible]);

    useEffect(() => {
        if(props.handleOpenAssessment){
            setIsAssessmentModalOpen(true);
            trackAssessmentOpened("user");
        }
    },[props.handleOpenAssessment]);

    async function trackAssessmentOpened(trigger: "user" | "automatic"){
        await AnalyticsService.trackUserAction("monthly_assessment_opened", null, {trigger});
    }

    async function getLatestAssessment () {
        try {
            const monthlyAssessment: ApiMonthlyCheckinAnswerV101 = await JourneyApiClient.getMonthlyAssessmentV101();
            const assessmentStep: number = getAssessmentProgress(monthlyAssessment);
            const day = new Date().getDate();
            if(day <= 3 && assessmentStep < 6){
                setIsAssessmentModalOpen(true);
                trackAssessmentOpened("automatic");
            }
        } catch (e) {
            handleGeneralError("Could not fetch latest assessment", e);
        }
    }

    function getAssessmentProgress (monthlyAssessment: ApiMonthlyCheckinAnswerV101): number {
        //In order to allow the user to continue on the last step they left we need to know where they stopped
        //We will return the following step, this will be the first step uncompleted
        //To know which step corresponds to which question, see renderAssessment() in AssessmentModal.tsx
        let progress: number = -1;
        if(monthlyAssessment.stressLevel){
            progress = 1;
        }
        if(monthlyAssessment.feelingsFrequency){
            progress = 2;
        }
        if(monthlyAssessment.manageStressLevel){
            progress = 3;
        }
        if(monthlyAssessment.feelingsFrequencyAffectWork){
            progress = 4;
        }
        if(monthlyAssessment.daysEmotionallyChallenging){
            progress = 5;
        }
        if(monthlyAssessment.priorities !== null && (JSON.parse(monthlyAssessment.priorities)).length > 0){
            //At this point the user has completed the assessment if career and interests have been completed previously
            progress = 6;
        }
        setAssessmentProgress(progress);
        props.isMonthlyAssessmentCompleted(progress === 6);
        return progress;
    }

    async function submitAssessment (formValues: ApiMonthlyCheckinAnswerV101) {
        try{
            await JourneyApiClient.saveMonthlyAssessmentV101(formValues);
            await silentlyRefreshUserData();
        } catch(e){

        }
    }

    async function handleAssessmentModalDismiss() {
        //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
        await new Promise(r => setTimeout(r, 10));
        setIsAssessmentModalOpen(false);
        props.handleModalDismiss();
    }

    return(
        <div className="assessment-component">
            <AssessmentModal
                progress={assessmentProgress}
                submitAssessment={submitAssessment}
                isModalOpen={isAssessmentModalOpen}
                handleModalDismiss={handleAssessmentModalDismiss}
                currentUser={props.currentUser}
                hasAnsweredCareerAndInterests={props.currentUser.interests !== null && props.currentUser.career !== null}
                onMonthlyAssessmentCompleted={() => props.isMonthlyAssessmentCompleted(true)}/>

        </div>
    )
}
