import * as React from 'react';
import {ApiUser, GetCompanyBenefitsInfoResponse} from "journey-shared/journey/ApiTypes";
import "./Benefits.scss";
import {Benefit} from "../Benefit/Benefit";
import Image3 from "../../assets/benefits_page/image3.png";
import MobileHeader from "../../assets/images/benefits-header-mobile.png";
import DesktopHeader from "../../assets/images/benefits-header-desktop.png";
import { ApplicationContext } from '../../misc/ApplicationContext';
import {IonItemDivider} from "@ionic/react";
import {ResourcesHelpTile} from "../ResourcesHelpTile/ResourcesHelpTile";
import MoreSupport from "../../assets/images/more-support-image.svg";
import ScheduleAppointment from "../../assets/images/schedule-appointment-image.svg";

interface Props {
    benefitsInfo: GetCompanyBenefitsInfoResponse;
    currentUser: ApiUser | null;
}


export const Benefits: React.FC<Props> = ({benefitsInfo, currentUser}) => {

    const {companyBenefits, standardBenefits} = benefitsInfo;
    const {isMobileWidth, openScheduleAppointmentModal} = React.useContext(ApplicationContext);


    return(
        <div className='benefits-component'>


            <div className={"benefits-header-container"}>
                <img id='heroImage' className={"benefits-header"} src={isMobileWidth ? MobileHeader : DesktopHeader} alt="hero"/>
            </div>
            <div className={"welcome-text-container"}>
                <div className={"welcome-text-title header-4"}>Welcome!</div>
                <div className={"welcome-text-body body-large"}>Below you will find a list of third party resources that your employer provides as a benefit to you! These resources are aimed to improve your overall wellbeing.</div>
            </div>
            <div id='benefits-section' className='benefits-panel-container'>
                {
                    standardBenefits.length &&
                    <div id='standard-benefits-section' className={"standard-benefits-panel"}>
                        <div className={"benefits-resources-help-tiles-container"}>
                            {currentUser?.company?.hasEAP && <>
                                <ResourcesHelpTile
                                    topText={"Want More Support?"}
                                    bottomText={"Call (866) 695 8862"}
                                    bottomTextClassName={"body-medium"}
                                    image={MoreSupport}
                                    onClick={() =>  window.open('tel:+18666958622',"_self")}
                                />
                                {currentUser.featureFlags.includes('EAPScheduling') && <ResourcesHelpTile
                                    topText={"Schedule"}
                                    bottomText={"Appointment"}
                                    image={ScheduleAppointment}
                                    onClick={openScheduleAppointmentModal}
                                />}
                            </>}
                        </div>
                        <div  className="benefits-page-line-divider-container"><IonItemDivider className="benefits-page-line-divider"/></div>

                        <div className='benefits-list'>
                            {
                                standardBenefits.map((benefit, index) => {
                                    return(
                                        <>
                                            <Benefit key={index} benefit={benefit} currentUser={currentUser} />
                                            { ((index < standardBenefits.length - 1) || isMobileWidth ) && <div  className="benefits-page-line-divider-container"><IonItemDivider className="benefits-page-line-divider"/></div>}
                                        </>
                                    )

                                })
                            }
                        </div>

                    </div>
                }
                <div  className="benefits-vertical-line-divider-container"><IonItemDivider className="benefits-vertical-line-divider"/></div>
                {
                    !!companyBenefits.length &&
                    <div id='third-party-benefits-section' className='company-benefits-panel'>
                        <div className='benefits-list'>
                            {
                                companyBenefits.map((benefit, index) => {
                                    return(
                                        <>
                                            <Benefit key={index} benefit={benefit} currentUser={currentUser} />
                                            { index < companyBenefits.length - 1 && <div  className="benefits-page-line-divider-container"><IonItemDivider className="benefits-page-line-divider"/></div>}
                                        </>
                                    )

                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
