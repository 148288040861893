import * as React from "react";
import {useContext} from "react";
import "./ProfileHeader.scss";
import {
    IonButton,
    IonIcon,
} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import ExpandMore from "../../assets/images/expand_more.svg";
import {ProfileProgressBar} from "./ProfileProgressBar";

interface Props {
    reference?: React.Ref<any>;
}

export const ProfileHeader: React.FC<Props> = (props: Props) => {

    const {currentUser, isProfileMenuOpen, handleToggleProfileMenu, profileStepsCompleted, totalProfileSteps, isEnglishLanguage } = useContext(ApplicationContext);
    const profileCompleted = profileStepsCompleted === totalProfileSteps;

    return (
        <div className="profile-header" onClick={handleToggleProfileMenu} ref={props.reference}>
            {!profileCompleted && isEnglishLanguage &&
                <div className="progress-bar-container">
                    <ProfileProgressBar />
                </div>
            }
            <div className="name-container">
                <div className="name button-small">
                    {`${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}`}
                </div>
            </div>


            <IonButton className={`dropdown-button ${isProfileMenuOpen ? 'down' : 'up'}`} onClick={handleToggleProfileMenu}>
                <IonIcon className="dropdown-icon" src={ExpandMore}/>
            </IonButton>
        </div>
    )
}
