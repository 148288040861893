import * as React from 'react';
import {IonContent, IonPage} from "@ionic/react";
import "./ContentCategoryPage.scss";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {
    ApiResourcesContentArticle,
    ApiResourcesContentCategory,
} from "journey-shared/journey/ApiTypes";
import {ResourcesBackButton} from "../../components/Resources/ResourcesBackButton";
import {ArticlePreviewTile} from "../../components/Resources/ArticlePreviewTile";
import {CategoryChip} from "../../components/Resources/CategoryChip";
import { useTranslation } from 'react-i18next';

type ResourcesRouteParams = {
    moduleId: string;
};

type PageState = {
    selectedCategories: Array<string>
}

export const ContentCategoryPage: React.FC<RouteComponentProps<any, any, PageState>> = ({ match, location }: RouteComponentProps<any, any, PageState>) => {

    const { t, i18n } = useTranslation();
    const [categories, setCategories] = useState<ApiResourcesContentCategory[]>([]);
    const [articles, setArticles] = useState<ApiResourcesContentArticle[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [moduleName, setModuleName] = useState<string | null>(null);
    const [isArticlesLoading, setIsArticlesLoading] = useState<boolean>(true);
    const [numberOfArticlesFound, setNumberOfArticlesFound] = useState<number | null>(null);
    const {isMobileWidth, handleGeneralError, language } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const params = useParams<ResourcesRouteParams>();
    const history = useHistory();

    useEffect(() => {
        if (isComponentVisible) {
            getContentCategories(params.moduleId);
            getModule(params.moduleId);
            setCategories([]);
            setArticles([]);
            setSelectedCategories(location.state?.selectedCategories ?? []);
            setModuleName(null);
            setIsArticlesLoading(true);
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (categories.length) {
            getModule(params.moduleId);
            getArticles(categories);
        }
    }, [categories]);

    useEffect(() => {
        if (!isComponentVisible) return;
        getContentCategories(params.moduleId);
    }, [isComponentVisible, language, params.moduleId]);

    useEffect(() => {
        countArticlesFound();
    }, [selectedCategories, articles]);

    function countArticlesFound() {
        if(!selectedCategories.length){
            setNumberOfArticlesFound(articles.length);
            return;
        }
        let articlesCounter: number = articles.reduce(
            (counter: number, article: ApiResourcesContentArticle) => {
                if(selectedCategories.includes(article.categoryId))
                    counter = counter + 1;
                return (counter);
            },
        0);
        setNumberOfArticlesFound(articlesCounter);
    }

    async function getModule(moduleId: string) {
        try {
            const module = await JourneyApiClient.getResourcesContentModule(moduleId)
            setModuleName(module.nameNative);
        } catch(error) {
            handleGeneralError("Unable to fetch Resources Content Module by ModuleId", error);
        }
    }

    async function getContentCategories(moduleId: string) {
        try {
            const categories = await JourneyApiClient.getResourcesContentCategoriesByModuleId(moduleId)
        setCategories(categories);
        } catch(error) {
            handleGeneralError("Unable to fetch Resources Content Category by ModuleId", error);
        }
    }

    async function getArticles(categories: ApiResourcesContentCategory[]) {
        const arrayOfCategoriesId: string[] = categories.map(c => c.id);
        try {
            const articles = await JourneyApiClient.getResourcesContentArticleByCategoryId(JSON.stringify(arrayOfCategoriesId));
            setArticles(articles);
            setIsArticlesLoading(false);
        } catch(error) {
            setIsArticlesLoading(false);
            handleGeneralError("Unable to fetch Resources Content Articles by Categories IDs", error);
        }
    }

    function handleChipClick(category: ApiResourcesContentCategory) {
        let copySelectedCategories = [...selectedCategories];
        if(selectedCategories.find((el: string) => el === category.id)){
            copySelectedCategories = copySelectedCategories.filter((el: string) => el !== category.id);
        }else {
            copySelectedCategories.push(category.id);
        }
        setSelectedCategories(copySelectedCategories);
        history.replace(match.url, { selectedCategories: copySelectedCategories });
    }

    return (
        <IonPage className="content-category-page">
            {isMobileWidth ? <SideMenuButton title={"Resources"}/> : <Toolbar/>}
            <IonContent className="category-content">
                <div className="category-container">
                    <div className="module-title header-5-variant">
                        {!isMobileWidth && <ResourcesBackButton className='back' />}
                        {moduleName}
                    </div>

                    <div className="articles-found-text body-medium">
                        {i18n.exists('articles found') && !isArticlesLoading && `${numberOfArticlesFound} ${t('articles found')}`}
                    </div>

                    <div className="categories-chip-container">
                        {categories && categories.map((category: ApiResourcesContentCategory, index: number) => {
                            return (
                                <CategoryChip
                                    key={index}
                                    category={category}
                                    handleCategoryClick={handleChipClick}
                                    selectedCategories={selectedCategories}/>
                            )
                        })}
                    </div>
                    {isArticlesLoading ? <PleaseWait/> :
                    <div className="articles-preview-wrapper">
                        <div className="articles-preview-container">
                            {articles && articles.map((article: ApiResourcesContentArticle, index: number) => {
                                if(selectedCategories.length > 0){
                                    if(selectedCategories.includes(article.categoryId)){
                                        return (
                                            <ArticlePreviewTile key={index} article={article}
                                                                    link={`/resources-article/${article.id}`}/>
                                        )
                                    }
                                }else{
                                    return (
                                        <ArticlePreviewTile key={index} article={article}
                                                                link={`/resources-article/${article.id}`}/>
                                    )
                                }
                            })}
                        </div>
                    </div>}
                </div>
            </IonContent>
        </IonPage>
    )
}
