import "./EapSupportPhones.scss";
import {useContext, useState, useEffect} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import * as React from "react";
import {ExitButton} from "../ExitButton/ExitButton";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import { GetCompanyBenefitsInfoResponse } from "journey-shared/journey/ApiTypes";

interface EapSupportPhonesProps {
    isComponentVisible: boolean;
}

export const EapSupportPhones: React.FC<EapSupportPhonesProps> = (props: EapSupportPhonesProps) => {

    const {setIsEapSupportPhonesModalOpen} = useContext(ApplicationContext);
    const [benefitsInfo, setBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);

    useEffect(() => {
        getResources();
    }, [props.isComponentVisible]);

    const getResources = async () => {
        const benefitsInfo = await JourneyApiClient.getCompanyBenefitsInfo();
        setBenefitsInfo(benefitsInfo);
    }

    const handleEapSupportPhonesModalDismiss = async () => {
        //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
        await new Promise(r => setTimeout(r, 10));
        setIsEapSupportPhonesModalOpen(false);
    }

    return(
        <div className="eap-support-phones">
            <div className="eap-support-exit-button-container">
                <ExitButton onClick={handleEapSupportPhonesModalDismiss}/>
            </div>
            <div className='eap-support-component' >
                
                <div className='eap-support-container'>
                    <h2>Need more support with work or life?</h2>
                    <h2>
                        Give us a call and speak to a clinician now.
                    </h2>
                    <div className='phonesComponent'>
                        {benefitsInfo?.supportPhones.map((phone, i) => {
                            return <a key={i} className='phoneComponent' href={`tel:${phone.phoneNumber}`}>
                                    <h3>{phone.description}</h3>
                                </a>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
