import {startOfWeek, isAfter, subDays } from 'date-fns'
import { JourneyApiClient } from "journey-shared/journey/JourneyApiClient";
import {useContext, useEffect, useState} from "react";
import "./ActivityCalendar.scss";
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import isBefore from 'date-fns/isBefore';
import addDays from 'date-fns/addDays';
import { endOfWeek } from 'date-fns/esm';
import Fire from '../../assets/images/fire.png';
import {ApplicationContext} from "../../misc/ApplicationContext";

export type ActivityCalendarProps = {
    className?: string;
    refreshCounter?: number;
}

type ActivityDate = {
    day: number;
    outOfMonth: boolean;
    date: string;
};

function calculateCurrentStreak(activitySet: Set<string>) {

    let rollingDate = new Date();
    const firstOfMonth = startOfMonth(rollingDate);
    const startofCalendar = startOfWeek(firstOfMonth);

    let dayHasActivity: boolean;
    let streak = 0;
    let dayIsWeekend: boolean;
    do {
        dayHasActivity = activitySet.has(`${rollingDate.getFullYear()}${rollingDate.getMonth()}${rollingDate.getDate()}`);
        if (dayHasActivity) {
            streak++;
        }
        dayIsWeekend = rollingDate.getDay() === 0 || rollingDate.getDay() === 6;
        rollingDate = subDays(rollingDate, 1);
    } while ((dayHasActivity || dayIsWeekend) && isAfter(rollingDate, startofCalendar))
    return streak;
}

export function ActivityCalendar(props: ActivityCalendarProps) {

    const [calendarDays, setCalendarDays] = useState<Array<ActivityDate>>([]);
    const [numberOfCalendarRows, setNumberOfCalendarRows] = useState<number>(0);
    const [momentumText, setMomentumText] = useState<string>('');
    const [activityMap, setActivityMap] = useState<Set<string>>(new Set());
    const {currentUser} = useContext(ApplicationContext);

    async function fetchUserActivity() {
        const newActivityMap = new Set<string>();
        if(currentUser){
            const response = await JourneyApiClient.getMonthlyActivity();
            for (let activity of response) {
                const date = new Date(activity.timestamp);
                newActivityMap.add(`${date.getUTCFullYear()}${date.getUTCMonth()}${date.getUTCDate()}`);
            }
        } else {
            //Generating random days for logged out users
            for(let i = 0; i < 12; i++){
                const date = generateRandomDayOfMonth();
                newActivityMap.add(`${date.getUTCFullYear()}${date.getUTCMonth()}${date.getUTCDate()}`);
            }
        }
        setActivityMap(newActivityMap);
        const streak = calculateCurrentStreak(newActivityMap);
        if (streak > 0) setMomentumText(`${streak}-day streak`);
    }

    function generateRandomDayOfMonth(){
        const startDate = new Date(new Date().getUTCFullYear(),new Date().getUTCMonth(),1).getTime();
        const endDate =  new Date(new Date().getUTCFullYear(),new Date().getUTCMonth(),new Date().getUTCDate()).getTime();
        const spaces = (endDate - startDate);
        let timestamp = Math.round(Math.random() * spaces);
        timestamp += startDate;
        return new Date(timestamp);
    }

    useEffect(() => {
        fetchUserActivity();
        const today = new Date();
        const firstOfMonth = startOfMonth(today);
        let rollingDate = startOfWeek(firstOfMonth);
        const lastOfMonth = endOfMonth(today);
        const endOfCalendar = endOfWeek(lastOfMonth);

        let days: Array<ActivityDate> = [];
        while (isBefore(rollingDate, endOfCalendar)) {
            days.push({
                day: rollingDate.getDate(),
                outOfMonth: isAfter(rollingDate, lastOfMonth) || isBefore(rollingDate, firstOfMonth),
                date: `${rollingDate.getFullYear()}${rollingDate.getMonth()}${rollingDate.getDate()}`
            });
            rollingDate = addDays(rollingDate, 1);
        }
        setCalendarDays(days);
        setNumberOfCalendarRows(days.length / 7);
    }, [props.refreshCounter]);

    const now = new Date();

    return(
        <div className={`activity-calender-component box ${props.className ?? ''}`}>
            <div className="box-header">
                <div className="box-title">
                    <div className={"title-container"}>
                        <div className={"title overline"}>Your Progress</div>
                        <div className={"date caption"}>{now.toLocaleString('default', { month: 'short'})} {now.toLocaleString('default', { year: 'numeric' })}</div>
                    </div>
                    {momentumText && <div className='caption box-suffix'>
                        <img src={Fire} /> {momentumText}
                    </div>}
                </div>
            </div>
            <div className="tile">
                <div className={`activity-calender rows-${numberOfCalendarRows}`}>
                    {calendarDays.map((cd, i, calendarDayArr) =>
                        <div className={`calendar-day caption ${cd.outOfMonth ? 'out-of-month': ''} 
                             ${activityMap.has(cd.date) ? 'active' : ''} 
                             ${ i > 0 && activityMap.has(calendarDays[i-1].date) ? 'continuous-left-radius' : ''}
                             ${ i < calendarDays.length - 1 && activityMap.has(calendarDays[i+1].date) ? 'continuous-right-radius' : ''}`}
                             key={i}
                        >
                        {cd.day}
                    </div>)}
                </div>
            </div>
        </div>
    )
}
