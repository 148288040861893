import "./PleaseWait.scss";
import * as React from 'react';
import {CircularProgress} from "@material-ui/core";

interface PleaseWaitProps{
    transparentBackground?: boolean;
}

export const PleaseWait: React.FC<PleaseWaitProps> = (props: PleaseWaitProps) => {
    return(
        <div className={`please-wait-component ${props.transparentBackground && "transparent"}`}>
            <div className="content animate__animated animate__fadeIn">
                <CircularProgress className="loading-spinner" size={100} />
            </div>
        </div>
    )
};
