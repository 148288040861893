import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./PlaylistVideoPage.scss";
import {RouteComponentProps} from "react-router-dom";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import { ApiVideoPlaylist } from "journey-shared/journey/ApiTypes";
import ClampLines from 'react-clamp-lines';
import { VideoTile } from '../../components/ContentListings/VideoTile';

export const PlaylistVideoPage: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {

    const {isMobileWidth, handleGeneralError } = useContext(ApplicationContext);

    const [playlistDetails, setPlaylistDetails] = useState<ApiVideoPlaylist | null>(null);
    const isComponentVisible = useIsComponentVisible();

    useEffect(() => {
        if (!isComponentVisible) return;
        // const playlistId = match.params.id;
        const playlistId = window.location.pathname.split("/")[2];
        getVideoContent(playlistId);
        setPlaylistDetails(null);
    }, [isComponentVisible]);

    const getVideoContent = async (playlistId: string) => {
        try {
            const jsonResponse = await JourneyApiClient.getPlaylist(playlistId);
            setPlaylistDetails(jsonResponse);
        } catch (ex) {
            handleGeneralError("Unable to fetch Playlist", ex);
        }
    }

    return(
        <IonPage className={`playlist-video-page`}>
            {isMobileWidth ? <SideMenuButton title={playlistDetails?.title ?? ''}/> : <Toolbar/>}
            <IonContent className="playlist-video-content">
                {playlistDetails ? <PlaylistVideoPageContent playlistDetails={playlistDetails} isMobileWidth={isMobileWidth} /> : <PleaseWait/>}
            </IonContent>
        </IonPage>
    )
};

export type PlaylistVideoPageContentProps = {
    playlistDetails: ApiVideoPlaylist;
    isMobileWidth: boolean;
}

export const PlaylistVideoPageContent: React.FC<PlaylistVideoPageContentProps> = (props: PlaylistVideoPageContentProps) => {
    return(
        <div className="playlist-content-container">
            <div className='playlist-header'>
                {!props.isMobileWidth && props.playlistDetails.thumbnailUrl && <img src={props.playlistDetails.thumbnailUrl} />}
                <div className={"playlist-title-description-container"}>
                    <div className={`${props.isMobileWidth ? "header-4" : "page-title"} playlist-title`}>{props.playlistDetails.title}</div>
                    <ClampLines
                        text={props.playlistDetails.description ?? ''}
                        id="really-unique-id"
                        lines={8}
                        ellipsis="..."
                        moreText="Expand"
                        lessText="Collapse"
                        className={`clamp-description body-large`}
                        innerElement="div"
                    />
                </div>

            </div>
            <div className="playlist-content">
                {props.playlistDetails.videos.map((video: any, index: number) =>
                    <div key={index} className='video-tile'><VideoTile showDescription={true} video={video} playlistId={props.playlistDetails.id} /></div>)}
            </div>
        </div>
    );
}
