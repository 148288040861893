import React, {SyntheticEvent} from 'react';
import "./ThemeStyle.scss";
import {VideoPlaylistResponse} from "journey-shared/journey/ApiTypes";
import {AddToPlaylist} from "../../../AddToPlaylist/AddToPlaylist";

export type VideoStyleProps = {
    playlist: VideoPlaylistResponse;
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
};

export function ThemeStyle(props: VideoStyleProps)  {

    return(
        <div className={`theme-style-component `}>
            <div className={`image-container `}>
                <img className={"image"} src={props.playlist.teacher?.videoThumbnailImageS3Path ?? ""}/>
                <div className={"tile-name-container"}>
                    <div className={"tile-name overline"}>THEMES</div>
                </div>
                <div className={"title-container"}>
                    <div className={"title subtitle1-variant"}>{props.playlist.title}</div>
                </div>
                <div className={"add-to-playlist-container"}>
                    <AddToPlaylist isSaved={props.isSaved} handleRemoveFromList={props.handleRemoveFromList} handleAddToList={props.handleAddToList}/>
                </div>

            </div>
        </div>
    )
}