import React, {SyntheticEvent} from 'react';
import "./VideoStyle.scss";
import {IonIcon} from "@ionic/react";
import Play from "../../../../assets/images/play.svg";
import {VideoResponse} from "journey-shared/journey/ApiTypes";
import {BACKGROUND_TILE_COLOR} from "journey-shared/journey/utils";
import {AddToPlaylist} from "../../../AddToPlaylist/AddToPlaylist";

export type VideoStyleProps = {
    video: VideoResponse;
    progress: number;
    durationMinutes: string;
    durationSeconds: string;
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
};

export function VideoStyle(props: VideoStyleProps)  {

    return(
        <div className={`video-style-component ${BACKGROUND_TILE_COLOR[props.video.id % BACKGROUND_TILE_COLOR.length]}`}>
            <div className={`image-container ${props.progress ? 'progress-visible' : ''}`}>
                <img className={`image`} src={props.video.teacher?.videoThumbnailImageS3Path ?? ""}/>
                <div className={"title-container"}>
                    <div className={"title subtitle2-variant"}>{props.video.title}</div>
                    <div className={"author-container"}>
                        <div className={"author-name overline"}>{props.video.teacher?.firstName}</div>
                    </div>
                </div>
                <div className="duration caption">{props.durationMinutes}:{props.durationSeconds}</div>
                <IonIcon className="play-icon" src={Play} />
                <div className={"add-to-playlist-container"}>
                    <AddToPlaylist isSaved={props.isSaved} handleRemoveFromList={props.handleRemoveFromList} handleAddToList={props.handleAddToList}/>
                </div>
            </div>
        </div>
    )
}