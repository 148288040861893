import React, {useContext, useState} from 'react';
import {ArrowContainer, Popover} from "react-tiny-popover";
import {IonButton} from "@ionic/react";
import AnalyticsService from "../../misc/AnalyticsService";
import {ApplicationContext} from "../../misc/ApplicationContext";
import "./MonthlyAssessmentPopover.scss";
import {useLocation} from "react-router-dom";

export function MonthlyAssessmentPopover() {

    const {currentUser, hasCompletedAssessment, openAssessmentModal} = useContext(ApplicationContext);
    const [isAssessmentPopoverOpen, setIsAssessmentPopoverOpen] = useState<boolean>(false);
    const location = useLocation();


    function calculateRemainingDaysInMonth() {
        const date = new Date();
        const time = new Date(date.getTime());
        time.setMonth(date.getMonth() + 1);
        time.setDate(0);
        return time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    }


    return(
        <div
            className={"monthly-assessment-popover-component"}
            onTouchStart={() => {
                if (hasCompletedAssessment || !currentUser) {
                    setIsAssessmentPopoverOpen(true)
                }
            }}
            onMouseEnter={() => {
                if (hasCompletedAssessment || !currentUser) {
                    setIsAssessmentPopoverOpen(true)
                }
            }}
            onTouchEnd={() => setIsAssessmentPopoverOpen(false)}
            onMouseLeave={() => setIsAssessmentPopoverOpen(false)}>
            <Popover
                containerClassName={"popover"}
                isOpen={hasCompletedAssessment && isAssessmentPopoverOpen}
                positions={['top']}
                padding={10}
                onClickOutside={() => setIsAssessmentPopoverOpen(false)}
                content={({position, childRect, popoverRect}) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'rgba(0, 0, 0, 0.72)'}
                        arrowSize={10}
                        className='popover-arrow-container'
                        arrowClassName='popover-arrow'
                    >
                        <div
                            className="popover-text"
                            onClick={() => setIsAssessmentPopoverOpen(!isAssessmentPopoverOpen)}
                        >
                            Next Monthly Assessment opens in {calculateRemainingDaysInMonth()} days
                        </div>
                    </ArrowContainer>
                )}
            >
                <IonButton
                    className={"take-assessment-button button-small-variant"}
                    disabled={hasCompletedAssessment || !currentUser}
                    size="small"
                    onClick={() => {
                        openAssessmentModal();
                        AnalyticsService.trackUserAction("hp_personalized_assessment", location.pathname);
                    }}>
                    Take Assessment
                </IonButton>
            </Popover>
        </div>
    )
}