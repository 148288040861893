
export default class NavigationHelper {
    static getURLParams(urlHash: Map<string, string>, path: string): Map<string, string>{
        const pathArray = path.split("/");
        let i = 1;
        urlHash.forEach((value: string, key: string) => {
            if(i <= pathArray.length - 1){
                urlHash.set(key, pathArray[i]);
            }
            i++;
        });
        return urlHash;
    }

    static getParam(paramIndex: number, path: string): string {
        const pathArray = path.split("/");
        return pathArray[paramIndex];
    }

}