import * as React from "react";
import {useContext} from "react";
import "./ProfileMenuList.scss";
import {
    IonIcon,
    IonItem,
    IonLabel,
    IonList
} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import ForwardArrow from "../../assets/images/forward-full-arrow.svg";
import Check from "../../assets/images/check.svg";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";
import {ProfileProgressBar} from "./ProfileProgressBar";
import ClientConfig from 'journey-shared/journey/ClientConfig';
import {useLocation} from "react-router-dom";

interface Props {
    handleMenuClose?: () => void;
    hideProgressBar: boolean;
}

function noop() {}

export const ProfileMenuList: React.FC<Props> = (props: Props) => {

    const {openMindfulBreakModal, openDownloadMobileAppModal, currentUser, setUserFlags, isMobileWidth, handleToggleProfileMenu, openAssessmentModal, hasCompletedAssessment, profileStepsCompleted, totalProfileSteps} = useContext(ApplicationContext);
    const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");
    const profileCompleted = profileStepsCompleted === totalProfileSteps;
    const location = useLocation();

    const handleAssesmentOpen = async () => {
        if(hasCompletedAssessment) return;
        handleToggleProfileMenu();
        if(props.handleMenuClose) props.handleMenuClose();
        openAssessmentModal();
        await AnalyticsService.trackUserAction("profile_menu_assessment_opened", location.pathname);
    }

    const handleMindfulBreakOpen = async () => {
        handleToggleProfileMenu();
        if(props.handleMenuClose) props.handleMenuClose();
        openMindfulBreakModal();
        await AnalyticsService.trackUserAction("profile_menu_mindful_break_opened", location.pathname);
    }

    const handleDownloadMobileAppOpen = async () => {
        if(isMobileApp) return;
        handleToggleProfileMenu();
        if(props.handleMenuClose) props.handleMenuClose();
        openDownloadMobileAppModal();
        await AnalyticsService.trackUserAction("profile_menu_download_mobile_opened", location.pathname);
    }

    const handleDownloadZoomAppOpen = async () => {
        await JourneyApiClient.setUserHasZoomApp();
        setUserFlags("hasZoomAppInstalled");
        handleToggleProfileMenu();
        if(props.handleMenuClose) props.handleMenuClose();
        window.open('https://api.journey.live/zoomapp/install', '_blank', 'noreferrer');
        await AnalyticsService.trackUserAction("profile_menu_download_zoom_opened", location.pathname);
    }

    async function trackZoomAppLaunch() {
        try {
            await JourneyApiClient.setUserHasZoomApp();
            await AnalyticsService.trackUserAction("profile_menu_download_zoom_marked_done", location.pathname);
            setUserFlags("hasZoomAppInstalled");
        } catch (e) {}
    }

    return (
        <IonList className={"profile-menu-list"}>
            <IonItem className={"profile-menu-item"}>
                {isMobileWidth && !profileCompleted && !props.hideProgressBar &&
                    <div className="progress-bar-container">
                        <ProfileProgressBar />
                    </div>
                }
                <div className={`${isMobileWidth ? "subtitle2-variant" : "body-small-variant"}`}>
                    {profileCompleted ? 'Profile Completed' : 'Complete your Profile'}
                </div>
            </IonItem>

            <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleAssesmentOpen : noop}>
                <div className={`box-number ${currentUser?.career && currentUser?.interests ? 'complete' : ''}`}>
                    {currentUser?.career && currentUser?.interests ? <img src={Check} /> : ''}
                </div>
                <div className="text-container">
                    <IonLabel onClick={handleAssesmentOpen} className="caption">Complete Intake <br/>Assessment</IonLabel>
                </div>
                <IonIcon onClick={handleAssesmentOpen} className="profile-menu-icon" slot="end" icon={ForwardArrow}  color="default" />

            </IonItem>

            <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleMindfulBreakOpen : noop}>
                <div className={`box-number ${currentUser?.hasAddedMindfulBreak ? 'complete' : ''}`}>
                    {currentUser?.hasAddedMindfulBreak ? <img src={Check} /> : ''}
                </div>
                <div className="text-container">
                    <IonLabel onClick={handleMindfulBreakOpen} className="caption">Add Mindful Break <br/> to Calendar</IonLabel>
                </div>
                <IonIcon onClick={handleMindfulBreakOpen} className="profile-menu-icon" slot="end" icon={ForwardArrow}  color="default" />
            </IonItem>

            {!isMobileApp &&
                <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleDownloadMobileAppOpen : noop}>
                    <div className={`box-number ${currentUser?.hasMobileAppInstalled ? 'complete' : ''}`}>
                        {currentUser?.hasMobileAppInstalled ? <img src={Check} /> : ''}
                    </div>
                    <div className="text-container">
                        <IonLabel onClick={handleDownloadMobileAppOpen} className="caption">Download Mobile App</IonLabel>
                    </div>
                    <IonIcon onClick={handleDownloadMobileAppOpen} className="profile-menu-icon" slot="end" icon={ForwardArrow}  color="default" />
                </IonItem>
            }

            <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleDownloadZoomAppOpen : noop}>
                <div className={`box-number ${currentUser?.hasZoomAppInstalled ? 'complete' : ''}`}>
                    {currentUser?.hasZoomAppInstalled ? <img src={Check} /> : ''}
                </div>
                <div className="text-container">
                    <IonLabel onClick={handleDownloadZoomAppOpen} className="caption">Download Zoom App</IonLabel>

                </div>
                <IonIcon onClick={handleDownloadZoomAppOpen} className="profile-menu-icon" slot="end" icon={ForwardArrow}  color="default" />

            </IonItem>
        </IonList>
    )
}
