import React, {useContext, useEffect, useState} from 'react';
import {IonButton, IonButtons, IonFooter, IonToolbar} from "@ionic/react";
import './FooterComponent.scss';
import {useHistory} from "react-router";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import {PRIVACY_POLICY_PATH} from "journey-shared/journey/utils";

const FooterComponent: React.FC = () => {
    const appContext = useContext(ApplicationContext);
    const history = useHistory();
    const isComponentVisible = useIsComponentVisible();

    useEffect( () => {
        if(isComponentVisible){

        }
    }, [isComponentVisible])


    return (
        <IonFooter className="footer-component">
            <IonToolbar className="footer-toolbar-component">
                <IonButtons className="buttons-container" slot="start">
                    <IonButton className="footer-button" fill="clear" routerLink={PRIVACY_POLICY_PATH}>
                        Terms & Privacy
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    );
}

export default FooterComponent;