import { IonApp, setupIonicReact, isPlatform, IonModal } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Import Swiper styles  */
import 'swiper/swiper.scss';

/* Theme variables */
import './theme/variables.css';
/* General App styling */
import './App.scss';
import './Typography.scss';
import {AppNavigation} from "./components/Navigation/AppNavigation";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {ApiClientConfiguration, ApiResourcesContentLanguage, ApiUser, User} from "journey-shared/journey/ApiTypes";
import {ApplicationContext, ApplicationContextType, DEFAULT_CLIENT_CONFIGURATION} from "./misc/ApplicationContext";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {capitalizeFirstLetter} from "journey-shared/journey/utils";
import GlobalMessage from "./components/GlobalMessage/GlobalMessage";
import { useAuth0 } from "auth0-react-ionic";
import { App as CapApp } from '@capacitor/app';
import * as Sentry from "@sentry/react";
import useIsComponentVisible from "./components/CustomHooks/useIsComponentVisible";
import AnalyticsService from "./misc/AnalyticsService";
import {MindfulBreak} from "./components/MindfulBreak/MindfulBreak";
import {DownloadMobileApp} from "./components/DownloadMobileApp/DownloadMobileApp";
import ClientConfig from 'journey-shared/journey/ClientConfig';
import {AssessmentComponent} from "./components/Assessment/AssessmentComponent";
import {ExitButton} from "./components/ExitButton/ExitButton";
import { useTranslation } from 'react-i18next';

const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");
setupIonicReact({mode: "ios", swipeBackEnabled: isMobileApp });


const App: React.FC = () => {

    const { i18n } = useTranslation();
    const rafId = useRef<number | null>(null);
    const isComponentVisible = useIsComponentVisible();
    const [currentUser, setCurrentUser] = useState<ApiUser | null>(null);
    const [isCurrentUserLoading, setCurrentUserLoading] = useState<boolean>(true);
    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(true);
    const [appResumeCounter, setAppResumeCounter] = useState<number>(0);
    const [globalHeader, setGlobalHeader] = useState<string | null>(null);
    const [globalMessage, setGlobalMessage] = useState<string | null>(null);
    const [language, setLanguage] = useState<ApiResourcesContentLanguage | null>(null);
    const [isEnglishLanguage, setIsEnglishLanguage] = useState<boolean>(true);
    const [isSuccessMessage, setIsSuccessMessage] = useState<boolean>(false);
    const [isMindfulBreakModalOpen, setIsMindfulBreakModalOpen] = useState<boolean>(false);
    const [isDownloadMobileAppModalOpen, setIsDownloadMobileAppModalOpen] = useState<boolean>(false);
    const [isEapSupportPhonesModalOpen, setIsEapSupportPhonesModalOpen] = useState<boolean>(false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);
    const [isLanguagesMenuOpen, setIsLanguagesMenuOpen] = useState<boolean>(false);
    const [clientConfiguration, setClientConfiguration] = useState<ApiClientConfiguration>(DEFAULT_CLIENT_CONFIGURATION);
    const { isAuthenticated, getAccessTokenSilently, logout, handleRedirectCallback } = useAuth0();
    const operatingSystem = isPlatform("ios") ? "ios" : isPlatform("android")  ? "android" : isPlatform("desktop") ? "web" : "other";
    const totalProfileSteps = isMobileApp ? 3 : 4;
    const [profileStepsCompleted, setProfileStepsCompleted] = useState<number>(0);
    const [hasCompletedAssessment, setHasCompletedAssessment] = useState<boolean>(true);
    const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState<boolean>(false);
    const [isResourcesEmailFormModalOpen, setIsResourcesEmailFormModalOpen] = useState<boolean>(false);
    const [isScheduleAppointmentModalOpen, setIsScheduleAppointmentModalOpen] = useState<boolean>(false);
    const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string>('');

    async function trackAppLaunch() {
        const params = (new URL(document.location.toString())).searchParams;
        let eventProperties = {
            utm_source: params.get("utm_source"),
            utm_medium: params.get("utm_medium"),
            utm_campaign: params.get("utm_campaign"),
            utm_term: params.get("utm_term"),
            utm_content: params.get("utm_content"),
            page_referrer: document.referrer
        }
        await AnalyticsService.trackUserAction("app_launch", null, eventProperties);
    }

    async function trackMobileAppLaunch() {
        if(isMobileApp) {
            try {
                await JourneyApiClient.setUserHasMobileApp();
                setUserFlags("hasMobileAppInstalled");
            } catch (e) {}
        }
    }

    async function handleAnalyticsUserChange(user: User | null) {
        if (user) {
            await AnalyticsService.identifyUser(user);
        } else {
            await AnalyticsService.resetUser();
        }
    }


    /**
     * If the query parameter "token" is found, log the user in and refresh the URL without that query parameter
     */
    function handleQueryParameters() {
        const queryParams = new URLSearchParams(window.location.search);
        let token = queryParams.get("token");
        if (token) {
            JourneyApiClient.setAuthCredentials(token);
            queryParams.delete("token");
            let paramsString = queryParams.toString();
            if(paramsString) {
                paramsString = "?" + paramsString;
            }
            window.location.replace(`${window.location.pathname}${paramsString}`);
        }
    }

    useEffect( () => {

        handleAnalyticsUserChange(currentUser);

        if(currentUser) {

            let completedSteps = 0;
            if(!isMobileApp && currentUser?.hasMobileAppInstalled) {
                completedSteps = completedSteps+1;
            }
            if(currentUser?.hasAddedMindfulBreak) {
                completedSteps = completedSteps+1;
            }
            if(currentUser?.hasZoomAppInstalled) {
                completedSteps = completedSteps+1;
            }
            if(currentUser?.career && currentUser?.interests) {
                completedSteps = completedSteps+1;
            }
            setProfileStepsCompleted(completedSteps);
        }
    }, [currentUser]);


    useEffect(() => {
        (async function() {
            await loadClientConfiguration();
            await refreshUserData();
            await trackMobileAppLaunch();
        })();
        updateDimensions();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [isComponentVisible]);

    useEffect(() => {
        (async function() {
            if (appResumeCounter === 0 || (isPlatform('ios') || isPlatform('android'))) {
                // only track app launch if it's the first open or a resume on native app
                await trackAppLaunch();
            }

            handleQueryParameters();
        })();
    }, [appResumeCounter]);

    function handleResize() {
        if (rafId.current) return;
        rafId.current = requestAnimationFrame(updateDimensions);
    }

    useEffect(() => {
        if(isAuthenticated) {
            (async () => {
                try {
                    const token = await getAccessTokenSilently();
                    JourneyApiClient.setAuthCredentials(token);
                    await refreshUserData();
                } catch (e) {
                    handleGeneralError("Could not save Authentication Credentials", e);
                }
            })();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
        // This is only for Android
        CapApp.addListener('appUrlOpen', async ({ url }) => {
            if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
                await handleRedirectCallback(url);
            }
        });
      }, [handleRedirectCallback]);

    useEffect(() => {
        const openMindfulBreakModalParam = new URLSearchParams(window.location.search).get("mindfulbreak");
        if(openMindfulBreakModalParam && openMindfulBreakModalParam === 'open'){
            // open modal by default as specified in URL params
            openMindfulBreakModal();
        }
    }, [isComponentVisible]);

    useEffect(() => {
        let stateCounter = 1;
        CapApp.addListener('appStateChange', ({ isActive }) => {
            if (!isActive) return;

            setAppResumeCounter(stateCounter++);
        });

        AnalyticsService.setGlobalMetadata({
            operating_system: operatingSystem,
            is_native_mobile_app: isMobileApp,
            device: ClientConfig.device,
            version: ClientConfig.appVersion,
            device_agent: navigator.userAgent,
        });

        const previouslyChosenLanguageText = localStorage.getItem('language');
        const previouslyChosenLanguage = previouslyChosenLanguageText ? JSON.parse(previouslyChosenLanguageText) as ApiResourcesContentLanguage : null;
        changeLanguage(previouslyChosenLanguage)
    }, [])


    const refreshUserData = async () => {
        if(!currentUser) {
            setCurrentUserLoading(true);
            const token = JourneyApiClient.getAuthCredentials();
            if(token) {
                JourneyApiClient.getCurrentUser()
                    .then(user => {
                        setCurrentUser(user);
                        setCurrentUserLoading(false);
                    }).catch(e => {
                        handleGeneralError("Could not fetch user", e);
                        setCurrentUserLoading(false);
                });
            }
            else {
                setCurrentUserLoading(false);
            }
        }
    };

    const silentlyRefreshUserData = async () => {
        const token = JourneyApiClient.getAuthCredentials();
        if(token) {
            JourneyApiClient.getCurrentUser()
                .then(user => {
                    setCurrentUser(user);
                });
        }
    };

    const loadClientConfiguration = async () => {
        try {
            setClientConfiguration(await JourneyApiClient.getConfig());
        } catch (error) {
            handleGeneralError("Could not load client configuration", error);
        }
    }

    const updateDimensions = () => {
        rafId.current = null;
        setIsMobileWidth(window.innerWidth < 768);
    }

    const handleLogout = () => {
        if(isAuthenticated) {
            logout({ returnTo: isPlatform('android') ? "com.journeylive.android://dev-apto9omc.us.auth0.com/capacitor/com.journeylive.android/callback" : window.location.origin });
        }
        JourneyApiClient.logout();
        JourneyApiClient.clearRedirectUrl();
        setCurrentUser(null);
        window.location.replace("/");
    };

    /**
     * @see JourneyApiClient.errorFormatter
     */
    const handleGeneralError = (description: string, error: any) => {
        const applicationError = !error.errorCode || error.errorCode >= 500 || error.errorCode === 400;
        setIsSuccessMessage(false);
        if(error.errorCode === 401){
            setGlobalHeader("Please Log In Again");
            setGlobalMessage(`Contact info@journey.live if problems persist.`);
            setTimeout(() => {
                handleLogout();
            }, 3000);
        }
        else if (applicationError){
            let exceptionMessage = description;
            if(error && error.errorCode) {
                exceptionMessage = `${exceptionMessage}:${error.errorCode}`
            }
            if(error && error.message) {
                exceptionMessage = `${exceptionMessage}:${capitalizeFirstLetter(error.message)}`
            }
            Sentry.captureException(exceptionMessage);
            setGlobalHeader("Application Error");
            setGlobalMessage(`Please contact info@journey.live. ${exceptionMessage}`);
        } else {
            setGlobalHeader("Please Try Again");
            setGlobalMessage(`${description} ${capitalizeFirstLetter(error.message)} Contact info@journey.live if problems persist.`);
        }
    }

    const handleApplicationError = (description: string) => {
        let finalError = {errorCode: 500, message: "Application Error"};
        handleGeneralError(description, finalError);
    }

    const handleUserError = (header: string, description: string) => {
        setGlobalHeader(header);
        setGlobalMessage(description);
        setIsSuccessMessage(false);
        Sentry.captureException(description);
    }

    const handleGeneralMessage = (header: string, description: string) => {
        setGlobalHeader(header);
        setGlobalMessage(description);
        setIsSuccessMessage(true);
    }

    const resetGlobalMessage = () => {
        setGlobalHeader(null);
        setGlobalMessage(null);
        setIsSuccessMessage(false);
    }

    const handleDownloadMobileAppModalDismiss = async () => {
        //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
        await new Promise(r => setTimeout(r, 10));
        setIsDownloadMobileAppModalOpen(false);
    }

    const handleToggleProfileMenu = async () => {
        let openMenu = !isProfileMenuOpen;
        setIsProfileMenuOpen(openMenu);
        if(openMenu) {
            await AnalyticsService.trackUserAction("profile_menu_opened");
            setIsLanguagesMenuOpen(false);
        }
    }

    const handleToggleLanguagesMenu = async () => {
        let openMenu = !isLanguagesMenuOpen;
        setIsLanguagesMenuOpen(openMenu);
        if(openMenu) {
            await AnalyticsService.trackUserAction("languages_menu_opened");
            setIsProfileMenuOpen(false);
        }
    }

    const openMindfulBreakModal = () => {
        trackMindfulBreakModalOpenEvent();
        setIsMindfulBreakModalOpen(true);
    }

    const openResourcesEmailFormModal = () => {
        setIsResourcesEmailFormModalOpen(true);
    }

    const openScheduleAppointmentModal = () => {
        setIsScheduleAppointmentModalOpen(true);
    }

    const openDownloadMobileAppModal = () => {
        trackDownloadMobileAppModalOpenEvent();
        setIsDownloadMobileAppModalOpen(true);
    }

    const openEapSupportPhonesModal = () => {
        trackEapSupportPhonesModalOpenEvent();
        setIsEapSupportPhonesModalOpen(true);
    }

    const trackMindfulBreakModalOpenEvent = async () => {
        await AnalyticsService.trackUserAction("view_mindful_break_modal");
    }

    const trackDownloadMobileAppModalOpenEvent = async () => {
        await AnalyticsService.trackUserAction("view_download_mobile_app_modal");
    }

    const trackEapSupportPhonesModalOpenEvent = async () => {
        await AnalyticsService.trackUserAction("view_eap_support_phones_modal");
    }

    const setUserFlags = (flag: string) => {
        let user: any = currentUser;
        user[flag] = true;

        setCurrentUser({...user});
    }

    const openAssessmentModal = () => {
        setIsAssessmentModalOpen(true);
    }

    function changeLanguage(lang: ApiResourcesContentLanguage | null): void {
        if (!lang) {
            localStorage.removeItem('language');
        } else {
            localStorage.setItem('language', JSON.stringify(lang));
        }
        JourneyApiClient.setLanguage(lang?.id ?? null);
        setIsEnglishLanguage(lang === null || lang.name.toLocaleLowerCase() === 'english');
        setLanguage(lang);
        i18n.changeLanguage(lang?.languageCode ?? 'en');
    }

    const getApplicationContext = (): ApplicationContextType => ({
        currentUser,
        setUserFlags,
        isCurrentUserLoading,
        isMobileWidth: isMobileWidth,
        appResumeCounter,
        handleLogout,
        refreshUserData,
        silentlyRefreshUserData,
        handleGeneralError,
        handleUserError,
        handleApplicationError,
        handleGeneralMessage,
        openDownloadMobileAppModal,
        isProfileMenuOpen,
        handleToggleProfileMenu,
        isLanguagesMenuOpen,
        handleToggleLanguagesMenu,
        profileStepsCompleted,
        totalProfileSteps,
        openAssessmentModal,
        hasCompletedAssessment,
        isDownloadMobileAppModalOpen,
        clientConfiguration,
        isEnglishLanguage,
        language,
        changeLanguage,
        openMindfulBreakModal,
        isMindfulBreakModalOpen,
        setIsMindfulBreakModalOpen,
        isResourcesEmailFormModalOpen,
        setIsResourcesEmailFormModalOpen,
        openResourcesEmailFormModal,
        isScheduleAppointmentModalOpen,
        setIsScheduleAppointmentModalOpen,
        openScheduleAppointmentModal,
        currentlyPlayingId,
        setCurrentlyPlayingId,
        openEapSupportPhonesModal,
        isEapSupportPhonesModalOpen,
        setIsEapSupportPhonesModalOpen
    });

    return (
        <ApplicationContext.Provider value={getApplicationContext()}>
            <IonApp className="app-container typography journey-gradient-bg">
                <GlobalMessage message={globalMessage} header={globalHeader} onClose={resetGlobalMessage} isSuccessMessage={isSuccessMessage} />
                <IonModal
                    className={"download-mobile-app-modal"}
                    showBackdrop={false}
                    isOpen={isDownloadMobileAppModalOpen}
                    onWillDismiss={handleDownloadMobileAppModalDismiss}>
                    <div className="download-app-exit-button-container"><ExitButton onClick={handleDownloadMobileAppModalDismiss}/></div>
                    <DownloadMobileApp isComponentVisible={isDownloadMobileAppModalOpen}/>
                </IonModal>
                {currentUser &&
                    <AssessmentComponent
                        currentUser={currentUser}
                        isComponentVisible={isComponentVisible}
                        isMonthlyAssessmentCompleted={(isCompleted: boolean) => setHasCompletedAssessment(isCompleted)}
                        handleOpenAssessment={isAssessmentModalOpen}
                        handleModalDismiss={() => setIsAssessmentModalOpen(false)}/>
                }
                <AppNavigation />
            </IonApp>
        </ApplicationContext.Provider>
    )
};

export default App;
