import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage,
    IonSpinner
} from "@ionic/react";
import {useHistory} from "react-router-dom";
import "./CommunicationsOptInPage.scss";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import AnalyticsService from "../../misc/AnalyticsService";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {
    HOME_PATH
} from "journey-shared/journey/utils";
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';

export const CommunicationsOptInPage: React.FC = () => {
    const [isMarketingOptInCompleted, setMarketingOptInCompleted] = useState<boolean>(false);
    const {isMobileWidth, handleApplicationError} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();

    useEffect(() => {
        (async function() {
            if(isComponentVisible){
                try {
                    await JourneyApiClient.userMarketingOptIn();
                    setMarketingOptInCompleted(true);
                } catch(e) {
                    handleApplicationError("Unable to opt in communication emails. Try again! ");
                    history.push(HOME_PATH);
                }
            }
        })();
    }, [isComponentVisible]);

    return(
        <IonPage className={`communications-page`}>
            {isMobileWidth ? <SideMenuButton title={"Communications Opt In"}/> : <Toolbar/>}
            <IonContent className="communications-page-content">
                {isMarketingOptInCompleted ?
                    <SuccessMessage isComponentVisible={isComponentVisible} redirectToHome={true}/>
                    :
                    <PleaseWait/>
                }
            </IonContent>
        </IonPage>
    )
};
