import "./HomePage.scss";
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonContent,
    IonItemDivider,
    IonPage,
    IonSegment, IonSegmentButton,
} from "@ionic/react";
import SwiperCore, {FreeMode, Navigation} from 'swiper';
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {useHistory, useLocation} from "react-router-dom";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import FooterComponent from "../../components/Footer/FooterComponent";
import { CategoryHeader } from '../../components/ContentListings/CategoryHeader';
import {  LIVE_STREAM_CATEGORY_NAME } from 'journey-shared/journey/utils';
import { ImageCarousel } from "../../components/ContentListings/ImageCarousel";
import { VideoCategoryResponse, ContentItem, GetCompanyBenefitsInfoResponse } from "journey-shared/journey/ApiTypes";
import { CheckinGraph, CheckinState } from "../../components/CheckinGraph/CheckinGraph";
import ClientConfig from "journey-shared/journey/ClientConfig";
import AnalyticsService from "../../misc/AnalyticsService";
import {LoginModalComponent} from "../../components/LoginModalComponent/LoginModalComponent";
import { ActivityCalendar } from '../../components/ActivityCalendar/ActivityCalendar';
import { DailyPractice } from "../../components/DailyPractice/DailyPractice";
import ScheduleAppointment from "../../assets/images/schedule-appointment-image.svg";
import MoreSupport from "../../assets/images/more-support-image.svg";
import AskAQuestion from "../../assets/images/ask-a-question.svg";
import { Popover, ArrowContainer } from 'react-tiny-popover'
import {ResourcesHelpTile} from "../../components/ResourcesHelpTile/ResourcesHelpTile";
import {MonthlyAssessmentPopover} from "../../components/MonthlyAssessmentPopover/MonthlyAssessmentPopover";


export const HomePage: React.FC = () => {

    const [recommendedPlaceholders, setRecommendedPlaceholders] = useState<ContentItem[]>([]);
    const [myListPlaceholders, setMyListPlaceholders] = useState<ContentItem[]>([]);
    const [recommendations, setRecommendations] = useState<ContentItem[]>([]);
    const [benefitsInfo, setBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);
    const [collections, setCategories] = useState<VideoCategoryResponse[]>([]);
    const [liveStreams, setLiveStreams] = useState<ContentItem[]>([]);
    const [checkinState, setCheckinState] = useState<CheckinState>('not-checkedin');
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
    const {
        isMobileWidth,
        currentUser,
        handleGeneralError,
        clientConfiguration,
        appResumeCounter,
        hasCompletedAssessment,
        isResourcesEmailFormModalOpen,
        openResourcesEmailFormModal,
        isScheduleAppointmentModalOpen,
        openScheduleAppointmentModal,
        openEapSupportPhonesModal
    } = useContext(ApplicationContext);
    const [savedList, setSavedList] = useState<ContentItem[]>([]);
    const [checkinCounter, setCheckinCounter] = useState<number>(0);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const location = useLocation();
    const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");
    SwiperCore.use([Navigation, FreeMode]);

    useEffect(() => {
        if (currentUser) {
            let redirectUrl = JourneyApiClient.getRedirectUrl();
            if (redirectUrl && redirectUrl !== '/') {
                JourneyApiClient.clearRedirectUrl();
                history.replace(redirectUrl);
            }
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (!isComponentVisible) return;
        setIsContentLoading(true);
        const contentRequests = [
            getHomeContent(),
            getLiveStreams(),
            getResources(),
            getRecommendations(),
        ];
        if (currentUser) {
            contentRequests.push(getSavedVideos());
        }
        Promise.all(contentRequests).then(() => setIsContentLoading(false));
    }, [isComponentVisible, appResumeCounter]);


    useEffect(() => {
        (async function () {
            if (!hasCompletedAssessment) return;
            //Do not Remove, Timeout is needed since hasComletedAssessment changes faster(Race condition) than saving data on DB
            await new Promise(r => setTimeout(r, 500));
            await getRecommendations();
        })();
    }, [hasCompletedAssessment]);

    const getSavedVideos = async () => {
        if (!currentUser) return;
        try {
            const videos = await JourneyApiClient.getSavedVideos();
            setSavedList(videos);
        } catch (ex) {
            handleGeneralError("Could not get saved videos", ex);
        }
    }


    const getHomeContent = async () => {
        try {
            const excludeLiveCategory = clientConfiguration.liveVideoProvider === "MUX";
            const categories = await JourneyApiClient.getHomeContent({excludeLiveCategory})
            setCategories(categories);
            if (!myListPlaceholders.length) {
                setMyListPlaceholders(selectRandomVideos(categories));
            }
            if (!recommendedPlaceholders.length) {
                setRecommendedPlaceholders(selectRandomVideos(categories));
            }

        } catch (ex) {
            handleGeneralError("Could not get home content", ex);
        }
    }

    const getLiveStreams = () => {
        return JourneyApiClient.getLiveStreamContent()
            .then(setLiveStreams)
            .catch(error => handleGeneralError("Could not get home content", error))
    }

    const getResources = async () => {
        const benefitsInfo = await JourneyApiClient.getCompanyBenefitsInfo();
        setBenefitsInfo(benefitsInfo);
    }

    const getRecommendations = async () => {
        const recommendations = await JourneyApiClient.getIonicRecommendations();
        setRecommendations(recommendations);
    }

    const openLoginModal = async () => {
        if (currentUser) return;
        setIsLoginModalOpen(true);
        await AnalyticsService.trackUserAction("view_login_modal", location.pathname);
    }

    const now = new Date();
    const greeting = now.getHours() < 5 ? 'Hi Night Owl' :
        now.getHours() < 12 ? `Good Morning, ${currentUser?.firstName}` :
            now.getHours() < 17 ? `Good Afternoon, ${currentUser?.firstName}`
                : `Good Evening, ${currentUser?.firstName}`;

    return (
        <IonPage
            className={`home-page ${(isLoginModalOpen || isResourcesEmailFormModalOpen || isScheduleAppointmentModalOpen) ? "blur-content" : ""}`}
            id="main-page">
            {isMobileWidth ? <SideMenuButton/> : <Toolbar handleOpenLoginModal={openLoginModal}/>}

            <LoginModalComponent
                isComponentVisible={isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />

            <IonContent className='main-content-container'>
                <div className="home-page-daily-checkin">
                    {currentUser?.firstName && <h6 className="user-greeting-text h6-bold">{greeting}</h6>}
                    <div className="checkin-steps-container">
                        <CheckinGraph onCheckin={((state) => {
                            setCheckinState(state);
                            setCheckinCounter(checkinCounter + 1);
                        })}/>
                        <DailyPractice
                            state={checkinState === 'checkedin' ? 'autoplay' : checkinState === "checkedin-previously" ? 'load-video' : 'placeholder'}/>
                        <ActivityCalendar refreshCounter={checkinCounter}/>
                    </div>
                </div>
                {
                    currentUser && benefitsInfo && (benefitsInfo.companyBenefits.length >= 2 || benefitsInfo.supportPhones.length >= 2) &&
                        <div className="video-listing-row-container">
                            <div className="video-listing-row resources">
                                <div className="benefits">
                                    {currentUser.company?.hasEAP && <>
                                        {currentUser.featureFlags.includes('EAPSupport') && <ResourcesHelpTile
                                            topText={"Want More Support?"}
                                            bottomText={"Call (866) 695 8862"}
                                            bottomTextClassName={"body-medium"}
                                            image={MoreSupport}
                                            onClick={() =>  window.open('tel:+18666958622',"_self")}
                                        />}
                                        {currentUser.featureFlags.includes('EAPScheduling') && <ResourcesHelpTile
                                            topText={"Schedule"}
                                            bottomText={"Appointment"}
                                            image={ScheduleAppointment}
                                            onClick={openScheduleAppointmentModal}
                                        />}
                                        {currentUser.featureFlags.includes('EAPQuestion') && <ResourcesHelpTile
                                            topText={"Ask a Question"}
                                            bottomText={""}
                                            image={AskAQuestion}
                                            onClick={openResourcesEmailFormModal}
                                            addRightDivider={true}
                                        />}
                                    </>}
                                    {benefitsInfo?.supportPhones.map((phone, i) => {
                                          return <ResourcesHelpTile
                                              key={i}
                                              topText={phone.title}
                                              bottomText={phone.subtitle}
                                              image={MoreSupport}
                                              onClick={openEapSupportPhonesModal}
                                          />
                                      })}
                                    {benefitsInfo?.companyBenefits.map((benefit, i) => {
                                        if (benefit.redirectUrl)
                                            return <a
                                                onClick={() => AnalyticsService.trackUserAction("hp_resource_click", location.pathname, {
                                                    name: benefit.name
                                                })}
                                                target='_blank' key={i} href={benefit.redirectUrl ?? ''} className='logo'><img
                                                src={benefit.imageUrl!}/></a>
                                        else
                                            return <span key={i} className="logo"><img src={benefit.imageUrl!}/></span>

                                    })}
                                </div>
                            </div>
                            <div  className="home-page-line-divider-container first"><IonItemDivider className="home-page-line-divider"/></div>
                        </div>
                }
                {
                    <div className="video-listing-row-container">
                        <div className="video-listing-row">
                            <CategoryHeader
                                name={'Recommended'}
                                description={null}
                                onClick={openLoginModal}
                            >
                                <MonthlyAssessmentPopover/>
                            </CategoryHeader>
                            <ImageCarousel onSaveChange={getSavedVideos} onClick={() => {
                                openLoginModal();
                                AnalyticsService.trackUserAction("hp_recommended_video", location.pathname);
                            }} items={recommendations}/>
                        </div>
                        <div  className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider"/></div>
                    </div>
                }
                {
                    (clientConfiguration.liveVideoProvider === "MUX" && liveStreams.length > 0) &&
                    <div className="video-listing-row-container">
                        <div className="video-listing-row">
                            <CategoryHeader
                                name={LIVE_STREAM_CATEGORY_NAME}
                                description={null}
                                onClick={openLoginModal}
                            />
                            <ImageCarousel onSaveChange={getSavedVideos} onClick={() => {
                                openLoginModal();
                                AnalyticsService.trackUserAction("hp_livestream_video", location.pathname);
                            }} items={liveStreams}/>
                        </div>
                        <div  className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider"/></div>
                    </div>
                }
                {
                    (savedList.length > 0) &&
                    <div className="video-listing-row-container">
                        <div className="video-listing-row">
                            <CategoryHeader
                                name='My List'
                                description={null}
                                onClick={openLoginModal}
                            />
                            <ImageCarousel onSaveChange={getSavedVideos} onClick={() => {
                                openLoginModal();
                                AnalyticsService.trackUserAction("hp_mylist_item", location.pathname);
                            }} items={savedList}/>
                        </div>
                        <div  className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider"/></div>
                    </div>
                }
                {
                    collections.map((category, index) => {
                        return(
                            <div className="video-listing-row-container" key={index}>
                                <div className="video-listing-row">
                                    <CategoryHeader
                                        name={category.name}
                                        description={category.shortDescription}
                                        path={`/category/${category.id}`}
                                        onClick={openLoginModal}
                                    />
                                    <ImageCarousel onSaveChange={getSavedVideos} onClick={() => {
                                        openLoginModal();
                                        AnalyticsService.trackUserAction("hp_category_item", location.pathname);
                                    }} items={category.items}/>
                                </div>
                                { index < collections.length - 1 && <div  className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider"/></div>}
                            </div>
                        )

                    })
                }

                {!isContentLoading && !isMobileApp && <FooterComponent/>}
            </IonContent>
        </IonPage>
    );

}

function selectRandomVideos(categories: VideoCategoryResponse[]): ContentItem[] {
    const items = categories
        .filter(category => !category.name.startsWith('Live Classes:') && !category.name.startsWith('Replays:'))
        .reduce((list, category) => list.concat(category.items), [] as ContentItem[]);
    const result: ContentItem[] = [];
    if (items.length === 0) return [];
    for (let i = 0; i < 4; i++) {
        result.push(items[Math.floor(Math.random()*items.length)]);
    }
    return result;
}
