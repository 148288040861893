import React, {useContext} from 'react';
import {LoggedOutNavigation} from "./LoggedOutNavigation";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {LoggedInNavigation} from "./LoggedInNavigation";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";

export const AppNavigation: React.FC = () => {

    const {currentUser, isCurrentUserLoading} = useContext(ApplicationContext);

    if (isCurrentUserLoading) {
        return <PleaseWait/>;
    } else if (currentUser) {
        return <LoggedInNavigation />;
    } else {
        return <LoggedOutNavigation />;
    }
};


