import * as React from "react";
import "./LanguagesMenu.scss";
import { LanguagesMenuList } from "./LanguagesMenuList";

interface Props {
    rightMargin: number;
}

export const LanguagesMenu: React.FC<Props> = (props: Props) => {

    return (
        <div className={`language-menu-component`} style={{right: `${props.rightMargin ?? "160"}px`}}>
            <LanguagesMenuList />
        </div>
    )
}
