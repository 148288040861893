import "./ShareComponent.scss";
import * as React from "react";
import {IonButton, IonIcon} from "@ionic/react";
import {CopyToClipboardModal} from "../CopyToClipboard/CopyToClipboardModal";
import {useState} from "react";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation} from "react-router-dom";
import ArrowRedo from "../../assets/images/arrow-redo.svg";

interface Props {
    link: string;
    text: string;
}

export const ShareComponent: React.FC<Props> = (props) => {

    const dailyVideoEmailSubject = "Check out this Article and Daily Practice from Journey.Live!";
    const dailyVideoEmailBody = `Hello! I thought you would enjoy this Daily Journey and Practice from Journey Live. ${props.link}`;

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const location = useLocation();

    async function handleButtonClick () {
        setIsModalOpen(true);
        await AnalyticsService.trackUserAction("daily_journey_share_link_viewed", location.pathname);
    }

    return(
        <div className="share-component">
            <div className="text-container body-small">
                {props.text}

            </div>
            <div className="button-container">
                <IonButton onClick={handleButtonClick} className="share-button button-small-variant">
                    <IonIcon className={"share-button-icon"} slot="start" icon={ArrowRedo} aria-hidden="true" />
                    Share
                </IonButton>
            </div>
            <CopyToClipboardModal link={props.link} isModalOpen={isModalOpen} dismissModal={() => setIsModalOpen(false)}/>
        </div>
    )
}