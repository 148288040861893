"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANGUAGES_LABEL = exports.SETTINGS_LABEL = exports.BENEFITS_LABEL = exports.RESOURCES_LABEL = exports.RECOMMENDATIONS_LABEL = exports.DAILY_VIDEO_LABEL = exports.DAILY_JOURNEY_LABEL = exports.CHECKIN_LABEL = exports.BROWSE_LABEL = exports.FOR_YOU_LABEL = exports.HOME_LABEL = exports.PERSONAL_PLAN_PATH = exports.GUINESS_RECORD_CHALLENGE_PATH = exports.TEACHER_PATH = exports.SSO_LOGIN_PATH = exports.LANGUAGES_PATH = exports.PARTICIPANT_OPT_IN_PATH = exports.CHAMPION_OPT_IN_PATH = exports.COMMUNICATIONS_OPT_IN_PATH = exports.PUBLIC_WATCH_PATH = exports.LIVE_VIDEO_PATH = exports.REGISTER_PATH = exports.PRIVACY_POLICY_PATH = exports.SETTINGS_PATH = exports.CATEGORY_PATH = exports.PlAYLIST_PATH = exports.PARTIAL_WATCH_PATH = exports.WATCH_PATH = exports.SEARCH_PATH_QUERY = exports.SEARCH_PATH = exports.RECOMMENDATIONS_PATH = exports.DAILY_VIDEO_PATH = exports.DAILY_JOURNEY_PATH = exports.BENEFITS_PATH = exports.RESOURCES_PATH_ARTICLE = exports.RESOURCES_PATH_CATEGORY = exports.RESOURCES_PATH_MODULE = exports.RESOURCES_PATH_DIVISION = exports.RESOURCES_PATH = exports.HOME_PATH = exports.validateRequired = exports.capitalizeFirstLetter = exports.validateEmail = exports.InternalServerError = exports.InvalidRequestError = exports.UnauthorizedError = exports.QueryError = exports.ValidationError = exports.NotFoundError = exports.assert = void 0;
exports.MONTHLY_ACTIVITY_INTENSITY_HIGH = exports.MONTHLY_ACTIVITY_INTENSITY_MEDIUM = exports.MONTHLY_ACTIVITY_INTENSITY_LOW = exports.sleep = exports.ZOOM_APP_PLAYLIST_SLUG = exports.DAILY_PRACTICE_PLAYLIST_SLUG = exports.LIVE_STREAM_RECORDINGS_CATEGORY_INTERNAL_NAME = exports.LIVE_STREAM_CATEGORY_INTERNAL_NAME = exports.LIVE_STREAM_CATEGORY_NAME = exports.DISCLAIMER = exports.PLATFORMS = exports.BACKGROUND_TILE_COLOR = exports.AUDIO_PLAYLIST_IDS = exports.PSEUDO_AUDIO_PLAYLIST_ID = exports.PSEUDO_VOD_PLAYLIST_ID = exports.LIVE_PLAYLIST_ID = exports.REPLAY_PLAYLIST_ID = exports.SPECIAL = exports.FORMAT = exports.IGNORE = exports.THEME = exports.DURATION = exports.TEACHER = exports.HIGH_TIER_TRESHOLD = exports.LOW_TIER_TRESHOLD = exports.REPORT_BY_USER = exports.IMPORT_VIDEO_FROM_S3 = exports.SEND_INVITATION = exports.REINSTATE_USER_BY_ID = exports.DELETE_USER_BY_ID = exports.DELETE_USERS = void 0;
function assert(condition, message) {
    if (!condition) {
        throw new Error(message);
    }
}
exports.assert = assert;
// Errors
class NotFoundError extends Error {
}
exports.NotFoundError = NotFoundError;
class ValidationError extends Error {
}
exports.ValidationError = ValidationError;
class QueryError extends Error {
}
exports.QueryError = QueryError;
class UnauthorizedError extends Error {
    constructor(message) {
        super();
        this.message = message !== null && message !== void 0 ? message : "Unauthorized";
    }
}
exports.UnauthorizedError = UnauthorizedError;
class InvalidRequestError extends Error {
    constructor(message) {
        super();
        this.message = message !== null && message !== void 0 ? message : "Invalid Request";
    }
}
exports.InvalidRequestError = InvalidRequestError;
class InternalServerError extends Error {
    constructor(message) {
        super();
        this.message = message || "Internal Server Error";
    }
}
exports.InternalServerError = InternalServerError;
// Throws a ValidationError if email is invalid
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
        return true;
    }
    else {
        throw new ValidationError(`Invalid email: ${email}`);
    }
}
exports.validateEmail = validateEmail;
const capitalizeFirstLetter = (name) => {
    return name ? `${name.charAt(0).toUpperCase()}${name.substr(1)}` : name;
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
// Throws a Validation error if value is "falsy"
function validateRequired(fieldName, value) {
    if (value) {
        return true;
    }
    else {
        throw new ValidationError(`Invalid ${fieldName}: "${value}"`);
    }
}
exports.validateRequired = validateRequired;
// Paths
exports.HOME_PATH = "/";
exports.RESOURCES_PATH = "/resources";
exports.RESOURCES_PATH_DIVISION = "/resources";
exports.RESOURCES_PATH_MODULE = "/resources/:divisionId";
exports.RESOURCES_PATH_CATEGORY = "/resources/:divisionId/:moduleId";
exports.RESOURCES_PATH_ARTICLE = "/resources-article/:articleId";
exports.BENEFITS_PATH = "/benefits";
exports.DAILY_JOURNEY_PATH = "/daily-journey";
exports.DAILY_VIDEO_PATH = "/daily-video";
exports.RECOMMENDATIONS_PATH = "/recommendations";
exports.SEARCH_PATH = "/search";
exports.SEARCH_PATH_QUERY = "/search/:query";
exports.WATCH_PATH = "/watch/:videoId";
exports.PARTIAL_WATCH_PATH = "/watch/:playlistId/:videoId";
exports.PlAYLIST_PATH = "/playlist/:id";
exports.CATEGORY_PATH = "/category/:id";
exports.SETTINGS_PATH = "/settings";
exports.PRIVACY_POLICY_PATH = "/privacy-policy";
exports.REGISTER_PATH = "/register/:companyCode";
exports.LIVE_VIDEO_PATH = "/watch-live/:id";
exports.PUBLIC_WATCH_PATH = "/video/public/:id";
exports.COMMUNICATIONS_OPT_IN_PATH = "/communications-opt-in";
exports.CHAMPION_OPT_IN_PATH = "/mhamc-champion-opt-in";
exports.PARTICIPANT_OPT_IN_PATH = "/mhamc-participant-opt-in";
exports.LANGUAGES_PATH = "/languages";
exports.SSO_LOGIN_PATH = "/auth/sso/:companyCode";
exports.TEACHER_PATH = "/teacher/:id";
exports.GUINESS_RECORD_CHALLENGE_PATH = "/world-record-attempt";
exports.PERSONAL_PLAN_PATH = '/personal-plan';
// Labels
exports.HOME_LABEL = "Home";
exports.FOR_YOU_LABEL = 'For You';
exports.BROWSE_LABEL = "Search";
exports.CHECKIN_LABEL = "Check-In";
exports.DAILY_JOURNEY_LABEL = "Daily Journey";
exports.DAILY_VIDEO_LABEL = "Mindful Break";
exports.RECOMMENDATIONS_LABEL = "Recommendations";
exports.RESOURCES_LABEL = "Resources";
exports.BENEFITS_LABEL = "Benefits";
exports.SETTINGS_LABEL = "Settings";
exports.LANGUAGES_LABEL = "Languages";
// Async Worker names
exports.DELETE_USERS = 'delete_users';
exports.DELETE_USER_BY_ID = 'delete_single_user';
exports.REINSTATE_USER_BY_ID = 'reinstate_user';
exports.SEND_INVITATION = 'send_invitation';
exports.IMPORT_VIDEO_FROM_S3 = 'import_video_from_s3';
// Report names
exports.REPORT_BY_USER = 'user_report';
exports.LOW_TIER_TRESHOLD = 1000;
exports.HIGH_TIER_TRESHOLD = 5000;
exports.TEACHER = 'Teacher';
exports.DURATION = 'Duration';
exports.THEME = 'Theme';
exports.IGNORE = 'Ignore';
exports.FORMAT = 'Format';
exports.SPECIAL = 'Special';
exports.REPLAY_PLAYLIST_ID = 258390;
exports.LIVE_PLAYLIST_ID = 245157;
exports.PSEUDO_VOD_PLAYLIST_ID = 10; //random arbitrarily chosen id
exports.PSEUDO_AUDIO_PLAYLIST_ID = 11; //random arbitrarily chosen id
exports.AUDIO_PLAYLIST_IDS = [302264, 287196, 287188, 287310, 287146, 287382];
exports.BACKGROUND_TILE_COLOR = ["blue", "yellow", "purple", "green", "pink"];
exports.PLATFORMS = ['iphone', 'ipad', 'web', 'android'];
exports.DISCLAIMER = "This information will not be shared with your employer in a personally identifiable way. All questions are optional.";
exports.LIVE_STREAM_CATEGORY_NAME = "Live Classes: Connect LIVE With Our Expert Teachers & Supportive Community";
exports.LIVE_STREAM_CATEGORY_INTERNAL_NAME = "live-streams";
exports.LIVE_STREAM_RECORDINGS_CATEGORY_INTERNAL_NAME = "live-streams-recordings";
exports.DAILY_PRACTICE_PLAYLIST_SLUG = 'daily-practice';
exports.ZOOM_APP_PLAYLIST_SLUG = 'zoom-app';
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
exports.sleep = sleep;
exports.MONTHLY_ACTIVITY_INTENSITY_LOW = 1;
exports.MONTHLY_ACTIVITY_INTENSITY_MEDIUM = 2;
exports.MONTHLY_ACTIVITY_INTENSITY_HIGH = 3;
