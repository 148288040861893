import React, {useState} from "react";
import {IonLabel, IonRadioGroup, IonRadio, IonItem} from "@ionic/react";
import "./RadioButtonAssessmentQuestion.scss";

interface radioButtonAssessmentQuestionProps {
    assessmentQuestion: string,
    onValueChange: (array: string) => void,
    assessmentValueOptions: any[],
    clearAnswerOnSelection?: boolean;
}

export const RadioButtonAssessmentQuestion: React.FC<radioButtonAssessmentQuestionProps> = (props: radioButtonAssessmentQuestionProps) => {

    const [answer, setAnswer] = useState<string>("");

    function handleValueChange (value: string, event: any) {
        props.onValueChange(value);
        if(!props.clearAnswerOnSelection){
            setAnswer(value);
        }
    }

    return(
        <div className="radio-button-assessment-question-component">
            <div className="radio-button-assessment-question-copy header-6">{props.assessmentQuestion}</div>
            <div className="radio-button-assessment-items-container">
                <IonRadioGroup className="radio-button-group">
                    {props.assessmentValueOptions.map((value: any, index: number) => {
                        return(
                            <IonItem
                                key={index}
                                className={`radio-button-assessment-checkbox-item  ${answer === value ? "selected" : ""}`}>
                                <IonRadio
                                    className={`radio-button-assessment-checkbox`}
                                    onClick={(event) => handleValueChange(value, event)}/>
                                <IonLabel className={`radio-button-assessment-label button-medium ${answer === value ? "selected" : ""}`}>
                                    {value}
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonRadioGroup>
            </div>
        </div>
    );
}
