import "./SuccessMessage.scss";
import * as React from 'react';
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    HOME_PATH
} from "journey-shared/journey/utils";
import SuccessIconSrc from "../../assets/images/check-circle-regular.svg";

interface SuccessMessageProps{
    isComponentVisible: boolean;
    message?: string;
    redirectToHome: boolean;
}

export const SuccessMessage: React.FC<SuccessMessageProps> = (props: SuccessMessageProps) => {

    const history = useHistory();

    useEffect(() => {
        if(!props.isComponentVisible) return;
        if(!props.redirectToHome) return;
        
        setTimeout(() => {
            history.push(HOME_PATH);
        }, 3000);
    }, [props.isComponentVisible]);

    return(
        <div className="success-message-component">
            <img className='success-icon' alt="Success Icon" src={SuccessIconSrc} />
            <div className='content'>
                {
                    props.message ?? "Thank You! You'll now be redirected to the home page."
                }
            </div>
        </div>
    )
};
