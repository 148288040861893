import "./Benefit.scss";
import * as React from 'react';
import {ApiUser, Benefit as ApiBenefit} from "journey-shared/journey/ApiTypes";

interface Props {
    benefit: ApiBenefit;
    currentUser: ApiUser | null;
}

export const Benefit: React.FC<Props> = ({benefit, currentUser}) => {

    return(
        <div className='benefit-component'>
            {
                benefit.redirectUrl ?
                    <a target="_blank" href={benefit.redirectUrl || ""} rel="noreferrer">
                        <img src={benefit.imageUrl || ""} alt="benefit"/>
                    </a>
                    :
                    <img src={benefit.imageUrl || ""} alt="benefit"/>
            }
            <div className={"subtitle1 benefit-name"}>{benefit.name}</div>
            <div className={"body-small"}>{benefit.description}</div>
        </div>
    );
};
