import './SignUpFormError.scss';
import React from 'react';

export const SignUpFormError: React.FC = () => {

        return (
            <div className='sign-up-form-error-component paper-container'>
                <h1 className='sign-up-title'> Invalid URL </h1>

                <div className="email-notice">
                    You are receiving this message because this URL is not tied to an active client.
                    Please check the link that you were provided and if you still are having issues,
                    contact us at <a href="mailto:info@journey.live">info@journey.live</a>
                </div>
            </div>
        );
};
