import { JourneyApiClient } from "journey-shared/journey/JourneyApiClient";
import {Link, useLocation} from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import "./Tile.scss";
import { VideoPlaylistResponse } from "journey-shared/journey/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import {TeacherStyle} from "./ContentStyles/Teacher/TeacherStyle";
import {SpecialSeries} from "./ContentStyles/SpecialSeries/SpecialSeries";
import {ThemeStyle} from "./ContentStyles/Theme/ThemeStyle";
import {AudioStyle} from "./ContentStyles/Audio/AudioStyle";

export type PlaylistProps = {
    playlist: VideoPlaylistResponse;
    showDescription?: boolean;
    showTitle?: boolean;
    className?: string;
    onSaveChange?: (saved: boolean) => void;
};

export function PlaylistTile(props: PlaylistProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(props.playlist.isSaved);
    const location = useLocation();

    async function addToList(e: SyntheticEvent) {
        e.preventDefault()
        if (loading) return;
        try {
            setLoading(true);
            await JourneyApiClient.saveVideoPlaylist(props.playlist.id);
            setIsSaved(true);
            if (props.onSaveChange) props.onSaveChange(true);
            AnalyticsService.trackUserAction("save_playlist", location.pathname, { video_id: props.playlist.id });
        } finally {
            setLoading(false);
        }
    }

    async function removeFromList(e: SyntheticEvent) {
        e.preventDefault();
        if (loading) return;
        try {
            setLoading(true);
            await JourneyApiClient.removeSavedVideoPlaylist(props.playlist.id);
            setIsSaved(false);
            if (props.onSaveChange) props.onSaveChange(false);
            AnalyticsService.trackUserAction("remove_save_playlist", location.pathname, { video_id: props.playlist.id });
        } finally {
            setLoading(false);
        }
    }
    function renderTile(theme: string | null) {
        switch (theme){
            case null:
                return(
                    <div></div>
                    //<TeacherStyle handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} playlist={props.playlist}/>
                )
            case "teacher":
                return(
                    <TeacherStyle handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} playlist={props.playlist}/>
                )
            case "theme":
                return(
                    <ThemeStyle handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} playlist={props.playlist}/>
                )
            case "special-series":
                return(
                    <SpecialSeries handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} playlist={props.playlist}/>
                )
            case "audio":
                return(
                    <AudioStyle durationMinutes={null} durationSeconds={null} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} progress={null} content={props.playlist} />
                )
        }
    }

    return(
        <div className="tile-component">
            <Link
                to={`/playlist/${props.playlist.id}`}
                className={`tile-container ${props.className ?? ''}`}>
                {renderTile(props.playlist.thumbnailStyle)}
            </Link>
            <div className={"title-and-description-container"}>
                {(props.showTitle ?? true) &&<div className={"out-of-tile-title caption"}>{props.playlist.title}</div>}
                {(props.showDescription ?? false) && <div className={"caption"}>{props.playlist.description}</div>}
            </div>
        </div>

    )
}
