import {IonButton, IonContent, IonModal, IonTextarea} from "@ionic/react";
import "./ResourcesAskAQuestionEmailForm.scss";
import {ExitButton} from "../ExitButton/ExitButton";
import React, {useState, useContext, useEffect} from "react";
import {CheckboxAssessmentQuestion} from "../Assessment/CheckboxAssessmentQuestion";
import { ApplicationContext } from "../../misc/ApplicationContext";
import {NumberInputAssessmentQuestion} from "../Assessment/NumberInputAssessmentQuestion";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {RadioButtonAssessmentQuestion} from "../Assessment/RadioButtonAssessmentQuestion";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation} from "react-router-dom";
import { TextInputAssessmentQuestion } from "../Assessment/TextInputAssessmentQuestions";

const areasOfConcern: string[] = ["Anger Management", "Anxiety", "Depression", "Drug/Alcohol & Addiction Concerns", "Dependent Care Concerns", "Family or Relationships Concerns", "Grief and Loss", "Legal/Financial Services", "Smoking Cessation", "Stress Management", "Workplace Concerns", "Other"];

interface ResourcesEmailFormModalProps {
    isModalOpen: boolean;
    handleModalDismiss: () => void;
}

export class ResourcesEmailAnswer {
    phoneNumber: number | null = null;
    preferredContactMethod: "phone" | "email" | null = null;
    areasOfConcern: string | null = null;
    question: string | null = null;
}

export const ResourcesAskAQuestionEmailForm: React.FC<ResourcesEmailFormModalProps> = (props: ResourcesEmailFormModalProps) => {

    const location = useLocation();
    const { currentUser, handleGeneralError, handleGeneralMessage} = useContext(ApplicationContext);
    const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<ResourcesEmailAnswer>({
        phoneNumber: null,
        preferredContactMethod: null,
        areasOfConcern: null,
        question: null,
    });


    useEffect(() => {
        const phoneNumberInputIsValid: boolean = (formValues.phoneNumber !== null && !(isNaN(formValues.phoneNumber)));
        if(currentUser &&
            (formValues.preferredContactMethod) && (formValues.areasOfConcern && formValues.areasOfConcern !== "[]")){
            if(formValues.preferredContactMethod === "phone" && !phoneNumberInputIsValid){
                setIsFormComplete(false);
            }else {
                setIsFormComplete(true);
            }
        } else {
            setIsFormComplete(false);
        }
    },[formValues]);

    useEffect(() => {
        if(props.isModalOpen){
            trackFormOpen();
        }
    },[props.isModalOpen])

    async function trackFormOpen () {
        await AnalyticsService.trackUserAction("ask_a_question_open", location.pathname);
    }

    async function trackFormSubmission () {
        await AnalyticsService.trackUserAction("ask_a_question_submission", location.pathname);
    }


    function handleValueChange (name: string, value: string | null | undefined, event?: any)  {
        setFormValues({...formValues, [name]: value});
    }

    function handleNumberChange (name: string, value: number | null, event?: any)  {
        setFormValues({...formValues, [name]: value});
    }

    function handleExitButtonPressed () {
        props.handleModalDismiss();
    }

    function handleSubmitForm() {
        JourneyApiClient.submitResourcesAskQuestionForm(formValues).then(() =>{
            handleGeneralMessage("Success", "Your question has been submitted, we'll respond shortly");
            trackFormSubmission();
            handleExitButtonPressed();
        }).catch(error => handleGeneralError("Could not submit ResourcesEmailForm", error))
    }

    return(
        <IonModal
            className={`resource-email-form-modal-component`}
            showBackdrop={true}
            isOpen={props.isModalOpen}
            initialBreakpoint={.95}
            onWillDismiss={props.handleModalDismiss}>
            <IonContent className="resource-email-form-modal-wrapper">
                <div className="top-container">
                    <div className="top-container-copy body-medium">After completing the form below, one of our team members will contact you within one business day. If this is a life-threatening emergency, please contact emergency services or 911 for assistance.</div>
                    <div className="exit-button-container"><ExitButton onClick={handleExitButtonPressed}/></div>
                </div>
                <div className="bottom-container">
                    <div className="radio-button-wrapper">
                        <RadioButtonAssessmentQuestion
                            assessmentQuestion={"Preferred contact method *"}
                            onValueChange={(contactMethod: string) => handleValueChange("preferredContactMethod", contactMethod.toLocaleLowerCase())}
                            assessmentValueOptions={["Email", "Phone"]}/>
                    </div>

                    {(formValues.preferredContactMethod === "phone") &&
                    <div className="phone-number-input-container">
                        <div className="phone-number-input-label header-6">
                            Phone Number *
                        </div>
                        <NumberInputAssessmentQuestion
                            assessmentQuestion={null}
                            onValueChange={(phoneNumber: number) => handleNumberChange("phoneNumber", phoneNumber)}
                            hasLabel={false}/>
                    </div>}

                    <CheckboxAssessmentQuestion
                        assessmentQuestion={"Please indicate your area(s) of concern *"}
                        onValueChange={(concerns) => handleValueChange("areasOfConcern", JSON.stringify((concerns)))}
                        assessmentValueOptions={areasOfConcern}
                        checkboxLimit={areasOfConcern.length}/>

                    <IonTextarea
                        onIonChange={(e) => handleValueChange("question", e.detail.value)}
                        className="question-input-field"
                        placeholder="Ask a question..."
                        value={formValues.question}
                        autoGrow={true}
                        rows={4}
                    />
                </div>
                <div className="submit-button-container">
                    <IonButton
                        disabled={!isFormComplete || !currentUser}
                        className="submit-button button-medium-variant"
                        onClick={handleSubmitForm}
                    >
                        Submit
                    </IonButton>
                </div>
            </IonContent>
        </IonModal>
    )

}