import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import "./NextRecommendation.scss";
import {ContentItem, VideoContentItemResponse} from "journey-shared/journey/ApiTypes";
import {PlaylistTile} from "../ContentListings/PlaylistTile";
import {VideoTile} from "../ContentListings/VideoTile";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {ExitButton} from "../ExitButton/ExitButton";
import {useHistory} from "react-router-dom";
import ClientConfig from "journey-shared/journey/ClientConfig";
import * as Sentry from "@sentry/react";
import {useIonViewWillLeave} from "@ionic/react";

interface NextRecommendationProps  {
    videoId?: number;
    playlistId?: number;
    hasVideoFinished: boolean;
    isVideoEndingSoon: boolean;
    isComponentVisible: boolean;
    numberOfRecommendationsToShow: number;
};


export const NextRecommendation: React.FC<NextRecommendationProps> = (props: NextRecommendationProps) => {

    const {isMobileWidth} = useContext(ApplicationContext);
    const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");
    const history = useHistory();
    const [isRecommendationsLoading, setIsRecommendationsLoading] = useState<boolean>(false);
    const [hideRecommendations, setHideRecommendations] = useState<boolean>(false);
    const [nextVideoTimer, setNextVideoTimer] = useState<number>(10);
    const [videoRecommendations, setVideoRecommendations] = useState<ContentItem[]>([]);
    const recommendationLimit: number = props.numberOfRecommendationsToShow ? props.numberOfRecommendationsToShow : (isMobileWidth) ? 1 : 6;
    const showOneRecommendation: boolean = props.numberOfRecommendationsToShow === 1;
    const recommendationLimitString: string[] = ["zero", "one", "two", "three", "four", "five", "six"];


    useEffect(()=>{
        if(props.isComponentVisible){
            setIsRecommendationsLoading(false);
            setVideoRecommendations([]);
            setHideRecommendations(false);
            setNextVideoTimer(10);
        }
    }, [props.isComponentVisible, props.videoId]);

    //Used to stop timer
    useIonViewWillLeave( () => setHideRecommendations(true));

    useEffect(() => {
        if(props.isVideoEndingSoon){
            fetchVideoRecommendations();
        }
    }, [props.isVideoEndingSoon]);

    useEffect(() => {
        if(!isRecommendationsLoading && props.hasVideoFinished){
            handleTimer();
        }
    }, [props.hasVideoFinished, isRecommendationsLoading]);

    useEffect(() => {
        //When timer is done, if the user has not removed the recommendations we will redirect them.
        if(nextVideoTimer < 1 && !hideRecommendations){
            handleRedirectToNextRecommendation();
        }
    }, [nextVideoTimer]);

    async function handleTimer() {
        let videoTimer: number = 10;
        const div = document.getElementById("next-recommendation-div");
        do{
            await delay(1000);
            //if there is no hover on the component, remove one second, if there is then "pause"
            if(isMobileApp || isMobileWidth || !(div?.parentNode?.querySelector(":hover"))){
                videoTimer = videoTimer - 1;
                setNextVideoTimer(videoTimer);
            }
        } while(videoTimer >= 1)
    }

    function delay(timeInMilliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, timeInMilliseconds));
    }

    async function fetchVideoRecommendations() {
        setIsRecommendationsLoading(true);
        let recommendations: ContentItem[] = [];
        const savedVideos: ContentItem[] = await JourneyApiClient.getSavedVideos();
        const recommendedVideos: ContentItem[] = await JourneyApiClient.getIonicRecommendations();
        let nextVideoInPlaylist: VideoContentItemResponse | null = null;
        try{
            //Since not all videos are part of playlists we need to have this try/catch
            if(props.videoId && props.playlistId){
                nextVideoInPlaylist = await JourneyApiClient.getNextVideoInPlaylist(props.videoId, props.playlistId);
                if(nextVideoInPlaylist){
                    nextVideoInPlaylist.path = `/watch/${props.playlistId}/${nextVideoInPlaylist.id}`;
                    recommendations.push(nextVideoInPlaylist);
                }
            } else if(props.videoId) {
                nextVideoInPlaylist = await JourneyApiClient.getNextVideoInAnyPlaylist(props.videoId);
                recommendations.push(nextVideoInPlaylist);
            }
        } catch (e: any){
            Sentry.captureException(e.message);
        }
        recommendations = recommendations.concat(savedVideos).concat(recommendedVideos);
        setVideoRecommendations(recommendations);
        setIsRecommendationsLoading(false);
    }

    function handleRedirectToNextRecommendation (){
        //Gets first recommendation on queue and checks if next recommendation is current video being watched.
        const nextRecommendation: ContentItem | null = videoRecommendations.length ?
            videoRecommendations[0].type === 'video' && videoRecommendations[0].id === props.videoId && videoRecommendations.length > 1 ?
                videoRecommendations[1] : videoRecommendations[0] : null;
        if(nextRecommendation){
            history.push(nextRecommendation.path);
        }
    }


    return (
        <div className="next-recommendations-component" style={{display: hideRecommendations || !props.hasVideoFinished ? "none" : "initial"}}>
            {props.hasVideoFinished &&
                <div id={"next-recommendation-div"}
                     style={{
                         display: hideRecommendations ? "none" : "",
                         justifyContent: videoRecommendations && videoRecommendations.length <= 3 ? "center" : ""
                     }}
                     className={`next-recommendations-wrapper`}>

                    {!isRecommendationsLoading &&
                    <>
                        <div className={`${showOneRecommendation && "small-player"} next-recommendations-top-container`}>
                            <div className={`${showOneRecommendation && "small-player"} next-recommendations-text body-small`} >
                                {`Up next in ${nextVideoTimer}`}
                            </div>
                            <div className="next-recommendations-exit-container">
                                <ExitButton onClick={() => setHideRecommendations(true)}/>
                            </div>
                        </div>
                        <div className={`${recommendationLimitString[recommendationLimit]} recommendations-container`}>
                            {videoRecommendations.map((item: ContentItem, index: number) => {
                                if(index < recommendationLimit){
                                    switch (item.type) {
                                        case 'playlist':
                                            return <div key={index} className='content-tile'><PlaylistTile key={index} playlist={item} showTitle={false}/></div>
                                        case 'video':
                                            return <div key={index} className='content-tile'><VideoTile key={index} video={item} showTitle={false} /></div>
                                        default:
                                            return <div key={index}></div>
                                    }
                                }
                            })}
                        </div>
                    </>}
                </div>
            }
        </div>
    )
}