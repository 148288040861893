import './BenefitsPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {GetCompanyBenefitsInfoResponse, RESOURCES_VISIT, User} from "journey-shared/journey/ApiTypes";
import {Benefits} from "../../components/Benefits/Benefits";
import {IonContent, IonPage} from '@ionic/react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";

export const BenefitsPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [companyBenefitsInfo, setCompanyBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);
    const {isMobileWidth, handleGeneralError, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();

    useEffect(() => {
        if(isComponentVisible){
            JourneyApiClient.getCompanyBenefitsInfo()
                .then(setCompanyBenefitsInfo)
                .catch((error) => {
                    handleGeneralError("Could not get company benefits or create user engagement", error);
                })
                .finally(() => setIsLoading(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return (
        <IonPage className={`benefits-page`} id="main-content">
            {isMobileWidth ?  <SideMenuButton title={"Benefits"}/> : <Toolbar/>}
            <IonContent>
                {
                    (function() {
                        if (isLoading) {
                            return <PleaseWait/>;
                        } else if (companyBenefitsInfo && !isLoading) {
                            return <Benefits benefitsInfo={companyBenefitsInfo} currentUser={currentUser} />;
                        } else {
                            return <div>Something went wrong.</div>;
                        }
                    })()
                }
            </IonContent>
        </IonPage>
    );

}
