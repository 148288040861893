import {IonPage} from "@ionic/react";
import {useEffect} from "react";
import "./LoginPage.scss";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {LoginComponent} from "../../components/LoginComponent/LoginComponent";

export const LoginPage = () => {
    const isComponentVisible = useIsComponentVisible();

    return(
        <IonPage className="login-page-component paper-container">
            <LoginComponent isComponentVisible={isComponentVisible}/>
        </IonPage>
    )
}
