import React from "react";
import {useEffect, useState} from "react";
import {IonPage, IonContent, isPlatform} from "@ionic/react";
import "./SsoLoginPage.scss";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {Severity} from "@sentry/react";
import {Company} from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from 'journey-shared/journey/JourneyApiClient';
import AnalyticsService from "../../misc/AnalyticsService";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import Logo from "../../assets/images/journey-logo-green.svg";
import { SignUpFormError } from "../../components/SignUpFormError/SignUpFormError";
import {useHistory} from "react-router-dom";
import {
    HOME_PATH
} from "journey-shared/journey/utils";
import { useAuth0 } from "auth0-react-ionic";
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';

export const SsoLoginPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<Company | null>(null);
    const {companyCode} = useParams<{companyCode: string}>();
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const isIos = isPlatform('ios');
    const { loginWithRedirect, handleRedirectCallback } = useAuth0();

    const loadCompany = async () => {
        setIsLoading(true);

        if (!companyCode) {
            Sentry.captureEvent({message: "Invalid Url", level: Severity.Info});
            setIsLoading(false);
            return;
        }

        try {
            let company = await JourneyApiClient.getCompany(companyCode);

            if(!company.isSsoLogin) {
                // company is not sso login
                setIsLoading(false);
                return;
            }
            setCompany(company);
            setIsLoading(false);

            //sso login
            if(isIos) {
                let response = await loginWithRedirect({connection: company.code});
                await handleRedirectCallback(response ?? '');
                history.replace("/");
            }
            else {
                loginWithRedirect({connection: company.code}).then(() => setIsLoading(false));
            }
        } catch(error) {
            Sentry.captureException(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(!isComponentVisible) return;

        (async () => {
            await loadCompany();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return (
        <IonPage className="sso-login-page-component">
            <IonContent className='sso-login-page-content'>
                    <div className='header'>
                        <img src={Logo} className="logo-image" alt="Journey LIVE Logo"/>
                    </div>
                    <div className='body'>
                        {
                            !isLoading && company ?
                                <SuccessMessage isComponentVisible={isComponentVisible} message={`Thank You! You'll now be redirected to ${company.name}'s SSO Login.`} redirectToHome={false}/>
                            :
                            !isLoading && !company ?
                                <SignUpFormError/>
                            :
                                <PleaseWait/>
                        }
                    </div>
            </IonContent>
        </IonPage>
    );
}
