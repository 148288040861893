import {IonContent, IonItem, IonInput, IonButton, isPlatform, IonText, IonSpinner} from "@ionic/react";
import {useEffect, useRef, useState} from "react";
import "./LoginComponent.scss";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import { useAuth0 } from "auth0-react-ionic";
import Logo from "../../assets/images/journey-logo-green.svg";
import AnalyticsService from "../../misc/AnalyticsService";
import ClientConfig from "journey-shared/journey/ClientConfig";
import ReactCodeInput from "react-code-input";
import {useLocation} from "react-router-dom";

interface LoginComponentProps {
    isComponentVisible: boolean;
    email?: string;
}

export const LoginComponent: React.FC<LoginComponentProps> = (props: LoginComponentProps) => {
    const [email, setEmail] = useState<string|null>(props.email ?? null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string | null>(null);
    const [code, setCode] = useState<string>("");
    const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
    const [error, setError] = useState<string|null>(null);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const { loginWithRedirect, handleRedirectCallback } = useAuth0();
    const isIos = isPlatform('ios');
    const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");
    const inputElement = useRef<HTMLIonInputElement>(null);
    const location = useLocation();

    useEffect(() => {
        if(props.isComponentVisible){
            if(props.email) {
                // email was provided beforehand
                loginWithSSOOrMagicLink();
            } else {
                setEmail(null);
                setIsLoading(false);
                setError(null);
                setTimeout(async () => {
                    if (!inputElement.current) return;
                    const input = await inputElement.current.getInputElement();
                    input?.focus()
                }, 500);
            }
        }
    }, [props.isComponentVisible]);

    const loginWithSSOOrMagicLink = () => {
        if(email && validateEmail(email)) {
            (async () => {
                setIsLoading(true);
                setError("");
                try {
                    let response = await JourneyApiClient.getRealmDiscovery(email);
                    if(response.realm === "sso") {
                        setShowPassword(false);
                        trackLoginSuccessfulEvent();
                        //sso login
                        if(isIos) {
                            let response = await loginWithRedirect({login_hint: email});
                            await handleRedirectCallback(response ?? '');
                            setIsLoading(false);
                        }
                        else {
                            loginWithRedirect({login_hint: email}).then(() => setIsLoading(false));
                        }
                    }
                    else if (response.realm === "magic-link") {
                        //magic link login
                        setShowPassword(false);
                        JourneyApiClient.sendLoginEmail(email)
                       .then(() => {
                           trackLoginSuccessfulEvent();
                           setIsLoading(false);
                           setIsSubmitted(true);
                       })
                       .catch((error) => {
                           trackLoginFailEvent();
                           setIsLoading(false);
                           setError("Could not send login instructions.");
                       });
                    } else if (response.realm === "code") {
                        //code login
                        setShowPassword(false);
                        JourneyApiClient.sendCodeLoginEmail(email)
                       .then(() => {
                           trackLoginSuccessfulEvent();
                           setIsLoading(false);
                           setIsSubmitted(true);
                       })
                       .catch((error) => {
                           trackLoginFailEvent();
                           setIsLoading(false);
                           setError("Could not send login instructions.");
                       });
                    } else if (response.realm === 'password') {
                        setShowPassword(true);
                        setIsLoading(false);
                    } else {
                        trackLoginFailEvent();
                        setError("Login not supported yet.");
                        setIsLoading(false);
                    }
                } catch(e: any) {
                    trackLoginFailEvent();
                    // add sentry here
                    setError(e?.message ?? "This email address is not in our records.");
                    setIsLoading(false);
                }
            })();
        } else {
            setError("Enter a valid email address.");
        }
    };

    async function handleLogin() {
        if(email && isSubmitted) {
            await verifyCodeLogin();
        }
        else if (email && !showPassword) {
            loginWithSSOOrMagicLink();
        } else if (email && password) {
            loginWithPassword();
        }
    }

    async function verifyCodeLogin() {
        setIsLoading(true);
        setError("");
        if(!email) {
            //this should not happen as code is being entered because an email was sent
            await submitAgain();
            return;
        }
        try {
            // submit email and code to get auth token
            const response = await JourneyApiClient.verifyCodeLogin(email, Number(code));

            if(!response.journeyAuthToken) {
                //token not in response
                //this should not happen, as the endpoint will return a token or throw an exception that is managed below
                setIsCodeValid(false);
                setError("Code is invalid");
                setIsLoading(false);
            } else {
                //token was returned in the response
                JourneyApiClient.setAuthCredentials(response.journeyAuthToken);
                if(props.email) {
                    window.location.replace("/");
                } else {
                    window.location.reload();
                }
            }
        } catch(exception: any) {
            setIsLoading(false);
            setIsCodeValid(false);
            setError(exception.message);
            trackLoginFailEvent();
        }
    }

    async function loginWithPassword() {
        if (!email || !password) return;
        setIsLoading(true);
        const authResponse = await JourneyApiClient.loginWithPassword(email, password);
        setIsLoading(false);
        if (authResponse.token) {
            window.location.replace(`/?token=${authResponse.token}`);
        } else {
            setError('Password incorrect');
        }
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const trackLoginSuccessfulEvent = async () => {
        await AnalyticsService.trackUserAction("login_success", location.pathname);
    }

    const trackLoginFailEvent = async () => {
        await AnalyticsService.trackUserAction("login_failure", location.pathname);
    }

    const submitAgain = async () => {
        setIsSubmitted(false);
        setIsCodeValid(true);
        setError(null);
        setCode("");
    }

    function handleEmailInputKeypress(e: React.KeyboardEvent) {
        if (e.key !== 'Enter') return;

        if (email && !showPassword) {
            loginWithSSOOrMagicLink();
        } else if (email && password) {
            loginWithPassword();
        }
    }

    const codeInputProps = {
        className: "code-input",
        inputStyle: {
            fontFamily: 'monospace',
            margin:  '4px',
            flexGrow: "1",
            textAlign: 'center' as const,
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #CFCFCF'
        },
        inputStyleInvalid: {
            fontFamily: 'monospace',
            margin:  '4px',
            flexGrow: "1",
            textAlign: 'center' as const,
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid red'
        }
    }

    return(
        <IonContent className={"login-component paper-container"} >
            <div className={`login-page-content ${isLoading ? "blur-content" : "login-page-content"} ${isMobileApp ? "mobile" : ""}`}>
                <div className="logo-container">
                    <img src={Logo} className="logo-image" alt="Journey LIVE Logo"/>
                </div>

                {error && !isSubmitted && (
                    <IonText color="danger" className="error-text">
                        {error}
                    </IonText>
                )}

                {!isSubmitted && (
                <IonItem className={error ? "email-input-error" : "email-input"}>
                    <IonInput className={"button-medium"} ref={inputElement} placeholder="Enter your email" type="email" autocomplete="email" onKeyPress={handleEmailInputKeypress} inputmode="email" value={email} onIonChange={e => setEmail(e.detail.value!)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" />
                </IonItem>
                )}

                {showPassword && <IonItem className={"password-input"}>
                    <IonInput placeholder="Password" type="password" value={password} onKeyPress={handleEmailInputKeypress} onIonChange={e => setPassword(e.detail.value!)} />
                </IonItem>}

                {isSubmitted && (
                    <>
                        <div className="code-sent header-6-variant">
                            Your code has been sent!
                        </div>

                        <ReactCodeInput
                            type="number"
                            isValid={isCodeValid}
                            fields={6}
                            onChange={setCode}
                            value={code}
                            name={"6 Digit Code"}
                            inputMode={"numeric"}
                            {...codeInputProps}
                        />

                        {error && (
                            <IonText color="danger" className="error-text">
                                {error}
                            </IonText>
                        )}

                        <div className="code-sent-subtitle caption">
                            Didn't get a code? Double check your email or <span className="submit-again" onClick={submitAgain}>submit again.</span>
                        </div>
                    </>
                )}

                <IonButton className='button-medium-variant login-button' disabled={isLoading || (isSubmitted && code.length < 6)} onClick={handleLogin}>
                    Login
                </IonButton>
            </div>

            {isLoading && (
                <div className="loading-spinner">
                    <div><IonSpinner name="circles" /></div>
                </div>
            )}
        </IonContent>
    )
}
