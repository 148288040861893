import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import "./ContentDivisionPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {ResourcesCarrousel} from "../../components/Resources/ResourcesCarrousel";
import {ApiResourcesContentDivision } from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ResourcesTile} from "../../components/Resources/ResourcesTile";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {BACKGROUND_TILE_COLOR} from "journey-shared/journey/utils";

const images: string[] = [
    "https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Ceasar_Barajas_2.png",
    "https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Anahita_Moghaddam.png",
    "https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Gly_Gabriel.png",
    "https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Azaria.png",
    "https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Daniel_Ryan.png",
    "https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Hector_Marcel_2.png",
    "https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Tiffani_Moore.png",
]

export const ContentDivisionPage: React.FC = () => {
    const [isDivisionsLoading, setIsDivisionsLoading] = useState<boolean>(true);
    const {isMobileWidth, handleGeneralError, language } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const [divisions, setDivisions] = useState<ApiResourcesContentDivision[]>([]);

    useEffect(() => {
        if (!isComponentVisible) return;
        fetchDivisions();
        setIsDivisionsLoading(true);
    }, [isComponentVisible]);

    useEffect(() => {
        if (!isComponentVisible) return;
        fetchDivisions();
    }, [isComponentVisible, language]);

    async function fetchDivisions() {
        setIsDivisionsLoading(true);
        try {
            const divisions = await JourneyApiClient.getResourcesContentDivisions();
            setDivisions(divisions);
            setIsDivisionsLoading(false);
        } catch (error) {
            setIsDivisionsLoading(false);
            handleGeneralError("Unable to fetch Resources Content Module By DivisionId", error);
        }
    }

    return(
        <IonPage className="content-division-page">
            {isMobileWidth ? <SideMenuButton title={"Resources"}/> : <Toolbar/>}
            <IonContent className="division-content">
                <ResourcesCarrousel isComponentVisible={isComponentVisible} isMobileWidth={isMobileWidth} />
                {isDivisionsLoading ? <PleaseWait/> :
                <div className="resources-tiles-wrapper">
                    <div className="resources-tiles-container">
                        {divisions && divisions.map((contentDivision: ApiResourcesContentDivision, index: number) => {
                            return(<ResourcesTile className='left-aligned-tile' color={BACKGROUND_TILE_COLOR[index % BACKGROUND_TILE_COLOR.length]} key={index} displayBorder={true} text={contentDivision.nameNative ?? contentDivision.nameEnglish} image={images[index]} link={`/resources/${contentDivision.id}`} alignTextLeft={true}/>)
                        })}
                    </div>
                </div>}
            </IonContent>
        </IonPage>
    )
}
