"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ALLOWED_CLIENT_ENVIRONMENTS = ["production", "staging", "development", "test"];
const ALLOWED_DEVICES = ["ios", "android", "webBrowser"];
class ClientConfig {
    constructor() {
        const selectedEnvironment = process.env.REACT_APP_ENVIRONMENT || "";
        if (ALLOWED_CLIENT_ENVIRONMENTS.includes(selectedEnvironment)) {
            this.environment = selectedEnvironment;
        }
        else {
            throw new Error(`Invalid environment value found: ${selectedEnvironment} expected: ${ALLOWED_CLIENT_ENVIRONMENTS}`);
        }
        const selectedDevice = process.env.REACT_APP_DEVICE || "";
        if (ALLOWED_DEVICES.includes(selectedDevice)) {
            this.device = selectedDevice;
        }
        else {
            throw new Error(`Invalid device value found: ${selectedDevice} expected: ${ALLOWED_DEVICES}`);
        }
        this.apiRoot = this.getValueForEnvironment('apiRoot', {
            production: "https://api.journey.live",
            staging: "https://api-staging.journey.live",
            development: "http://localhost:3340",
            test: "http://localhost:3340",
        });
        this.segmentWriteKey = this.getValueForEnvironment('segmentWriteKey', {
            production: "GpeECShYvMX9jS5Gn3pYypSpcmkf91eC",
            staging: "ko7z8ULzIvbbwFvauZv8hqRZohIHI139",
            development: "ko7z8ULzIvbbwFvauZv8hqRZohIHI139",
            test: "ko7z8ULzIvbbwFvauZv8hqRZohIHI139",
        });
        this.muxMetricsKey = this.getValueForEnvironment('muxMetricsKey', {
            production: "l79btce03j0hpo7uo8sr0pl7u",
            staging: "qe25n85bd70g2agfrgvprm477",
            development: "qe25n85bd70g2agfrgvprm477",
            test: "qe25n85bd70g2agfrgvprm477",
        });
        this.enableAnalytics = this.getValueForEnvironment('enableAnalytics', {
            production: true,
            staging: true,
            development: true,
            test: false,
        });
        this.newFrontendRoot = this.getValueForEnvironment('newFrontendRoot', {
            production: "https://app2.journey.live",
            staging: "https://app2-staging.journey.live",
            development: "http://localhost:3000",
            test: "http://localhost:3000",
        });
        // These values are the same regardless of environment
        this.auth0Domain = "login.journey.live";
        this.auth0ClientId = "Ls5w7WWihmQry8fLg8i92HSE1VAKHV2C";
        this.auth0Audience = "https://api.journey.live";
        this.appVersion = "1.0.14";
    }
    getValueForEnvironment(valueName, options) {
        const selectedValue = options[this.environment];
        if (!selectedValue) {
            throw new Error(`Invalid ${valueName} value found: '${selectedValue}' for '${this.environment}' environment. Expected a string.`);
        }
        else {
            return selectedValue;
        }
    }
}
exports.default = new ClientConfig();
