import * as React from 'react';
import {ApiClientConfiguration, ApiResourcesContentLanguage, ApiUser} from "journey-shared/journey/ApiTypes";

export const DEFAULT_CLIENT_CONFIGURATION: ApiClientConfiguration = {
  liveVideoProvider: "VIMEO",
  videoProvider: "VIMEO"
}

export interface ApplicationContextType {
    currentUser: ApiUser | null;
    setUserFlags: (flag: string) => void;
    isCurrentUserLoading: boolean;
    isMobileWidth: boolean;
    handleLogout: () => void;
    refreshUserData: () => Promise<any>;
    silentlyRefreshUserData: () => Promise<any>;
    handleGeneralError: (description: string, error?: any) => void;
    handleUserError: (header: string, description: string) => void;
    handleApplicationError: (description: string) => void;
    handleGeneralMessage: (header: string, description: string) => void;
    openDownloadMobileAppModal: () => void;
    isProfileMenuOpen: boolean;
    handleToggleProfileMenu: () => void;
    profileStepsCompleted: number;
    totalProfileSteps: number;
    isLanguagesMenuOpen: boolean;
    handleToggleLanguagesMenu: () => void;
    openAssessmentModal: () => void;
    hasCompletedAssessment: boolean;
    isDownloadMobileAppModalOpen: boolean;
    clientConfiguration: ApiClientConfiguration;
    appResumeCounter: number;
    language: ApiResourcesContentLanguage | null;
    isEnglishLanguage: boolean;
    changeLanguage: (lang: ApiResourcesContentLanguage) => void;
    openMindfulBreakModal: () => void;
    isMindfulBreakModalOpen: boolean;
    setIsMindfulBreakModalOpen: (open: boolean) => void;
    openResourcesEmailFormModal: () => void;
    isResourcesEmailFormModalOpen: boolean;
    setIsResourcesEmailFormModalOpen: (open: boolean) => void;
    openScheduleAppointmentModal: () => void;
    isScheduleAppointmentModalOpen: boolean;
    setIsScheduleAppointmentModalOpen: (open: boolean) => void;
    currentlyPlayingId: string,
    setCurrentlyPlayingId: (id: string) => void,
    openEapSupportPhonesModal: () => void;
    isEapSupportPhonesModalOpen: boolean;
    setIsEapSupportPhonesModalOpen: (open: boolean) => void;
}

const noop = () => {};
export const ApplicationContext: React.Context<ApplicationContextType> =
  React.createContext<ApplicationContextType>({
      currentUser: null,
      setUserFlags: noop,
      isCurrentUserLoading: true,
      isMobileWidth: true,
      handleLogout: noop,
      refreshUserData: async () => {return Promise.resolve()},
      silentlyRefreshUserData: async () => {return Promise.resolve()},
      handleGeneralError: () => {return Promise.reject("Not implemented")},
      handleUserError: () => {return Promise.reject("Not implemented")},
      handleApplicationError: () => {return Promise.reject("Not implemented")},
      handleGeneralMessage: () => {return Promise.reject("Not implemented")},
      openDownloadMobileAppModal: noop,
      isProfileMenuOpen: false,
      handleToggleProfileMenu: noop,
      profileStepsCompleted: 0,
      totalProfileSteps: 0,
      isLanguagesMenuOpen: false,
      handleToggleLanguagesMenu: noop,
      openAssessmentModal: noop,
      hasCompletedAssessment: false,
      isDownloadMobileAppModalOpen: false,
      clientConfiguration: DEFAULT_CLIENT_CONFIGURATION,
      appResumeCounter: 0,
      language: null,
      isEnglishLanguage: true,
      changeLanguage: noop,
      openMindfulBreakModal: noop,
      isMindfulBreakModalOpen: false,
      setIsMindfulBreakModalOpen: noop,
      openResourcesEmailFormModal: noop,
      isResourcesEmailFormModalOpen: false,
      setIsResourcesEmailFormModalOpen: noop,
      openScheduleAppointmentModal: noop,
      isScheduleAppointmentModalOpen: false,
      setIsScheduleAppointmentModalOpen: noop,
      currentlyPlayingId: '',
      setCurrentlyPlayingId: noop,
      openEapSupportPhonesModal: noop,
      isEapSupportPhonesModalOpen: false,
      setIsEapSupportPhonesModalOpen: noop
  });


