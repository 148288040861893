export const ScheduleAppointmentTerms = ("To Our Clients:\n" +
    "\n" +
    "Welcome to Journey Proactive EAP. Our goal is to provide you with professional assistance in a confidential and secure setting. We value your privacy and there are a few things we want you to understand about our privacy practices as well as procedures for exercising your rights.\n" +
    "\n" +
    "OUR LEGAL DUTY\n" +
    "Journey Proactive EAP conducts business in accordance with the guidelines established by the Federal Health Insurance Portability and Accountability Act (HIPAA). This notice describes how protected health information (medical information) about you may be used and disclosed and how you can gain access to this information. Please review it carefully and feel free to ask questions.\n" +
    "\n" +
    "We are required by applicable federal and state law to maintain the privacy of your health information. We are also required to give you this Notice about our privacy practices, legal duties, and your rights concerning your health information.\n" +
    "\n" +
    "YOUR PROTECTED HEALTH INFORMATION\n" +
    "\n" +
    "1. Uses and Disclosures Journey Proactive EAP provides a safe and secure place to address your personal issues and concerns. We will help to assess your situation and develop the most appropriate plan of action with you. The plan may include counseling and/or a referral to an appropriate professional or program to help resolve your problem. We may use or disclose your health information to a counselor, behavioral health, or medical care professional for purposes of assessing your counseling needs, making appropriate referrals, and ensuring the continuity of your care.\n" +
    "\n" +
    "There is no cost to you for services provided by Journey Proactive EAP's Clinical Department, however, we do not cover the cost of services to which you may be referred. We attempt to maintain up-to-date information on your health insurance coverage so that we can refer you to providers covered by your plan, and will work with you to verify your insurance benefit coverage. We may use or disclose your health information to facilitate payment for services.\n" +
    "\n" +
    "Finally, Journey Proactive EAP may use and disclose your health information in connection with our operations. This includes quality assurance and improvement activities and reviewing the performance of our counselors.\n" +
    "\n" +
    "2. Appointment Reminders We may use or disclose your health information to provide you with appointment reminders (such as voicemail messages, postcards, or letters).\n" +
    "\n" +
    "3. Requesting Restrictions You may initiate restrictions on certain uses and disclosures of your protected health information as described in #1. Journey Proactive EAP will do everything possible to reach a reasonable consensus with you in regard to these restrictions. However, Journey Proactive EAP may deny service if it deems such restrictions would significantly preclude our ability to provide you with professional services.\n" +
    "\n" +
    "4. Your Written Authorization Journey Proactive EAP will not release your private health information to other organizations not involved with your treatment, payment, and health care operations; such as your employer, schools, and some types of community services, without your written authorization. If you have been formally referred to the Assistance Program by your company due to workplace performance or behavioral issues, you will be asked to sign an authorization giving us permission to report attendance and compliance with the program to your employer.\n" +
    "You have a right to revoke an authorization in writing.\n" +
    "\n" +
    "\n" +
    "5. Accounting of Disclosures You have a right to an accounting of some disclosures of your protected health information to third parties. This does not include disclosures you authorize or disclosures that occur in the context of providing you with routine services.\n" +
    "\n" +
    "6. Required Disclosures There are four limitations to confidentiality, which are beyond our control. They are as follows:\n" +
    "\n" +
    "a. If we learn of any alleged child abuse or neglect or abuse of the elderly, we are required by law, as mental health professionals, to report this to the State Child Abuse Registry or State Adult Protective Services. This information will not be shared with your company.\n" +
    "b. If in our judgment a client is dangerous to himself/herself or others, we may need to break confidentiality in order to assure a client’s safety or the safety of others.\n" +
    "c. If a client reveals to us information relating to the contemplation of a criminal act, we may need to break confidentiality to prevent the criminal act.\n" +
    "d. If we are required to present records and/or a counselor to comply with a court order.\n" +
    "\n" +
    "7. Questions and Complaints Journey Proactive EAP has appointed a privacy officer who monitors the confidentiality of your protected health information. Journey Proactive EAP relies on your input so that we may continually ensure that we are protecting your privacy and effectively communicating your privacy rights. If you have a question, wish to provide feedback, or initiate requests related to items 1-6 above, you may contact the privacy officer at 212-686-6827.\n" +
    "\n" +
    "If you believe that your privacy rights have been violated you may make a complaint to our privacy officer or the Federal Office of the Civil Rights Privacy Unit at 866-627-7748. Journey Proactive EAP will not take action against you for doing so.\n" +
    "\n" +
    "8. E-mail Journey Proactive EAP does not recommend that you communicate with us through e-mail other than for routine matters such as confirmation of appointments. If you choose to do so, Journey Proactive EAP cannot guarantee that it can protect any health information contained in such e-mail.\n" +
    "\n" +
    "9. Alternative Communication You have the right to request that we communicate with you about your health information by alternative means or at alternative locations. You must make your request in writing. Your request must specify the alternative means or location.\n" +
    "\n" +
    "10. Revised Notices Journey Proactive EAP reserves the right to make changes to its privacy practices and will provide you with a revised notice relevant to your protected health information as long as you are an active client of Journey Proactive EAP.\n" +
    "\n" +
    "11. Right to Paper Copy If you wish, you may access this notice on our website at www.ccainc.com/privacy or request a copy to be sent via unsecured E-mail. A paper copy will be furnished upon your request.\n" +
    "\n" +
    "12. By signing below you are acknowledging receipt of our privacy practices and providing your consent for Journey Proactive EAP to assist you as described above. If you choose not to sign, we will make note of your refusal and may ask you to sign specific consent forms in order to engage in the activities needed to facilitate your care.\n ");