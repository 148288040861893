import "./MoreVideosByTeacher.scss";
import * as React from "react";
import {IonButton, IonIcon} from "@ionic/react";
import AnalyticsService from "../../misc/AnalyticsService";
import {useHistory, useLocation} from "react-router-dom";
import { ApiTeacher } from "journey-shared/journey/ApiTypes";
import TvPlay from "../../assets/images/tv-play.svg";

interface Props {
    teacher: ApiTeacher;
}

export const MoreVideosByTeacher: React.FC<Props> = (props) => {

    const location = useLocation();
    const history = useHistory();

    async function handleButtonClick () {
        await AnalyticsService.trackUserAction("more_videos_by_teacher", location.pathname);
        history.push(`/teacher/${props.teacher.id}`);
    }

    return(
        <div className="more-videos-by-teacher-component">
            <div className="teacher-container">
                <span className="circle" onClick={handleButtonClick}>
                    {props.teacher.headshotThumbnailImageS3Path && <img src={props.teacher.headshotThumbnailImageS3Path} alt="teacher"/>}
                </span>
                <div className="column">
                    <div className={"header-6"} onClick={handleButtonClick}>
                        {props.teacher.firstName}
                    </div>
                    <div className={"caption"}>
                        {props.teacher.title}
                    </div>
                </div>
            </div>
            <div className="button-container">
                <IonButton onClick={handleButtonClick} className="more-videos-button button-small-variant">
                    <IonIcon slot="start" icon={TvPlay} aria-hidden="true" />
                    {`More videos by ${props.teacher.firstName}`}
                </IonButton>
            </div>
        </div>
    )
}