import * as React from "react";
import {useContext} from "react";
import "./LanguagesHeader.scss";
import {
    IonButton,
    IonIcon,
} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import ExpandMore from "../../assets/images/expand_more.svg";
import { useTranslation } from 'react-i18next';

export const LanguagesHeader: React.FC = () => {

    const { t } = useTranslation();
    const {handleToggleLanguagesMenu, isLanguagesMenuOpen} = useContext(ApplicationContext);

    return (
        <div className="languages-header" onClick={handleToggleLanguagesMenu}>
            <div className="name-container">
                <div className="name button-small">
                    {t('Languages')}
                </div>
            </div>

            <IonButton className={`dropdown-button ${isLanguagesMenuOpen ? 'down' : 'up'}`} onClick={handleToggleLanguagesMenu}>
                <IonIcon className="dropdown-icon" src={ExpandMore}/>
            </IonButton>
        </div>
    )
}
