import React, {useEffect, useContext, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {ApplicationContext} from "../../misc/ApplicationContext";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    const location = useLocation();

    const {refreshUserData, currentUser} = useContext(ApplicationContext);
    const [currentPathName, setCurrentPathName] = useState<string | null>(null);

    useEffect(() => {
        if (location.pathname !== currentPathName) {
            setCurrentPathName(location.pathname);
            AnalyticsService.trackPageNavigation(location.pathname);
        }
    }, [location]);

    useEffect(() => {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        //retrieve token from url if present
        let token = new URLSearchParams(event.url.split('?').pop()).get("token");
        if(token) {
          JourneyApiClient.setAuthCredentials(token);
          refreshUserData();
        }
        // Example url: https://app2.journey.live/watch/1102?param=foo
        // slug = /watch/1102?param=foo
        const slug = event.url.split('.live').pop();
        if (slug) {
          if(!currentUser && !slug.includes("/auth/sso")) {
            JourneyApiClient.setRedirectUrl(slug);
          }
          history.push(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, []);

    return null;
  };

  export default AppUrlListener;
