import React from 'react';
import './DeleteAccountModal.scss';
import {IonButton, IonIcon, IonModal, IonSpinner} from "@ionic/react";
import {close} from 'ionicons/icons';

interface DeleteAccountModalProps {
    isOpen: boolean;
    close: () => void;
    handleDeleteAccount: () => void;
    isDeletionLoading: boolean;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = (props: DeleteAccountModalProps) => {

    const handleDeletionRefusalButtonPressed = () => {
        props.close();
    }


    //TODO: remove disabling button
    return(
        <IonModal isOpen={props.isOpen} className={"account-deletion-modal-component"} backdropDismiss={false}>
            <div className="exit-button-container">
                <IonButton
                    disabled={props.isDeletionLoading}
                    className="exit-button"
                    fill="clear" onClick={() => props.close()}>
                    <IonIcon icon={close}/>
                </IonButton>
            </div>

            <p className="warning-text-question">Are you sure you want to delete your account?</p>
            <p className="warning-text">You will be logged out immediately and all of your data will be removed.</p>
            <div className="buttons-container">
                <IonButton
                    disabled={props.isDeletionLoading}
                    className="buttons delete-confirmation-button"
                    onClick={props.handleDeleteAccount}>
                    {props.isDeletionLoading ? <IonSpinner name="circles"/> : "Delete Account"}
                </IonButton>
                <IonButton
                    disabled={props.isDeletionLoading}
                    className="buttons do-not-delete-button"
                    onClick={handleDeletionRefusalButtonPressed}>
                    Keep Account
                </IonButton>
            </div>
        </IonModal>
    );
}

export default DeleteAccountModal;
