import "./../HomePage/HomePage.scss";
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import { ApiVideoCategory } from "journey-shared/journey/ApiTypes";
import {IonButton, IonContent, IonItemDivider, IonModal, IonPage} from "@ionic/react";
import SwiperCore, {FreeMode, Navigation} from 'swiper';
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {useHistory, useLocation} from "react-router-dom";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {ApplicationContext} from "../../misc/ApplicationContext";

import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenu} from "../../components/SideMenu/SideMenu";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {FeaturedContentCarrousel} from "../../components/FeaturedContentCarrousel/FeaturedContentCarrousel";
import {LoginComponent} from "../../components/LoginComponent/LoginComponent";
import AnalyticsService from "../../misc/AnalyticsService";
import FooterComponent from "../../components/Footer/FooterComponent";
import { CategoryHeader } from '../../components/ContentListings/CategoryHeader';
import { LIVE_STREAM_CATEGORY_NAME } from 'journey-shared/journey/utils';
import { ImageCarousel } from "../../components/ContentListings/ImageCarousel";
import { VideoCategoryResponse, ContentItem } from "journey-shared/journey/ApiTypes";
import ClientConfig from "journey-shared/journey/ClientConfig";
import {LoginModalComponent} from "../../components/LoginModalComponent/LoginModalComponent";
import {CheckinGraph} from "../../components/CheckinGraph/CheckinGraph";
import {DailyPractice} from "../../components/DailyPractice/DailyPractice";
import {ActivityCalendar} from "../../components/ActivityCalendar/ActivityCalendar";
import {ArrowContainer, Popover} from "react-tiny-popover";
import {MonthlyAssessmentPopover} from "../../components/MonthlyAssessmentPopover/MonthlyAssessmentPopover";


export const VideoListingPage: React.FC = () => {

    const [recommendations, setRecommendations] = useState<ContentItem[]>([]);
    const [collections, setCategories] = useState<VideoCategoryResponse[]>([]);
    const [liveStreams, setLiveStreams] = useState<ContentItem[]>([]);
    const [featuredContent, setFeaturedContent] = useState<ApiVideoCategory[]>([]);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
    const {isMobileWidth, currentUser, handleGeneralError, clientConfiguration} = useContext(ApplicationContext);
    const [continueWatchingList, setContinueWatchingList] = useState<ContentItem[]>([])
    const [savedList, setSavedList] = useState<ContentItem[]>([])
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const location = useLocation();
    const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");
    SwiperCore.use([Navigation, FreeMode]);

    useEffect(() => {
        if (currentUser) {
            let redirectUrl = JourneyApiClient.getRedirectUrl();
            if (redirectUrl && redirectUrl !== '/') {
                JourneyApiClient.clearRedirectUrl();
                history.replace(redirectUrl);
            }
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (!isComponentVisible) return;

        setIsContentLoading(true);
        const contentRequests = [
            getHomeContent(),
            getLiveStreams(),
            getFeaturedContent(),
            getRecommendations()
        ];
        if (currentUser) {
            contentRequests.push(getContinueWatching());
            contentRequests.push(getSavedVideos());
        }
        Promise.all(contentRequests).then(() => setIsContentLoading(false));
    }, [isComponentVisible]);


    const getContinueWatching = async () => {
        if (!currentUser) return;
        try {
            const videos = await JourneyApiClient.getContinueWatching();
            setContinueWatchingList(videos);
        } catch (ex) {
            handleGeneralError("Could not get continue wawtching videos", ex);
        }
    }

    const getSavedVideos = async () => {
        if (!currentUser) return;
        try {
            const videos = await JourneyApiClient.getSavedVideos();
            setSavedList(videos);
        } catch (ex) {
            handleGeneralError("Could not get saved videos", ex);
        }
    }

    const getHomeContent = () => {
        const excludeLiveCategory = clientConfiguration.liveVideoProvider === "MUX";
        return JourneyApiClient.getHomeContent({excludeLiveCategory})
            .then(setCategories)
            .catch(error => handleGeneralError("Could not get home content", error));
    }

    const getLiveStreams = () => {
        return JourneyApiClient.getLiveStreamContent()
            .then(setLiveStreams)
            .catch(error => handleGeneralError("Could not get home content", error))
    }

    const getFeaturedContent = () => {
        return JourneyApiClient.getFeaturedContent()
            .then(setFeaturedContent)
            .catch(error => handleGeneralError("Could not get featured content", error));
    }

    const handleRedirect = (url: string) => {
        if (currentUser) {
            history.push(url);
        } else {
            openLoginModal();
        }
    }


    const handleModalDismiss = async () => {
        //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
        await new Promise(r => setTimeout(r, 10));
        setIsLoginModalOpen(false);
    }

    const openLoginModal = () => {
        if (currentUser) return;
        AnalyticsService.trackUserAction("view_login_modal", location.pathname);
        setIsLoginModalOpen(true);
    }

    const getRecommendations = async () => {
        const recommendations = await JourneyApiClient.getDefaultRecommendations();
        setRecommendations(recommendations);
    }

    const now = new Date();
    const greeting = now.getHours() < 5 ? 'Hi Night Owl' :
        now.getHours() < 12 ? `Good Morning` :
            now.getHours() < 17 ? `Good Afternoon`
                : `Good Evening`;

    return (
        <IonPage className={`home-page ${isLoginModalOpen ? "blur-content" : ""}`} id="main-content">
            {isMobileWidth ? <SideMenu/> && <SideMenuButton/> : <Toolbar handleOpenLoginModal={openLoginModal}/>}
            <LoginModalComponent
                isComponentVisible={isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />
            <IonContent className={"main-content-container"}>
                <div className="home-page-daily-checkin">
                     <h6 className="user-greeting-text h6-bold">{greeting}</h6>
                    <div className="checkin-steps-container">
                        <CheckinGraph onCheckin={() => {}} openLoginModal={openLoginModal}/>
                        <DailyPractice state={'placeholder'}/>
                        <ActivityCalendar />
                    </div>
                </div>
                {
                    <>
                        <div className="video-listing-row">
                            <CategoryHeader
                                name={'Recommended'}
                                description={null}
                                onClick={openLoginModal}
                            >

                                <MonthlyAssessmentPopover/>
                            </CategoryHeader>
                            <ImageCarousel onSaveChange={getSavedVideos} onClick={() => {
                                openLoginModal();
                                AnalyticsService.trackUserAction("hp_recommended_video", location.pathname);
                            }} items={recommendations}/>
                        </div>
                        <div  className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider"/></div>
                    </>
                }
                {
                    (clientConfiguration.liveVideoProvider === "MUX") &&
                    <>
                        <div className="video-listing-row">
                            <CategoryHeader
                                name={LIVE_STREAM_CATEGORY_NAME}
                                description={null}
                                onClick={openLoginModal}
                            />
                            <ImageCarousel onClick={openLoginModal} items={liveStreams}/>
                        </div>
                        <div className="home-page-line-divider-container"><IonItemDivider
                            className="home-page-line-divider"/></div>
                    </>
                }
                {
                    (continueWatchingList.length > 0) &&
                    <>
                        <div className="video-listing-row">
                            <CategoryHeader
                                name='Continue Watching'
                                description={null}
                                onClick={openLoginModal}
                            />
                            <ImageCarousel onClick={openLoginModal} items={continueWatchingList}/>
                        </div>
                        <div className="home-page-line-divider-container"><IonItemDivider
                            className="home-page-line-divider"/></div>
                    </>
                }
                {
                    (savedList.length > 0) &&
                    <>
                        <div className="video-listing-row">
                            <CategoryHeader
                                name='My List'
                                description={null}
                                onClick={openLoginModal}
                            />
                            <ImageCarousel onClick={openLoginModal} items={savedList}/>
                        </div>
                        <div className="home-page-line-divider-container"><IonItemDivider
                            className="home-page-line-divider"/></div>
                    </>
                }
                {
                    collections.map((category, index) => {
                        return (
                            <>
                                <div className="video-listing-row" key={index}>
                                    <CategoryHeader
                                        name={category.name}
                                        description={category.shortDescription}
                                        path={`/category/${category.id}`}
                                        onClick={openLoginModal}
                                    />
                                    <ImageCarousel onClick={openLoginModal} items={category.items}/>
                                </div>
                                {index < collections.length - 1 &&
                                <div className="home-page-line-divider-container"><IonItemDivider
                                    className="home-page-line-divider"/></div>}
                            </>
                        )
                    })

                }

                {!isContentLoading && !isMobileApp && <FooterComponent/>}
            </IonContent>
        </IonPage>
    );

}
