import * as React from 'react';
import {useContext} from "react";
import {
    IonButton,
    IonContent,
    IonPage,
} from "@ionic/react";
import "./SettingsPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import DeleteAccount from "../../components/DeleteAccount/DeleteAccount";
import {PRIVACY_POLICY_PATH} from "journey-shared/journey/utils";
import ClientConfig from 'journey-shared/journey/ClientConfig';

export const SettingsPage: React.FC = () => {
    const {isMobileWidth} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");
    const isIos = (ClientConfig.device === "ios");

    return(
        <IonPage className="settings-page">
            {isMobileWidth ? <SideMenuButton title={"Settings"}/> : <Toolbar/>}
            <IonContent className="settings-page-content">
                <div className="buttons-container">
                    {isMobileApp && <IonButton fill="clear" className="privacy-policy-button" routerLink={PRIVACY_POLICY_PATH}>
                        Privacy Policy
                    </IonButton>}
                    {isIos && 
                        <DeleteAccount isMobileWidth={isMobileWidth} isComponentVisible={isComponentVisible}/>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
};
