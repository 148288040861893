import './DailyVideoPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {VideoResponse} from "journey-shared/journey/ApiTypes";
import {IonContent, IonPage} from "@ionic/react";
import {SideMenu} from "../../components/SideMenu/SideMenu";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { DailyVideo } from '../../components/DailyVideo/DailyVideo';
import {DAILY_JOURNEY_PATH, DAILY_VIDEO_PATH} from 'journey-shared/journey/utils';
import ClientConfig from "journey-shared/journey/ClientConfig";

export const DailyVideoPage: React.FC = () => {

    const [isLoading, setIsVideoContentLoading] = useState<boolean>(true);
    const [muxVideo, setMuxVideo] = useState<VideoResponse|null>(null);
    const {isMobileWidth, handleGeneralError, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();

    async function getVideoContent() {
        setIsVideoContentLoading(true);
        try {
            const jsonResponse = await JourneyApiClient.getDailyVideoInfo()
            setMuxVideo(jsonResponse.video);
            setIsVideoContentLoading(false);
        } catch (error) {
            setIsVideoContentLoading(false);
            handleGeneralError("Could not get video content", error);
        }
    }

    useEffect(() => {
        if(!isComponentVisible) return;
        getVideoContent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);


    return (
        <IonPage className={`daily-video-page`}>
            {isMobileWidth ? <SideMenu/> && <SideMenuButton/> : <Toolbar/>}
            <IonContent>
            {currentUser && muxVideo &&
            <DailyVideo
                muxVideo={muxVideo}
                currentUser={currentUser}
                playVideoOnMute={false}/>}
            </IonContent>
        </IonPage>
    );

};
