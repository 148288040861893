import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import {useHistory} from "react-router-dom";
import "./ParticipantRegistrationPage.scss";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {
    HOME_PATH
} from "journey-shared/journey/utils";
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";

export const ParticipantRegistrationPage: React.FC = () => {
    const [isOptInCompleted, setOptInCompleted] = useState<boolean>(false);
    const {isMobileWidth, handleApplicationError} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    let champion = queryParams.get("champion") ?? undefined;

    useEffect(() => {
        (async function() {
            if(isComponentVisible){
                try {
                    await JourneyApiClient.setUserAsMhamParticipant(champion);
                    setOptInCompleted(true);
                } catch(e) {
                    handleApplicationError("Unable to opt in as a Participant. Try again!");
                    history.push(HOME_PATH);
                }
            }
        })();
    }, [isComponentVisible]);

    return(
        <IonPage className={`participant-registration-page`}>
            {isMobileWidth ? <SideMenuButton title={"Participant Opt In"}/> : <Toolbar/>}
            <IonContent className="participant-page-content">
                {isOptInCompleted ?
                    <SuccessMessage 
                        isComponentVisible={isComponentVisible} 
                        redirectToHome={true} 
                        message={`Thank you for signing up to participate in the Mental Health Awareness Month Challenge! Please check your email for more information.`}/>
                    :
                    <PleaseWait/>
                }
            </IonContent>
        </IonPage>
    )
};
