import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./TeacherPage.scss";
import {useHistory} from "react-router-dom";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import { VideoTile } from '../../components/ContentListings/VideoTile';
import { PlaylistTile } from '../../components/ContentListings/PlaylistTile';
import { ApiTeacher } from 'journey-shared/journey/ApiTypes';
import ClampLines from 'react-clamp-lines';

export const TeacherPage: React.FC = () => {

    const [teacher, setTeacher] = useState<ApiTeacher | null>();
    const {isMobileWidth, handleGeneralError } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();

    useEffect(() => {
        if(!isComponentVisible) return;

        setTeacher(null);
        (async function () {
            try {
                const teacherId = window.location.pathname.split("/")[2];
                const teacher = await JourneyApiClient.getTeacher(teacherId);
                setTeacher(teacher);
            } catch(error) {
                handleGeneralError("Could not get teacher.", error);
                history.replace("/");
            }
        })();
    }, [isComponentVisible]);

    return(
        <IonPage className="teacher-page">
            {isMobileWidth ? <SideMenuButton title={teacher?.firstName}/> : <Toolbar/>}

            <IonContent className="teacher-content-container">
                {!teacher ? <PleaseWait/> : <>
                <div className='teacher-header'>
                    {teacher.bannerThumbnailImageS3Path && <img src={teacher.bannerThumbnailImageS3Path} />}
                        <div>
                            <h1>{teacher.firstName} {teacher.lastName}</h1>
                            <ClampLines
                                text={teacher.bio ?? ''}
                                id="really-unique-id"
                                lines={8}
                                ellipsis="..."
                                moreText="Expand"
                                lessText="Collapse"
                                className="clamp-description"
                                innerElement="p"
                            />
                        </div>
                    </div>
                    <div className="teacher-content">
                        {teacher.items?.map((item, i: number) => {
                            switch (item.type) {
                                case 'playlist':
                                    return <div key={i} className='content-tile'><PlaylistTile key={i} playlist={item} /></div>
                                case 'video':
                                    return <div key={i} className='content-tile'><VideoTile key={i} video={item} /></div>
                                default:
                                    return <div key={i}></div>
                            }
                        })}
                    </div>
                </>}
            </IonContent>
        </IonPage>
    )
};
