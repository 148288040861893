import "./SearchPage.scss";
import * as React from 'react';
import {useContext, useEffect, useState, useMemo} from "react";
import {
    IonContent,
    IonPage,
    IonItem,
    IonInput, IonIcon, IonSpinner, IonToolbar, IonButton
} from "@ionic/react";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import SearchIcon from "../../assets/images/search-icon.svg";
import SearchIconGray from "../../assets/images/search-icon-gray.svg";
import ClearIcon from "../../assets/images/clear-icon.svg";
import {SearchResultContentItem} from "journey-shared/journey/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import { VideoTile } from '../../components/ContentListings/VideoTile';
import { PlaylistTile } from '../../components/ContentListings/PlaylistTile';
import LodashDebounce from "lodash.debounce";
import { GenericTile } from "../../components/ContentListings/GenericTile";
import {useLocation, useParams} from "react-router-dom";

type SearchPageRouteParams = {
    query: string
};

export const SearchPage: React.FC = () => {
    const [searchInput, setSearchInput] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [content, setContent] = useState<SearchResultContentItem[] | null>(null);
    const {isMobileWidth, handleGeneralError } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const params = useParams<SearchPageRouteParams>();
    const location = useLocation();

    useEffect(() => {
        if(isComponentVisible){
            setIsLoading(false);
            if(params.query){
                cleanAndSetSearchInput(params.query);
            }
        }
    }, [isComponentVisible, params.query]);


    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, []);

    useEffect(() => {
        debouncedSearch(searchInput)
    }, [searchInput]);

    async function trackSearchString(keyword: string) {
        await AnalyticsService.trackUserAction("search_content", location.pathname, {keyword});
    }

    const cleanAndSetSearchInput = (inputString: string | null) => {
        let newSearch = inputString ? inputString.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null;
        setSearchInput(newSearch);
    }

    const searchContent = (searchString: string | null) => {
        if(searchString){
            setIsLoading(true);
            JourneyApiClient.searchByTitle(searchString).then(list => {
                trackSearchString(searchString);
                setContent(list);
                setIsLoading(false);
            }).catch(error => {
                handleGeneralError("Could not fetch videos", error);
                setIsLoading(false);
            });
        }
    }

    function handleClearSearch () {
        setSearchInput(null);
    }

    const debouncedSearch = useMemo(() => {
        return LodashDebounce(searchContent, 300);
    }, []);

    return(
        <IonPage className={`search-page`}>
            {isMobileWidth ? <div className={"side-menu-container"}><SideMenuButton title={"Search"}/></div> : <Toolbar/>}
            <IonToolbar className="search-page-toolbar">
                <IonItem className="search-item">
                    <IonInput
                        className="header-6 search-input"
                        value={searchInput}
                        placeholder="Search"
                        onIonChange={e => cleanAndSetSearchInput(e.detail.value!)}>
                    </IonInput>
                    {searchInput !== null && <IonButton className={"clear-button"} onClick={handleClearSearch} fill={"clear"}><IonIcon icon={ClearIcon} /></IonButton>}
                    <IonIcon className={"search-icon"} slot="end" icon={searchInput === null ? SearchIcon : SearchIconGray} />
                </IonItem>
            </IonToolbar>
            <IonContent className="search-page-content">
                {isLoading ?
                    <div className="search-page-loading-indicator-container">
                        <IonSpinner className="search-page-loading-indicator" name={"crescent"}/>
                    </div>
                    :
                    (content && content.length > 0 ?
                        <div className="search-container">
                            {content.map((item, i: number) => {
                                switch (item.type) {
                                    case 'playlist':
                                        return <div key={i} className='content-tile'><PlaylistTile showDescription={true} key={i} playlist={item} /></div>
                                    case 'video':
                                        return <div key={i} className='content-tile'><VideoTile showDescription={true} key={i} video={item} /></div>
                                    case 'wpocontent':
                                        return <div key={i} className='content-tile'><GenericTile item={item} key={i}  /></div>
                                    default:
                                        return <div key={i}></div>
                                }
                            })}
                        </div>
                        :
                        <div>
                            <div className="results-text caption">{searchInput ? `No results for ${searchInput}` : "Start typing to see results"}</div>
                        </div>
                    )
                }
            </IonContent>
        </IonPage>
    )
};
