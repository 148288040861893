import "./ImageCarousel.scss";
import { MouseEventHandler, useContext, useState} from 'react';
import {IonButton, IonIcon} from "@ionic/react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import ForwardArrow from "../../assets/images/forward-arrow-blue.svg";
import { ApplicationContext } from "../../misc/ApplicationContext";
import {FreeMode, Navigation} from 'swiper';
import { VideoTile } from "./VideoTile";
import { ContentItem } from "journey-shared/journey/ApiTypes";
import { v4 as uuidv4 } from 'uuid';
import { PlaylistTile } from "./PlaylistTile";
import { LiveStreamTile } from "./LiveStreamTile";
import { GenericTile } from './GenericTile';

export type ImageCarouselProps = {
    items: ContentItem[];
    onClick?: MouseEventHandler;
    className?: string;
    onSaveChange?: (saved: boolean) => void;
    showVideoTitles?: boolean;
    showVideoDescriptions?: boolean;
}

function noop() {}

export function ImageCarousel(props: ImageCarouselProps) {

    const { isMobileWidth } = useContext(ApplicationContext);
    const [uniqueId, setUniqueId] = useState<string>(uuidv4());

    return(
        <div className={`image-carousel ${props.className ?? ''}`} onClick={props.onClick ?? noop}>
            <IonButton fill="clear" className={`custom_prev_${uniqueId} arrow-button left`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <IonButton fill="clear" className={`custom_next_${uniqueId} arrow-button right`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <Swiper
                className="swiper"
                modules={[Navigation, FreeMode]}
                scrollbar={false}
                freeMode={true}
                navigation={{
                    nextEl: `.custom_next_${uniqueId}`,
                    prevEl: `.custom_prev_${uniqueId}`,
                    disabledClass: 'hide-arrow'
                }}
                spaceBetween={isMobileWidth ?  20 : 40}
                slidesPerView={isMobileWidth ?  1.4 : window.innerWidth < 769 ? 2 : window.innerWidth < 1024 ? 2.5 : window.innerWidth < 1280 ? 3.3 : 4} >
                {
                    props.items.map((item, i) => {

                        switch (item.type) {
                            case 'playlist':
                                return <SwiperSlide key={i}><PlaylistTile onSaveChange={props.onSaveChange} playlist={item} className='image-carousel-tile' showDescription={true} /></SwiperSlide>
                            case 'video':
                                return <SwiperSlide key={i}><VideoTile onSaveChange={props.onSaveChange} video={item} className='image-carousel-tile' showDescription={props.showVideoDescriptions ?? true} showTitle={props.showVideoTitles} /></SwiperSlide>
                            case 'livestream':
                                return <SwiperSlide key={i}><LiveStreamTile stream={item} className='image-carousel-tile' /></SwiperSlide>
                            case 'generic':
                                return <SwiperSlide key={i}><GenericTile item={item} className='image-carousel-tile' /></SwiperSlide>
                            default:
                                return <SwiperSlide key={i}><div></div></SwiperSlide>
                        }
                    })
                }
            </Swiper>

        </div>
    );
}
