import { ApiLiveStream } from "journey-shared/journey/ApiTypes";
import { SyntheticEvent, useContext } from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../../misc/ApplicationContext";
import moment from "moment-timezone";
import "./Tile.scss";
import {LiveStreamStyle} from "./ContentStyles/LiveStream/LiveStreamStyle";

export type LiveStreamTileProps = {
    stream: ApiLiveStream;
    showDescription?: boolean;
    showTitle?: boolean;
    className?: string;
};

function preventDefault(e: SyntheticEvent) {
    e.preventDefault();
}

function noop() {}

export function LiveStreamTile(props: LiveStreamTileProps) {

    const { currentUser } = useContext(ApplicationContext);

    let scheduleDaysString: string = "";

    if(props.stream.days.length > 0 && props.stream.recurringTimeOfDayUTC) {

        if(props.stream.days.length > 1) {
            let scheduleDaysArray = props.stream.days.join(", ").split(" ");
            // Replace last comma with 'and' so that we have A, B and C
            let secondLast = scheduleDaysArray[scheduleDaysArray.length - 2];
            secondLast = secondLast.substring(0, secondLast.length - 1) + " and";
            scheduleDaysArray[scheduleDaysArray.length - 2] = secondLast;
            scheduleDaysString = scheduleDaysArray.join(" ");
        } else {
            scheduleDaysString = props.stream.days.join("");
        }

         // TimeOfDayUTC is of the form "HH:mm" so we split it into [H, mm]
         const [hour, minutes] = props.stream.recurringTimeOfDayUTC.split(":").map(piece => parseInt(piece, 10));
         // Build a UTC moment object and set the hours and minutes accordingly
         const startTime: moment.Moment = moment().utc().startOf('day').hour(hour).minute(minutes);
         // Turn the moment object to the local timezone and extract the time component
         scheduleDaysString = scheduleDaysString + " - " + startTime.tz(moment.tz.guess()).format("h:mm A");

    } else {
        scheduleDaysString = moment(props.stream.oneTimeScheduleDate).tz(moment.tz.guess()).format("dddd MMM Do - h:mm A")
    }



    return(
        <div className={"tile-component"}>
            <Link onClick={currentUser ? noop : preventDefault} to={`/watch-live/${props.stream.id}`} className={`tile-container ${props.className ?? ''}`}>
                <LiveStreamStyle liveStream={props.stream}/>
            </Link>
            {(props.showTitle ?? true) &&<div className={"out-of-tile-title caption"}>{props.stream.title}</div>}
            <div className={"caption"}>{scheduleDaysString}</div>
        </div>
    )
}