import * as dotenv from "dotenv";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "auth0-react-ionic";
import { isPlatform } from "@ionic/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ClientConfig from "journey-shared/journey/ClientConfig";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from './translations.json';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

dotenv.config();

const platform = isPlatform('ios') ? "ios" : "web";
const redirectUri = isPlatform('android') ? "com.journeylive.android://dev-apto9omc.us.auth0.com/capacitor/com.journeylive.android/callback" : window.location.origin;

Sentry.init({
    dsn: "https://422aaeb192b94f83bd850aebae7bf5df@o400167.ingest.sentry.io/6440139",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.10,
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
            domain={ClientConfig.auth0Domain}
            clientId={ClientConfig.auth0ClientId}
            redirectUri={redirectUri}
            scope={"openid profile email all:api"}
            audience={ClientConfig.auth0Audience}
            platform={platform}
        >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
