import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import "./ContentArticlePage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {ApiResourcesContentArticle} from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {useParams} from "react-router-dom";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { ResourcesBackButton } from '../../components/Resources/ResourcesBackButton';

type ResourcesArticleRouteParams = {
    articleId: string;
};

export const ContentArticlePage: React.FC = () => {

    const {isMobileWidth, handleGeneralError} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const [article, setArticle] = useState<ApiResourcesContentArticle | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const params = useParams<ResourcesArticleRouteParams>();

    useEffect(() => {
        if(isComponentVisible) {
            getContentArticle(params.articleId);
        }
    }, [isComponentVisible]);

    function getContentArticle(articleId: string) {
        setIsLoading(true);
        JourneyApiClient.getResourcesContentArticle(articleId).then(article => {
            setArticle(article);
            setIsLoading(false);
        }).catch( (error: any) => {
            handleGeneralError("Unable to fetch Resources Article by ArticleId", error);
            setIsLoading(false);
        })
    }

    return(
        <IonPage className="content-article-page">
            {isMobileWidth ? <SideMenuButton title={"Resources"}/> : <Toolbar/>}
            <IonContent className="article-content">
                {isLoading || !article ? <PleaseWait/> :
                <div className="article-container">
                     {!isMobileWidth && <ResourcesBackButton />}
                    <div className="article-title header-5">
                        {article.title}
                    </div>
                    <div className="article-body body-medium" dangerouslySetInnerHTML={{__html: article.body ?? ""}}>
                    </div>
                </div>}
            </IonContent>
        </IonPage>
    )
}
