import React, {useEffect, useState} from 'react';
import "./ProgressIndicator.scss";

interface ProgressIndicatorProps{
    currentStep: number;
    totalSteps: number;
}

interface Progress {
    state: "complete" | "current" | "incomplete";
    color: "green-no-gradient" | "beige-no-gradient";
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props: ProgressIndicatorProps) => {

    const [progress, setProgress] = useState<Progress[]>([]);

    useEffect(() => {
        createSteps(props.currentStep, props.totalSteps);
    }, [props.currentStep])


    function createSteps(currentStep: number, totalSteps: number) {
        const completedSteps: number = props.currentStep > 0 ? props.currentStep - 1 : 0;
        let progress: Progress[] = [];
        for (let i = 0; i < totalSteps; i++) {
            if (i === props.currentStep) {
                progress.push({
                    state: "current",
                    color: "green-no-gradient"
                });
            } else if (i <= completedSteps) {
                progress.push({
                    state: "complete",
                    color: "green-no-gradient"
                });
            } else {
                progress.push({
                    state: "incomplete",
                    color: "beige-no-gradient"
                });
            }
        }
        setProgress(progress);
    }


    return (
        <div className={"progress-indicator-component"}>
            {progress.map((step: Progress, index) => {
                return (
                    <>
                        {index > 0 && <div className={`rectangle ${step.color}`}/>}
                        <div className={`full-circle ${step.color}`} key={index}>
                            {step.state === "current" ? <div className={"half-circle-container"}>
                                <div className={"half-circle"}/>
                            </div> : null}
                        </div>
                    </>

                )
            })}
        </div>
    )
}