import React, {SyntheticEvent} from 'react';
import './AddToPlaylist.scss';
import {IonIcon} from "@ionic/react";
import RemoveFromList from "../../assets/images/list-remove.svg";
import AddToList from "../../assets/images/add-to-list.svg";

export type AddToPlaylistProps = {
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
};

export function AddToPlaylist(props: AddToPlaylistProps) {

    return(
        <div className="add-to-playlist-component">
            {props.isSaved && <>
                <div className='tooltip remove'>Remove from My List</div>
                <IonIcon onClick={props.handleRemoveFromList} src={RemoveFromList}/>
            </>}
            {!props.isSaved && <>
                <div className='tooltip add'>Add to My List</div>
                <IonIcon onClick={props.handleAddToList} src={AddToList}/>
            </>}
        </div>
    )
}