import React, {SyntheticEvent} from 'react';
import "./AudioStyle.scss";
import VideoAudioIcon from "../../../../assets/images/video-audio-icon.svg";
import {VideoPlaylistResponse, VideoResponse} from "journey-shared/journey/ApiTypes";
import {AddToPlaylist} from "../../../AddToPlaylist/AddToPlaylist";

export type AudioStyleProps = {
    content: VideoResponse | VideoPlaylistResponse;
    progress: number | null;
    durationMinutes: string | null;
    durationSeconds: string | null;
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
};

export function AudioStyle(props: AudioStyleProps)  {


    return(
        <div className={`audio-style-component`}>
            <div className={`image-container ${props.progress ? 'progress-visible' : ''}`}>
                <img className={"image"} src={VideoAudioIcon}/>
                <div className={"title-container"}>
                    <div className={"title subtitle2-variant"}>{props.content.title}</div>
                </div>
                {props.durationMinutes ? <div className="duration caption">{`${props.durationMinutes}:${props.durationSeconds}`}</div>: ""}
                <div className={"add-to-playlist-container"}>
                    <AddToPlaylist isSaved={props.isSaved} handleRemoveFromList={props.handleRemoveFromList} handleAddToList={props.handleAddToList}/>
                </div>

            </div>
        </div>
    )
}