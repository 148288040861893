import { differenceInDays } from 'date-fns'
import { JourneyApiClient } from "journey-shared/journey/JourneyApiClient";
import { useContext, useEffect, useState} from "react";
import placeholderOne from "../../assets/images/daily-practice-1.jpg";
import placeholderTwo from "../../assets/images/daily-practice-2.jpg";
import placeholderThree from "../../assets/images/daily-practice-3.jpg";
import placeholderFour from "../../assets/images/daily-practice-4.jpg";
import placeholderFive from "../../assets/images/daily-practice-5.jpg";
import "./DailyPractice.scss";
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import { ApplicationContext } from '../../misc/ApplicationContext';
import AnalyticsService from '../../misc/AnalyticsService';
import { VideoResponse } from 'journey-shared/journey/ApiTypes';
import { MONTHLY_ACTIVITY_INTENSITY_HIGH } from 'journey-shared/journey/utils';
import DailyPracticeImage from "../../assets/images/daily-practice-image.png";
import {useLocation} from "react-router-dom";
import {MuxVideoPlayer} from "../MuxVideoPlayer/MuxVideoPlayer";


const placeHolderImages = [
    placeholderOne,
    placeholderTwo,
    placeholderThree,
    placeholderFour,
    placeholderFive
];

export type DailyPracticeProps = {
    className?: string
    state: 'load-video' | 'autoplay' | 'placeholder'
}

export function DailyPractice(props: DailyPracticeProps) {

    const [dailyPracticeVideo, setDailyPracticeVideo] = useState<VideoResponse|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const { appResumeCounter, currentUser, isMobileWidth} = useContext(ApplicationContext);
    const [dayIndex, setDayIndex] = useState<number | null>(null);
    const [dailyPracticeWatched, setDailyPracticeWatched] = useState<boolean>(false);

    async function fetchDailyPracticeVideo() {
        setIsLoading(true);
        const video = await JourneyApiClient.getDailyPractice();
        setDailyPracticeVideo(video);
        setIsLoading(false)
    }

    async function fetchTodaysActivity() {
        const todayActivity = await JourneyApiClient.getTodaysActivity();
        if (todayActivity) {
            setDailyPracticeWatched(todayActivity.intensity === MONTHLY_ACTIVITY_INTENSITY_HIGH);
        } else {
            setDailyPracticeWatched(false);
        }
    }


    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
        }
        JourneyApiClient.recordActivity('daily-practice-watch');
        await AnalyticsService.trackUserAction("daily_practice_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_practice_video_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("daily_practice_video_checkpoint", location.pathname, eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_practice_video_unmute", location.pathname, eventProperties);
    }

    useEffect(() => {
        if (!isComponentVisible) return;
        const daysSinceEpoch = differenceInDays(new Date(), new Date(0));
        const newDayIndex = daysSinceEpoch % placeHolderImages.length;
        setDayIndex(newDayIndex);
        if (props.state === 'placeholder') {
            setDailyPracticeVideo(null);
        } else if (!dailyPracticeVideo) {
            fetchDailyPracticeVideo();
            fetchTodaysActivity();
        }

    }, [isComponentVisible, appResumeCounter, props.state]);

    return(
        <div className={`daily-practice-component box ${props.className ?? ''}`}>
            {dailyPracticeVideo ? <>
                <MuxVideoPlayer
                    thumbnail={dailyPracticeVideo.thumbnailUrl}
                    videoId={dailyPracticeVideo.id}
                    muxPlaybackId={dailyPracticeVideo.muxPlaybackId}
                    muxVideoId={dailyPracticeVideo.muxVideoId}
                    title={dailyPracticeVideo.title}
                    currentUser={currentUser}
                    trackCuePointEvent={trackCuePointEvent}
                    trackPlayEvent={trackPlayEvent}
                    trackPauseEvent={trackPauseEvent}
                    isComponentVisible={isComponentVisible}
                    isLoading={isLoading}
                    autoplay={props.state === 'autoplay'}
                    isInitialMuted={props.state === 'autoplay'}
                    duration={dailyPracticeVideo.duration}
                    trackUnmute={trackUnmute}
                    hideInitialControls={true}
                    hideRecommendations={false}
                    numberOfRecommendationsToShow={1}
                />
            </>:
                <div className={"daily-practice-padding-container"}>
                    <div className={"background-image-container"}>
                        <img className={"background-image"} src={DailyPracticeImage} />
                    </div>
                    <div className="box-header">
                        <div className="box-title">
                            <div className={"title overline"}>2. Daily Practice</div>
                        </div>
                    </div>

                    <div className="tile">
                        <div className={`tile-text ${isMobileWidth ? "subtitle1-variant" : "header-6-variant"}`}>Check-in first to get a personalized practice!</div>
                    </div>
                </div>
            }
        </div>
    )
}
