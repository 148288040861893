import * as React from "react";
import {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {useContext} from "react";
import "./LanguagesMenuList.scss";
import {
    IonIcon,
    IonItem,
    IonList
} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import Check from "../../assets/images/check-grey.svg";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApiResourcesContentLanguage} from "journey-shared/journey/ApiTypes";

interface Props {
    handleMenuClose?: () => void;
}

function noop() {}

export const LanguagesMenuList: React.FC<Props> = (props: Props) => {

    const {isMobileWidth, language, changeLanguage, handleToggleLanguagesMenu } = useContext(ApplicationContext);
    
    const [languages, setLanguages] = useState<ApiResourcesContentLanguage[]>([]);
    let history = useHistory();

    useEffect(() => {
        (async function() {
            let response = await JourneyApiClient.getResourcesLanguages();
            setLanguages(response);
        })();
    }, []);

    function handleLanguageClick(lang: ApiResourcesContentLanguage): void {
        changeLanguage(lang);
        handleToggleLanguagesMenu();
        history.push("/");
    }

    return (
        <IonList className={`languages-menu-list`}>
            {
                languages.map((lang, index) => {
                    return (
                        <IonItem key={index} className={`${!isMobileWidth && language?.id === lang.id ? 'selected' : ''} menu-item`} onClick={() => handleLanguageClick(lang)}>
                            <div key={index} className={`caption ${lang.country === 'U.S.A.' ? 'underline' : ''}`}>
                                {lang.nameLocalised ?? lang.name} {lang.name === 'English' || lang.name === 'Portuguese' ? ` - ${lang.country}` : ''}
                            </div>
                            {(isMobileWidth && language?.id === lang.id) &&
                                <IonIcon key={index} className="menu-icon" slot="end" icon={Check}  color="default" />
                            }
                        </IonItem>
                )})
            }
        </IonList>
    )
}
