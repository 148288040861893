import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, {useEffect} from "react";
import {Route, Redirect} from "react-router-dom";
import {LoginPage} from "../../pages/LoginPage/LoginPage";
import {VideoListingPage} from "../../pages/VideoListingPage/VideoListingPage";
import {PrivacyPolicyPage} from "../../pages/PrivacyPolicy/PrivacyPolicyPage";
import {RegisterPage} from "../../pages/RegisterPage/RegisterPage";
import {DAILY_JOURNEY_PATH, PRIVACY_POLICY_PATH, REGISTER_PATH, PUBLIC_WATCH_PATH, SSO_LOGIN_PATH, GUINESS_RECORD_CHALLENGE_PATH} from "journey-shared/journey/utils";
import AppUrlListener from './AppUrlListener';
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import { DailyJourneyPage } from "../../pages/DailyJourneyPage/DailyJourneyPage";
import ClientConfig from "journey-shared/journey/ClientConfig";
import {PublicVideoPage} from "../../pages/PublicVideoPage/PublicVideoPage";
import {GuinessRecordChallenge} from "../../pages/GuinessRecordChallenge/GuinessRecordChallenge";
import {SsoLoginPage} from "../../pages/SsoLoginPage/SsoLoginPage";

const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");

export const LoggedOutNavigation: React.FC = () => {

  const isComponentVisible = useIsComponentVisible();
  const queryParams = new URLSearchParams(window.location.search);
  let token = queryParams.get("token");

  useEffect(() => {
      let redirectUrl = window.location.pathname;
      if (redirectUrl && redirectUrl !== '/' && !redirectUrl.startsWith('/auth') && !redirectUrl.startsWith('/world-record-attempt') && !token) {
          JourneyApiClient.setRedirectUrl(redirectUrl);
      }
  }, [isComponentVisible]);


    return(
      <IonReactRouter>
        <AppUrlListener />
        <IonRouterOutlet id="main" animated={isMobileApp}>
          <Route path="/login" component={LoginPage} exact={true}/>
          <Route path={PRIVACY_POLICY_PATH} component={PrivacyPolicyPage} exact={true}/>
          <Route path={REGISTER_PATH} component={RegisterPage} exact={true}/>
          <Route path={PUBLIC_WATCH_PATH} component={PublicVideoPage} exact={true}/>
          <Route path={GUINESS_RECORD_CHALLENGE_PATH} component={GuinessRecordChallenge} exact={true}/>
          <Route path={`${DAILY_JOURNEY_PATH}/:id?`} component={DailyJourneyPage} exact={true} />
          <Route path="/" component={isMobileApp ? LoginPage : VideoListingPage} exact={true}/>
          <Route path={SSO_LOGIN_PATH} component={SsoLoginPage} exact={true}/>
          {!token && // if there is a token in the url, let the logged in navigation take over if successful
            <Route>
                <Redirect to={"/"} exact/>
            </Route>
          }
        </IonRouterOutlet>
      </IonReactRouter>
    );
};
