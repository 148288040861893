import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./CategoryPage.scss";
import {useHistory} from "react-router-dom";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import { VideoTile } from '../../components/ContentListings/VideoTile';
import { PlaylistTile } from '../../components/ContentListings/PlaylistTile';
import { VideoCategoryResponse } from 'journey-shared/journey/ApiTypes';
import ClampLines from 'react-clamp-lines';

export const CategoryPage: React.FC = () => {

    const [category, setCategory] = useState<VideoCategoryResponse | null>();
    const {isMobileWidth, handleGeneralError } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();

    useEffect(() => {
        if(isComponentVisible){
            const categoryId = window.location.pathname.split("/")[2];
            getVideoContent(categoryId);
            setCategory(null);
        }
    }, [isComponentVisible]);

    const getVideoContent = (categoryId: string) => {
        JourneyApiClient.getCategory(categoryId).then(jsonResponse => {
            setCategory(jsonResponse);
        }).catch( error => {
            handleGeneralError("Could not get category", error);
        });
    }

    return(
        <IonPage className="category-page">
            {isMobileWidth ? <SideMenuButton title={category?.name}/> : <Toolbar/>}

            <IonContent className="category-content-container">
                {!category ? <PleaseWait/> : <>
                <div className='category-header'>
                    {!isMobileWidth && category.thumbnailUrl && <img src={category.thumbnailUrl} />}
                        <div className={"category-title-description-container"}>
                            <div className={`${isMobileWidth ? "header-4" : "page-title"} category-title`}>{category.name}</div>
                            <ClampLines
                                text={category.description ?? ''}
                                id="really-unique-id"
                                lines={8}
                                ellipsis="..."
                                moreText="Expand"
                                lessText="Collapse"
                                className={`clamp-description body-large`}
                                innerElement="div"
                            />
                        </div>
                    </div>


                    <div className="category-content">
                        {category.items.map((item, i: number) => {
                            switch (item.type) {
                                case 'playlist':
                                    return <div key={i} className='content-tile'><PlaylistTile key={i} playlist={item} /></div>
                                case 'video':
                                    return <div key={i} className='content-tile'><VideoTile key={i} video={item} /></div>
                                default:
                                    return <div key={i}></div>
                            }
                        })}
                    </div>
                </>}
            </IonContent>
        </IonPage>
    )
};
