import * as React from "react";
import {useContext} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
    
}

export const ProfileProgressBar: React.FC<Props> = (props: Props) => {

    const {profileStepsCompleted, totalProfileSteps} = useContext(ApplicationContext);
    const profileCompleted = profileStepsCompleted === totalProfileSteps;

    return (
        <>
            {!profileCompleted &&
                <CircularProgressbar
                    value={profileStepsCompleted} 
                    maxValue={totalProfileSteps} 
                    text={`${profileStepsCompleted}/${totalProfileSteps}`} 
                    styles={buildStyles({
                        textColor: "#4EDED6",
                        pathColor: "#4EDED6",
                        textSize: "35px",
                        trailColor: "#F7F2ED"
                    })}
                />
            }
        </>
    )
}
