import "./VideoPage.scss";

import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {IonContent, IonItemDivider, IonPage} from "@ionic/react";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {SideMenu} from "../../components/SideMenu/SideMenu";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {
    ContentItem, VideoContentItemResponse, VideoResponse,
} from "journey-shared/journey/ApiTypes";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import AnalyticsService from "../../misc/AnalyticsService";
import {VideoComment} from "../../components/VideoComment/VideoComment";
import {MuxVideoPlayer} from "../../components/MuxVideoPlayer/MuxVideoPlayer";
import {useParams, useLocation} from "react-router-dom";


type VideoPageRouteParams = {
    videoId: string;
    playlistId: string;
    categoryId?: string;
};

export const VideoPage: React.FC = () => {
    const [muxVideo, setMuxVideo] = useState<VideoResponse|null>(null);
    const [isVideoContentLoading, setIsVideoContentLoading] = useState<boolean>(true);
    const {isMobileWidth, currentUser, handleGeneralError, handleApplicationError} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const [videoId, setVideoId] = useState<number | null>(null);
    const params = useParams<VideoPageRouteParams>();

    useEffect(() => {
        if(isComponentVisible){
            const vId = params.videoId;
            setVideoId(parseInt(vId, 10));
            setIsVideoContentLoading(true);
            getVideoContent(vId);
        }
    }, [isComponentVisible, params.videoId]);


    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("video_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("video_checkpoint", location.pathname, eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("video_unmute", location.pathname, eventProperties);
    }

    function getVideoContent (videoId: string) {
        setIsVideoContentLoading(true);
        JourneyApiClient.getVideoById(videoId).then((jsonResponse: VideoResponse) => {
            setMuxVideo(jsonResponse);
            setIsVideoContentLoading(false);
        }).catch( error => {
            setIsVideoContentLoading(false);
            handleGeneralError("Could not get video content", error);
        });
    }

    function handleError (description: string) {
        handleApplicationError(description);
    }

    function getVideoDurationString (duration: number): string  {
        const minutes: number = Math.round(duration / 60);
        return `${minutes} min`;
    }


    return(
        <IonPage className="video-page-component">
            {isMobileWidth ? <SideMenu/> && <SideMenuButton/> : <Toolbar/>}

            <IonContent className="video-content">
                <div className="video-page-wrapper">
                    <div className='video-container'>
                        {(videoId === null || !currentUser || isVideoContentLoading) ?
                            <PleaseWait/> :

                            (muxVideo) &&
                            <MuxVideoPlayer
                                videoId={muxVideo.id}
                                duration={muxVideo.duration}
                                position={muxVideo.position}
                                isLoading={isVideoContentLoading}
                                isComponentVisible={isComponentVisible}
                                muxPlaybackId={muxVideo.muxPlaybackId}
                                muxVideoId={muxVideo.muxVideoId}
                                title={muxVideo.title}
                                currentUser={currentUser}
                                trackCuePointEvent={trackCuePointEvent}
                                trackPlayEvent={trackPlayEvent}
                                trackPauseEvent={trackPauseEvent}
                                handleApplicationError={handleError}
                                trackUnmute={trackUnmute}
                                playlistId={parseInt(params.playlistId)}
                                numberOfRecommendationsToShow={isMobileWidth ? 1 : 6}/>
                        }
                    </div>
                    <div className="video-content-container">
                        {muxVideo && <>
                            <div className="title body-large-variant">{muxVideo.title }</div>
                            <div className="duration caption">{muxVideo.duration ? getVideoDurationString(muxVideo.duration) : ""}</div>
                            <div className="description body-medium">{muxVideo.description}</div>
                        </>}

                        <IonItemDivider className="line-divider"/>
                        <div className={"comments header-5"}>Comments</div>
                        {(muxVideo && videoId && currentUser) && <VideoComment
                            sourceId={videoId}
                            currentUser={currentUser}
                            isLiveStream={false}
                        />}
                    </div>

                </div>
            </IonContent>
        </IonPage>
    )
};
