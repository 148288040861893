import * as React from 'react';
import {useContext} from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import "./LanguagesPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import { LanguagesMenuList } from "../../components/Languages/LanguagesMenuList";
import { useTranslation } from 'react-i18next';

export const LanguagesPage: React.FC = () => {
    const {isMobileWidth} = useContext(ApplicationContext);
    const { t } = useTranslation();

    return(
        <IonPage className="languages-page">
            {isMobileWidth ? <SideMenuButton title={t('Languages')}/> : <Toolbar/>}
            <IonContent className="languages-page-content">
                <LanguagesMenuList />
            </IonContent>
        </IonPage>
    )
};
