import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {IonContent, IonPage, IonButton, IonIcon, IonItem, IonInput, IonText, isPlatform} from "@ionic/react";
import "./GuinessRecordChallenge.scss";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {VideoResponse} from "journey-shared/journey/ApiTypes";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import AnalyticsService from "../../misc/AnalyticsService";
import {MuxVideoPlayer} from "../../components/MuxVideoPlayer/MuxVideoPlayer";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";
import Facebook from "../../assets/images/facebook.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import ArrowRedo from "../../assets/images/arrow-redo.svg";
import WalgreensLogo from "../../assets/images/walgreens.png";
import GuinessWorldRecordsLogo from "../../assets/images/gwr-official-attempt.png";
import {LoginModalComponent} from "../../components/LoginModalComponent/LoginModalComponent";
import { image } from 'ionicons/icons';

export const GuinessRecordChallenge: React.FC = () => {
    const WORLD_RECORD_VIDEO_ID = 39941755;
    const link = 'https://app2.journey.live/world-record-attempt';
    const LIVE_DATE = new Date('2023-05-22T05:00Z');

    const queryParams = new URLSearchParams(window.location.search);
    const ignoreHoldingPage = queryParams.get("ignore-holding-page");
    const isChallengeLive = ignoreHoldingPage === 'true' ? true : new Date() > LIVE_DATE;
    const [muxVideo, setMuxVideo] = useState<VideoResponse|null>(null);
    const [isVideoContentLoading, setIsVideoContentLoading] = useState<boolean>(true);
    const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
    const {currentUser, handleGeneralError, handleApplicationError, handleGeneralMessage, isMobileWidth} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const [videoId, setVideoId] = useState<number | null>(null);
    const [email, setEmail] = useState<string>();
    const [error, setError] = useState<string|null>(null);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(isComponentVisible){
            setVideoId(WORLD_RECORD_VIDEO_ID);
            setIsVideoContentLoading(true);
            getVideoContent(WORLD_RECORD_VIDEO_ID.toString());
        }
    }, [isComponentVisible]);


    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("world_record_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("world_record_video_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("world_record_video_checkpoint", location.pathname, eventProperties);
    }

    const getVideoContent = (videoId: string) => {
        setIsVideoContentLoading(true);
        JourneyApiClient.getPublicVideoById(videoId).then((jsonResponse: VideoResponse) => {
            setMuxVideo(jsonResponse);
            setIsVideoContentLoading(false);
        }).catch( error => {
            setIsVideoContentLoading(false);
            handleGeneralError("Could not get video content", error);
        });
    }

    const handleError = (description: string) => {
        handleApplicationError(description);
    }

    const handleFacebookClick = async () => {
        await AnalyticsService.trackUserAction("world_record_video_facebook_share", location.pathname);
    }

    const handleLinkedInClick = async() => {
        await AnalyticsService.trackUserAction("world_record_video_linkedin_share", location.pathname);
    }

    const handleShareClick = async () => {
        await navigator.clipboard.writeText(link);
        handleGeneralMessage('Link Copied!', link);
    }

    const handleSubmitClick = async () => {
        setError(null);
        if(!email || !validateEmail(email)) {
            setError("Enter a valid email address.");
            return;
        }

        try {
            const eventSignup = await JourneyApiClient.signupToEvent(email, "Walgreens World Record", "walgreens");

            if(eventSignup.userHasRegistered) {
                //redirect to homepage, start login process
                if(currentUser) {
                    history.push("/");
                    return;
                }

                setIsLoginModalOpen(true);
            } else {
                //hide the email input and submit button and display success message
                handleGeneralMessage('Registered!', "Thank you, be in the lookout for an email from Journey");
            }
        } catch(e) {

        }

        setEmailSubmitted(true);
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function handleEmailInputKeypress(e: React.KeyboardEvent) {
        if (e.key !== 'Enter') return;

        handleSubmitClick();
    }

    const openLoginModal = async () => {
        if (currentUser) return;
        setIsLoginModalOpen(true);
    }



    return(
        <IonPage className={`world-record-page-component ${isLoginModalOpen ? "blur-content" : ""}`}>
            {isMobileWidth ? <SideMenuButton title={"Walgreens World Record Attempt"} /> : <Toolbar handleOpenLoginModal={openLoginModal}/>}

            <LoginModalComponent
                isComponentVisible={isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
                email={email}
            />

            <IonContent className="world-record-content">
                {isChallengeLive &&
                    <div className='world-record-video-container'>
                        {(videoId === null || isVideoContentLoading) ?
                            <PleaseWait/> : (muxVideo) &&
                            <MuxVideoPlayer
                                isPublicVideo={true}
                                autoplay={true}
                                videoId={muxVideo.id}
                                duration={muxVideo.duration}
                                position={muxVideo.position}
                                isLoading={isVideoContentLoading}
                                isComponentVisible={isComponentVisible}
                                muxPlaybackId={muxVideo.muxPlaybackId}
                                muxVideoId={muxVideo.muxVideoId}
                                title={muxVideo.title}
                                currentUser={null}
                                trackCuePointEvent={trackCuePointEvent}
                                trackPlayEvent={trackPlayEvent}
                                trackPauseEvent={trackPauseEvent}
                                handleApplicationError={handleError}
                                numberOfRecommendationsToShow={6}
                                isInitialMuted={true}
                                hideRecommendations={true}
                                replayVideo={true}/>
                        }
                    </div>
                }
                {muxVideo &&
                    <div className="world-record-content-container">
                        {isChallengeLive &&
                            <div className='text-content-container'>
                                <img src={WalgreensLogo} className="logo-image" alt="Walgreens Logo" />
                                <h1 className="title">Watch the meditation video above to help break a <b>GUINNESS WORLD RECORDS®</b> title for most views for an online guided meditation video in 24 hours on a bespoke platform.</h1>
                                <h3 className="subtitle">After watching, invite your social networks to meditate with us today!</h3>
                                <div className="socials">
                                    <IonButton onClick={handleFacebookClick} className="button facebook-button" target="_blank" href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(link)}`}>
                                        <IonIcon slot="start" icon={Facebook} aria-hidden="true" />
                                        {`Share on Facebook`}
                                    </IonButton>
                                    <IonButton onClick={handleLinkedInClick} className="button linkedin-button" target="_blank" href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`}>
                                        <IonIcon slot="start" icon={LinkedIn} aria-hidden="true" />
                                        {`Share on LinkedIn`}
                                    </IonButton>
                                    <IonButton onClick={handleShareClick} className="button share-button">
                                        <IonIcon slot="start" icon={ArrowRedo} aria-hidden="true" />
                                        {`Share Link`}
                                    </IonButton>

                                    <IonButton size='default' target='_blank' download='Walgreens-Meditation-Record-Attempt.jpg' href='/assets/Walgreens-Meditation-Record-Attempt.jpg' className="button">
                                        <IonIcon slot="start" icon={image} aria-hidden="true" />
                                        Download Graphic
                                    </IonButton>
                                </div>
                                {!emailSubmitted && <>
                                    <h3 className="signup">Did you find this meditation helpful? If so, sign up here to access additional resources from Journey.</h3>
                                    {error && (
                                        <IonText color="danger" className="error-text">
                                            {error}
                                        </IonText>
                                    )}
                                    <IonItem className={`email-input ${error ? 'error' : ''}`}>
                                        <IonInput placeholder="Enter your email address" type="email" autocomplete="email" onKeyPress={handleEmailInputKeypress} inputmode="email" value={email} onIonChange={e => setEmail(e.detail.value!)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" />
                                    </IonItem>
                                    <IonButton onClick={handleSubmitClick} className="button submit-button">
                                        {`Submit`}
                                    </IonButton>
                                </>}
                            </div>
                        }
                        {!isChallengeLive &&
                            <div className='text-content-container'>
                                <img src={WalgreensLogo} className="logo-image" alt="Walgreens Logo" />
                                <h1 className="title">Come back to this page on Monday, May 22 to attempt to break a <b>GUINNESS WORLD RECORDS®</b>  title!</h1>
                                <h3 className="subtitle">In recognition of Mental Health Awareness Month and World Meditation Day, Walgreens team members, friends and family are invited to view a 5-minute meditation in an attempt to break a record for most views for an online guided meditation video in 24 hours on a bespoke platform. See you on Monday!</h3>
                                <img src={GuinessWorldRecordsLogo} className="logo-image" alt="Guiness World Records Logo" />
                            </div>
                        }
                    </div>
                }
            </IonContent>
        </IonPage>
    )
};
