import './DailyJourneyPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useParams, useLocation} from "react-router-dom";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApiDailyJourney} from "journey-shared/journey/ApiTypes";
import {IonContent, IonPage} from "@ionic/react";
import {SideMenu} from "../../components/SideMenu/SideMenu";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {ContinuousDailyJourney} from "../../components/ContinuousDailyJourney/ContinuousDailyJourney";
import DesktopHeader from "../../assets/images/daily-journey-header.png";
import AnalyticsService from "../../misc/AnalyticsService";


type DailyJourneyRouteParams = {
    id: string;
    count: string;
    from: string;
};

export const DailyJourneyPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dailyJourneyInfo, setDailyJourneyInfo] = useState<ApiDailyJourney | null>(null);
    const {isMobileWidth, handleGeneralError, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const params = useParams<DailyJourneyRouteParams>();
    const location = useLocation();

    useEffect(() => {
        if(isComponentVisible){
            if(params.id) {
                //id is specified as query parameter, display daily journey with id specified
                JourneyApiClient.getDailyJourneyById(params.id)
                    .then(setDailyJourneyInfo)
                    .catch((error) => {
                        handleGeneralError("Unable to fetch Daily Journey", error);
                    })
                    .then(() => {
                        setIsLoading(false);
                    });
            }
            else {
                //id not specified, display current day daily journey
                JourneyApiClient.getDailyJourneyInfo()
                    .then(setDailyJourneyInfo)
                    .catch((error) => {
                        handleGeneralError("Unable to fetch Daily Journey Info", error);
                    })
                    .then(() => {
                        setIsLoading(false);
                    });
            }
            const queryParams = new URLSearchParams(window.location.search);
            let shared = queryParams.get("shared");
            if(shared) {
                AnalyticsService.trackUserAction("daily_journey_shared_link", location.pathname, { shared_by_user_id: shared});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return (
        <IonPage className={`daily-journey-page`}>
            {isMobileWidth ? <SideMenu /> && <SideMenuButton title={"Daily Journey"} /> : <Toolbar/>}
            <IonContent>
                <div className='dj-header'>
                    <div className={"daily-journey-text page-title"}>Daily Journey</div>

                    <img className={"image"} src={DesktopHeader}/>

                </div>
            {
                dailyJourneyInfo &&
                <div>
                    <ContinuousDailyJourney dailyJourney={dailyJourneyInfo} isComponentVisible currentUser={currentUser}/>
                </div>
            }
            </IonContent>
        </IonPage>
    );

};
