import {
    IonAccordion,
    IonAccordionGroup,
    IonContent,
    IonItemDivider, IonLabel, IonItem,
    IonModal,
    IonSegment,
    IonSegmentButton, IonCheckbox, IonTextarea, IonButton
} from "@ionic/react";
import "./ScheduleAppointmentModal.scss";
import {ExitButton} from "../ExitButton/ExitButton";
import React, {useState, useContext, useEffect, useRef} from "react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation} from "react-router-dom";
import {JourneyApiClient} from "journey-shared/journey/JourneyApiClient";
import {ApiClinician} from "journey-shared/journey/ApiTypes";
import {ClinicianAppointment} from "../ClinicianAppointment/ClinicianAppointment";
import {ClinicianCalendar} from "../ClinicianCalendar/ClinicianCalendar";
import {format} from "date-fns";
import {ScheduleAppointmentTerms} from "./ScheduleAppointmentTerms";


interface scheduleAppointmentModalProps {
    isModalOpen: boolean;
    handleModalDismiss: () => void;
}

export const ScheduleAppointmentModal: React.FC<scheduleAppointmentModalProps> = (props: scheduleAppointmentModalProps) => {

    const { currentUser, handleGeneralError, isMobileWidth } = useContext(ApplicationContext);
    const location = useLocation();
    const [segmentValue, setSegmentValue] = useState<string>('therapist');
    const [clinicians, setClinicians] = useState<ApiClinician[] | null>(null);
    const [clinicianSelected, setClinicianSelected] = useState<ApiClinician| null>(null);
    const [dateSelected, setDateSelected] = useState<Date | null>(null);
    const [timeSelected, setTimeSelected] = useState<string | null>(null);
    const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
    const accordionGroup = useRef<HTMLIonAccordionGroupElement | null>(null);

    useEffect(() => {
        if(props.isModalOpen){
            trackFormOpen();
            getClinicians();
            toggleAccordion("STEP1");
        }
    },[props.isModalOpen])

    async function toggleAccordion(step: string){
        //Hacky solution to ensure that in first render we have the reference for the accordion and avoid race condition
        do{
            await new Promise(r => setTimeout(r, 10));
        } while(!accordionGroup)
        if(accordionGroup){
            if(accordionGroup.current){
                accordionGroup.current.value = step;
            }
        }
    }

    async function getClinicians(){
        try{
            const cliniciansList = await JourneyApiClient.getClinicians();
            setClinicians(cliniciansList);
        } catch (e){
            handleGeneralError("Could not get clinicians", e);
        }
    }

    async function trackFormOpen () {
        await AnalyticsService.trackUserAction("schedule_appointment_open", location.pathname);
    }

    async function trackFormSubmission () {
        await AnalyticsService.trackUserAction("schedule_appointment_submission", location.pathname);
    }

    function handleExitButtonPressed () {
        props.handleModalDismiss();
    }

    function handleSegmentButtonPressed(segmentButtonValue: string){
        setSegmentValue(segmentButtonValue);
    }

    function handleAppointmentClick(date: Date, time: string, clinician: ApiClinician){
        setClinicianSelected(clinician);
        setDateSelected(date);
        setTimeSelected(time);
        toggleAccordion("STEP2");
    }

    async function handleAppointmentConfirmation(){
        if(dateSelected && timeSelected && clinicianSelected){
            let [hours, minutes] = changeTimeTo24HourClock(timeSelected).split(':');
            const dateWithTimezoneOffset = new Date(dateSelected.getUTCFullYear(), dateSelected.getUTCMonth(), dateSelected.getUTCDate(), parseInt(hours),  parseInt(minutes) - new Date().getTimezoneOffset()).toISOString()
            try{
                await JourneyApiClient.createClinicianAppointment(clinicianSelected.id, dateWithTimezoneOffset.toString());
                toggleAccordion("STEP3");
                trackFormSubmission();
            } catch (e) {
                handleGeneralError("Schedule Appointment failed", e);
            }
        }
    }

    function changeTimeTo24HourClock(timeString: string){
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = (parseInt(hours) + 12).toString();
        }
        return `${hours}:${minutes}`;
    }


    return(
        <IonModal
            className={`schedule-appointment-modal-component`}
            showBackdrop={true}
            isOpen={props.isModalOpen}
            initialBreakpoint={isMobileWidth ? .95 : 1}
            onWillDismiss={props.handleModalDismiss}>
            <IonContent className="schedule-appointment-modal-wrapper">
                <div className="schedule-appointment-container">
                    <div className="top-container">
                        <div className="exit-button-container"><ExitButton onClick={handleExitButtonPressed}/></div>
                        <div className="schedule-appointment-header header-4">
                            Schedule Appointment
                        </div>
                        <div className="schedule-appointment-body body-medium">
                            Find some time with with us. Availabilty shown in your time zone (EST).
                        </div>
                    </div>
                    <div className="bottom-container">
                        <IonAccordionGroup className="accordion-group" ref={accordionGroup}>
                            <IonAccordion className="accordion" value={"STEP1"}>
                                <div className={"accordion-title overline"} slot={"header"}>STEP 1. CHOOSE APPOINTMENT</div>
                                <div className="segment-container" slot={"content"}>
                                    <IonSegment className={"segment"} value={segmentValue}>
                                        <IonSegmentButton
                                            className={`segment-button button-small-variant ${segmentValue === "therapist" ? "selected" : ""}`}
                                            value={"therapist"}
                                            onClick={() => handleSegmentButtonPressed("therapist")}>
                                            By Therapist
                                        </IonSegmentButton>
                                        <IonSegmentButton
                                            className={`segment-button button-small-variant ${segmentValue === "availability" ? "selected" : ""}`}
                                            value={"availability"}
                                            onClick={() => handleSegmentButtonPressed("availability")}>
                                            By Availability
                                        </IonSegmentButton>
                                    </IonSegment>
                                </div>

                                {segmentValue === "therapist" && clinicians && clinicians.map((clinician: ApiClinician, index: number) => {
                                    return(
                                        <div key={index} slot={"content"}>
                                            {index > 0 &&
                                            <div  className="schedule-appointment-line-divider-container">
                                                <IonItemDivider className="schedule-appointment-line-divider"/>
                                            </div>}
                                            <div className="clinician-appointment-wrapper">
                                                <ClinicianAppointment isMobileWidth={isMobileWidth} clinician={clinician} onAppointmentClick={handleAppointmentClick}/>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className={"clinician-calendar-wrapper"} slot={"content"}>
                                    {segmentValue === "availability" && clinicians?.length &&
                                    <ClinicianCalendar clinicians={clinicians} onAppointmentClick={handleAppointmentClick}/>}
                                </div>
                            </IonAccordion>
                            <IonAccordion className="accordion" value={"STEP2"}>
                                <div className={"accordion-title overline"} slot={"header"}>STEP 2. CONFIRM APPOINTMENT</div>
                                <div slot={"content"}>
                                    <div className={"step-2-container"}>
                                        <div className={"clinician-biography-step-2"}>
                                            <div className={`clinician-image-container ${isMobileWidth ? "hide" : ""}`}>
                                                {clinicianSelected && clinicianSelected.thumbnailUrl && <img className={"clinician-image"} src={clinicianSelected.thumbnailUrl}/>}
                                            </div>
                                            <div className={"clinician-biography-step-2-text-container"}>
                                                <div className={`clinician-name ${isMobileWidth ? "header-6-variant" : "header-5"}`}>
                                                    {clinicianSelected && `${clinicianSelected.firstName} ${clinicianSelected.lastName} Virtual Appointment`}
                                                </div>
                                                <div className={`clinician-meeting-duration ${isMobileWidth ? "header-6-variant" : "header-5"}`}>50 minutes</div>
                                                <div className={`clinician-time ${isMobileWidth ? "header-6-variant" : "header-5"}`}>
                                                    {dateSelected && timeSelected && `${format(new Date(dateSelected), 'EE, MMMM d')} at ${timeSelected}`}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"terms-container"}>
                                            <div className={"title subtitle1"}>
                                                CCA, Inc. Notice of Privacy Practices
                                            </div>
                                            <IonItem className={"text-area-item"}>
                                                <IonTextarea className={"terms-text-area body-small"} rows={10} value={ScheduleAppointmentTerms} readonly={true}/>
                                            </IonItem>
                                            <IonItem className={"checkbox-item"}>
                                                <IonCheckbox className={"checkbox"} onIonChange={(e) => setIsTermsChecked(e.detail.checked)} slot={"start"}/>
                                                <IonLabel className={"label caption"}>I have read and agree to the terms above. *</IonLabel>
                                            </IonItem>
                                            <div className={"step-2-button-container"}>
                                                <IonButton
                                                    disabled={!dateSelected || !timeSelected || !isTermsChecked}
                                                    className={"step-2-button button-medium"}
                                                    onClick={handleAppointmentConfirmation}>
                                                    Confirm Appointment
                                                </IonButton>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </IonAccordion>
                            <IonAccordion className="accordion" value={"STEP3"}>
                                <div className={"accordion-title overline"} slot={"header"}>STEP 3. CONFIRMATION RECEIPT</div>
                                <div slot={"content"}>
                                    <div  className={"step-3-container"}>
                                        <div className={`appointment-confirmation-text ${isMobileWidth ? "subtitle1" : "header-5"}`}>
                                            Your appointment is confirmed:
                                        </div>
                                        <div className={"clinician-biography-step-3-text-container"}>
                                            <div className={`clinician-name ${isMobileWidth ? "header-6-variant" : "header-5-variant"}`}>
                                                {clinicianSelected && `${clinicianSelected.firstName} ${clinicianSelected.lastName} Virtual Appointment`}
                                            </div>
                                            <div className={`clinician-meeting-duration ${isMobileWidth ? "header-6-variant" : "header-5-variant"}`}>50 minutes</div>
                                            <div className={`clinician-time ${isMobileWidth ? "header-6-variant" : "header-5-variant"}`}>
                                                {dateSelected && timeSelected && `${format(new Date(dateSelected), 'EE, MMMM d')} at ${timeSelected}`}
                                            </div>
                                        </div>
                                        <div className={"add-it-to-calendar-container"}>
                                        </div>
                                    </div>
                                </div>
                            </IonAccordion>
                        </IonAccordionGroup>

                    </div>
                </div>
            </IonContent>
        </IonModal>
    )

}