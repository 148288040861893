import "./RegisterPage.scss";

import React from "react";
import {useEffect, useState} from "react";
import {IonPage, IonContent} from "@ionic/react";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {Severity} from "@sentry/react";
import {Company} from "journey-shared/journey/ApiTypes";
import {JourneyApiClient} from 'journey-shared/journey/JourneyApiClient';
import AnalyticsService from "../../misc/AnalyticsService";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import Logo from "../../assets/images/journey-logo-green.svg";
import { SignUpForm } from "../../components/SignUpForm/SignUpForm";
import { SignUpFormError } from "../../components/SignUpFormError/SignUpFormError";

export const RegisterPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<Company | null>(null);
    const {companyCode} = useParams<{companyCode: string}>();
    const isComponentVisible = useIsComponentVisible();

    const loadCompany = () => {
        setIsLoading(true);

        if (!companyCode) {
            Sentry.captureEvent({message: "Invalid Url", level: Severity.Info});
            setIsLoading(false);
            return;
        }
        JourneyApiClient.getCompany(companyCode)
            .then(company => {
                setCompany(company);
                setIsLoading(false);
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if(isComponentVisible){
            loadCompany();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return (
        <IonPage className="register-page-component">
            <IonContent className='register-page-content'>
                <div className='register-page'>
                    <div className='header'>
                        <img src={Logo} className="logo-image" alt="Journey LIVE Logo"/>
                    </div>
                    <div className='body'>
                        {
                            !isLoading && company ?
                                <SignUpForm setIsLoading={setIsLoading} company={company}/>
                            :
                            !isLoading && !company ?
                                <SignUpFormError/>
                            :
                                <PleaseWait/>
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}
