import { VideoResponse } from "journey-shared/journey/ApiTypes";
import { JourneyApiClient } from "journey-shared/journey/JourneyApiClient";
import { EventHandler, SyntheticEvent, useContext, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import AnalyticsService from "../../misc/AnalyticsService";
import { ApplicationContext } from "../../misc/ApplicationContext";
import "./Tile.scss";
import moment from "moment-timezone";
import {VideoStyle} from "./ContentStyles/Video/VideoStyle";
import {AudioStyle} from "./ContentStyles/Audio/AudioStyle";


export type VideoTileProps = {
    video: VideoResponse;
    index?: number;
    showDescription?: boolean;
    showTitle?: boolean;
    className?: string;
    onSaveChange?: (saved: boolean) => void;
    onClick?: EventHandler<any>;
    playlistId?: number;
};

function preventDefault(e: SyntheticEvent) {
    e.preventDefault();
}

function noop() {}

export function VideoTile(props: VideoTileProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(props.video.isSaved);
    const { currentUser } = useContext(ApplicationContext);
    const link = props.playlistId ? `/watch/${props.playlistId}/${props.video.id}` : props.video.path;
    const location = useLocation();

    let durationMinutes = '';
    let durationSeconds = '';
    if (props.video.duration) {
        durationMinutes = Math.floor(props.video.duration / 60).toString();
        durationSeconds = (props.video.duration % 60).toString().padStart(2, '0');
    }

    let progress = 0;
    if (props.video.position && props.video.duration) {
        progress = 100 / props.video.duration * props.video.position;
    }

    async function addToList(e: SyntheticEvent) {
        e.preventDefault()
        if (loading) return;
        try {
            setLoading(true);
            await JourneyApiClient.saveVideo(props.video.id);
            setIsSaved(true);
            if (props.onSaveChange) props.onSaveChange(true);
            AnalyticsService.trackUserAction("save_video", location.pathname, {
                video_id: props.video.id });
        } finally {
            setLoading(false);
        }
    }

    async function removeFromList(e: SyntheticEvent) {
        e.preventDefault();
        if (loading) return;
        try {
            setLoading(true);

            await JourneyApiClient.removeSavedVideo(props.video.id);
            setIsSaved(false);
            if (props.onSaveChange) props.onSaveChange(false);
            AnalyticsService.trackUserAction("remove_save_video", location.pathname, {
                video_id: props.video.id })
        } finally {
            setLoading(false);
        }
    }

    function formatDate(date: Date) {
        return moment(date).tz(moment.tz.guess()).format("dddd MMM Do - h:mm A");
    }

    function renderTile(theme: string | null) {
        switch (theme){
            case null:
                return
            case "video":
                return(
                    <VideoStyle durationMinutes={durationMinutes} durationSeconds={durationSeconds} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} progress={progress} video={props.video}/>
                )
            case "audio":
                return(
                    <AudioStyle durationMinutes={durationMinutes} durationSeconds={durationSeconds} handleAddToList={addToList} handleRemoveFromList={removeFromList} isSaved={isSaved} progress={progress} content={props.video} />
                )

        }
    }


    return(
        <div className={"tile-component"}>
            <Link
                onClick={currentUser ? props.onClick ?? noop : preventDefault}
                to={link}
                className={`tile-container ${props.className ?? ''}`}>
                {renderTile(props.video.thumbnailStyle)}
            </Link>
            {(props.video.liveRecordingDate || props.showTitle || props.showDescription) && <div className={"title-and-description-container"}>
                {props.video.liveRecordingDate && <div className={"caption"}>{formatDate(props.video.liveRecordingDate)}</div>}
                {(props.showTitle ?? true) &&<div className={"out-of-tile-title caption"}>{props.video.title}</div>}
                {(props.showDescription ?? false) && <div className={"caption"}>{props.video.description}</div>}
            </div>}
        </div>
    )
}
