import "./CategoryChip.scss";
import {ApiResourcesContentCategory} from "journey-shared/journey/ApiTypes";
import {IonChip, IonIcon, IonLabel} from "@ionic/react";
import closeIconWhite from "../../assets/images/close-icon-white.svg";
import * as React from "react";

export type CategoryChipProps = {
    category: ApiResourcesContentCategory;
    handleCategoryClick: (category: ApiResourcesContentCategory) => void;
    selectedCategories: string[];
};


export function CategoryChip(props: CategoryChipProps) {

    const isSelected: boolean = props.selectedCategories.includes(props.category.id);

    return(
        <IonChip
            onClick={() => props.handleCategoryClick(props.category)}
            className={`categories-chip-component ${isSelected && "selected"}`} >
            <IonLabel className="categories-chip-label">{props.category.nameNative}</IonLabel>
            {isSelected && <IonIcon className="categories-chip-icon" icon={closeIconWhite}/>}
        </IonChip>
    )
}