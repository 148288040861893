import {IonButton, IonIcon} from "@ionic/react";
import rightArrow from "../../assets/images/right-arrow-white.svg";
import * as React from "react";
import journeyLogoBlue from "../../assets/images/journey-logo-blue.svg";
import {ExitButton} from "../ExitButton/ExitButton";

interface assessmentWelcomeStepProps {
    handleNextStepNavigation: () => void;
    handleModalDismiss: () => void;
}

export const AssessmentWelcomeStep: React.FC<assessmentWelcomeStepProps> = (props: assessmentWelcomeStepProps) => {


    return(
        <div className="assessment-welcome-step">
            <div className="exit-button-container"><ExitButton onClick={props.handleModalDismiss}/></div>
            <div className="journey-logo-container"><img alt={"Journey Logo"} src={journeyLogoBlue}/></div>
            <div className="assessment-title header-4">Welcome to Journey!</div>
            <div className="assessment-text body-medium">We’re excited to help you prioritize your wellbeing. Can we start by asking you a few questions?</div>
            <div className="assessment-continue-button-container">
                <IonButton className="assessment-continue-button button-medium-variant" onClick={props.handleNextStepNavigation}>
                    Get Started<IonIcon src={rightArrow}/>
                </IonButton>
            </div>
        </div>
    )
}