import * as React from "react";
import {useContext} from "react";
import "./SideMenu.scss";
import {
    IonContent, IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {
    RESOURCES_LABEL,
    DAILY_JOURNEY_LABEL,
    RECOMMENDATIONS_LABEL,
    HOME_PATH,
    RESOURCES_PATH,
    DAILY_JOURNEY_PATH,
    RECOMMENDATIONS_PATH,
    BROWSE_LABEL,
    SEARCH_PATH,
    SETTINGS_LABEL,
    SETTINGS_PATH,
    BENEFITS_LABEL, BENEFITS_PATH,
    LANGUAGES_PATH, FOR_YOU_LABEL
} from "journey-shared/journey/utils";
import {settings, globeOutline} from 'ionicons/icons';
import {ApplicationContext} from "../../misc/ApplicationContext";
import HOME from "../../assets/images/home-icon.svg";
import BROWSE from "../../assets/images/search-sidemenu-icon.svg";
import DAILYJOURNEY from "../../assets/images/daily-journey-icon.svg";
import RECOMMENDATIONS from "../../assets/images/recommendations-icon.svg";
import RESOURCES from "../../assets/images/resources-icon.svg";
import LOGOUT from "../../assets/images/logout-icon.svg";
import ForwardArrow from "../../assets/images/forward-full-arrow.svg";
import ClientConfig from "journey-shared/journey/ClientConfig";
import { ProfileMenuList } from "../Profile/ProfileMenuList";
import { useTranslation } from 'react-i18next';

interface Props {

}

export const SideMenu: React.FC<Props> = (props: Props) => {

    const { t, i18n } = useTranslation();
    const menuRef = React.useRef<HTMLIonMenuElement>(null);
    const {handleLogout, isEnglishLanguage, currentUser} = useContext(ApplicationContext);
    const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");

    const onClick = () => {
        if(menuRef.current) {
            menuRef.current.close(true);
        }
    }

    return (
      <IonMenu side="start" className="side-menu-component" contentId="main-content" menuId="side-menu" ref={menuRef}>
          <IonHeader className="side-menu-header">
              <IonToolbar className="side-menu-toolbar">
                  <IonTitle className="side-menu-title"><h6><strong>{i18n.exists('Menu') ? t('Menu') : ''}</strong></h6></IonTitle>
              </IonToolbar>
          </IonHeader>
          <IonContent>
            {isEnglishLanguage &&
              <IonList>
                  <IonItem className="side-menu-item" routerLink={HOME_PATH} onClick={onClick} detail={false}>
                      <IonIcon className="side-menu-icon" slot="start" icon={HOME}  color="default" />
                      <IonLabel className="side-menu-text button-small">{FOR_YOU_LABEL}</IonLabel>
                      <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
                  </IonItem>
                  <IonItem className="side-menu-item" routerLink={SEARCH_PATH} onClick={onClick} detail={false}>
                      <IonIcon className="side-menu-icon" slot="start" icon={BROWSE}  color="default" />
                      <IonLabel className="side-menu-text button-small">{BROWSE_LABEL}</IonLabel>
                      <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
                  </IonItem>
                  <IonItem className="side-menu-item" routerLink={DAILY_JOURNEY_PATH} onClick={onClick} detail={false}>
                      <IonIcon className="side-menu-icon" slot="start" icon={DAILYJOURNEY} color="default" />
                      <IonLabel className="side-menu-text button-small">{DAILY_JOURNEY_LABEL}</IonLabel>
                      <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
                  </IonItem>
                    {!currentUser?.featureFlags?.includes('personalised-homepage-v1') && <>
                    <IonItem className="side-menu-item" routerLink={RECOMMENDATIONS_PATH} onClick={onClick} detail={false}>
                        <IonIcon className="side-menu-icon" slot="start" icon={RECOMMENDATIONS}  color="default" />
                        <IonLabel className="side-menu-text button-small">{RECOMMENDATIONS_LABEL}</IonLabel>
                        <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
                    </IonItem>
                    </>}
                  <IonItem className="side-menu-item" routerLink={BENEFITS_PATH} onClick={onClick} detail={false}>
                      <IonIcon className="side-menu-icon" slot="start" icon={RESOURCES} color="default" />
                      <IonLabel className="side-menu-text button-small">{BENEFITS_LABEL}</IonLabel>
                      <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
                  </IonItem>
                  <IonItem className="side-menu-item" routerLink={RESOURCES_PATH} onClick={onClick} detail={false}>
                      <IonIcon className="side-menu-icon" slot="start" icon={RESOURCES} color="default" />
                      <IonLabel className="side-menu-text button-small">{RESOURCES_LABEL}</IonLabel>
                      <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
                  </IonItem>
                  <ProfileMenuList handleMenuClose={onClick} hideProgressBar={true}/>
              </IonList>}
          </IonContent>
          <IonFooter>
            <IonItem className="side-menu-item top" routerLink={LANGUAGES_PATH} onClick={onClick} detail={false}>
                <IonIcon className="side-menu-icon" slot="start" icon={globeOutline}  color="default" />
                <IonLabel className="side-menu-text button-small">{t('Languages')}</IonLabel>
                <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
            </IonItem>
              {isMobileApp &&
              <IonItem className="side-menu-item top" routerLink={SETTINGS_PATH} onClick={onClick} detail={false}>
                  <IonIcon className="side-menu-icon" slot="start" icon={settings}  color="default" />
                  <IonLabel className="side-menu-text button-small">{SETTINGS_LABEL}</IonLabel>
                  <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
              </IonItem>
              }
              <IonItem className="side-menu-item" routerLink="/" onClick={handleLogout} detail={false}>
                  <IonIcon className="side-menu-icon" slot="start" icon={LOGOUT}  color="default" />
                  <IonLabel className="side-menu-text button-small">{t('Log out')}</IonLabel>
                  <IonIcon className="side-menu-forward-icon" slot="end" icon={ForwardArrow}/>
              </IonItem>
          </IonFooter>
      </IonMenu>
    )
}
