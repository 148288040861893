import React, {useEffect, useState} from 'react';
import {ApiClinician} from "journey-shared/journey/ApiTypes";
import "./ClinicianAppointment.scss";
import {ScheduleCarousel} from "../ScheduleCarousel/ScheduleCarousel";

export interface ClinicianAppointmentProps {
    isMobileWidth: boolean;
    clinician: ApiClinician;
    onAppointmentClick: (localeDayString: Date, time: string, clinician: ApiClinician) => void;
}


export function ClinicianAppointment(props: ClinicianAppointmentProps) {

    const [dayTimeMap, setDayTimeMap] = useState<Map<string, string[]> | null>(null);
    const [daysArray, setDaysArray] = useState<string[]>([]);

    useEffect(() => {
        createDayTimeMap(props.clinician);
    }, [props.clinician])

    function createDayTimeMap(clinician: ApiClinician){
        let daysArr: string[] = [];
        let map: Map<string, string[]> = new Map();
        clinician.slots.map((date: string) => {
            const day: string = new Date(date).toLocaleDateString();
            const time: string = new Date(date).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
            let timesArr: string[] = map.get(day) ?? [];
            timesArr.push(time);
            map.set(day,timesArr);

            if(!daysArr.includes(day)){
                daysArr.push(day);
            }
            setDayTimeMap(map);
            setDaysArray(daysArr);

        })
    }

    return(
        <div className={"clinician-appointment-component"}>
            <div className={"clinician-container"}>
                <div className={"clinician-image-container"}>
                    {props.clinician.thumbnailUrl && <img className={"clinician-image"} src={props.clinician.thumbnailUrl}/>}
                </div>
                <div className={"clinician-name-title-degree-container"}>
                    <div className={"clinician-name subtitle1-variant"}>
                        {`${props.clinician.firstName} ${props.clinician.lastName}`}
                        <div className={"clinician-pronouns subtitle1"}>{` (${props.clinician.pronouns})`}</div>
                    </div>
                    <div className={"clinician-title body-medium"}>
                        {`${props.clinician.title}`}
                    </div>
                    <div className={"clinician-degree body-medium"}>
                        {`${props.clinician.degree}`}
                    </div>
                </div>
            </div>

            <div className={"clinician-bio-container"}>
                <div className={"clinician-bio body-small"}>
                    {props.clinician.bio}
                </div>
            </div>
            <div className={"calendar-container"}>
                {dayTimeMap &&
                <ScheduleCarousel
                    isMobileWidth={props.isMobileWidth}
                    clinician={props.clinician}
                    daysTimeMap={dayTimeMap}
                    daysAvailableArray={daysArray}
                    onAppointmentClick={props.onAppointmentClick}
                />}
            </div>
        </div>
    )
}