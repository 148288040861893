import {
  HOME_PATH,
  RESOURCES_PATH,
  DAILY_JOURNEY_PATH,
  RECOMMENDATIONS_PATH,
  WATCH_PATH,
  PlAYLIST_PATH,
  CATEGORY_PATH,
  SEARCH_PATH,
  DAILY_VIDEO_PATH,
  SETTINGS_PATH,
  PRIVACY_POLICY_PATH,
  COMMUNICATIONS_OPT_IN_PATH,
  REGISTER_PATH,
  LIVE_VIDEO_PATH,
  BENEFITS_PATH,
  RESOURCES_PATH_MODULE,
  RESOURCES_PATH_DIVISION,
  RESOURCES_PATH_ARTICLE,
  RESOURCES_PATH_CATEGORY,
  LANGUAGES_PATH,
  PUBLIC_WATCH_PATH,
  SSO_LOGIN_PATH, SEARCH_PATH_QUERY,
  PARTIAL_WATCH_PATH,
  CHAMPION_OPT_IN_PATH,
  PARTICIPANT_OPT_IN_PATH,
  TEACHER_PATH,
  GUINESS_RECORD_CHALLENGE_PATH,
  PERSONAL_PLAN_PATH,
} from "journey-shared/journey/utils";
import { IonModal, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useContext } from "react";
import {Route, Redirect} from "react-router-dom";
import {VideoPage} from "../../pages/VideoPage/VideoPage";
import {VideoListingPage} from "../../pages/VideoListingPage/VideoListingPage";
import {DailyJourneyPage} from "../../pages/DailyJourneyPage/DailyJourneyPage";
import {BenefitsPage} from "../../pages/BenefitsPage/BenefitsPage";
import {PlaylistVideoPage} from "../../pages/PlaylistVideoPage/PlaylistVideoPage";
import {SideMenu} from "../SideMenu/SideMenu";
import {CategoryPage} from "../../pages/CategoryPage/CategoryPage";
import {RecommendationsPage} from "../../pages/RecommendationsPage/RecommendationsPage";
import {SearchPage} from "../../pages/SearchPage/SearchPage";
import AppUrlListener from './AppUrlListener';
import {DailyVideoPage} from "../../pages/DailyVideoPage/DailyVideoPage";
import {SettingsPage} from "../../pages/SettingsPage/SettingsPage";
import {PrivacyPolicyPage} from "../../pages/PrivacyPolicy/PrivacyPolicyPage";
import {RegisterPage} from "../../pages/RegisterPage/RegisterPage";
import {LiveVideoPage} from "../../pages/LiveVideoPage/LiveVideoPage";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { HomePage } from "../../pages/HomePage/HomePage";
import {CommunicationsOptInPage} from "../../pages/CommunicationsOptInPage/CommunicationsOptInPage";
import {ChampionRegistrationPage} from "../../pages/ChampionRegistrationPage/ChampionRegistrationPage";
import {ParticipantRegistrationPage} from "../../pages/ParticipantRegistrationPage/ParticipantRegistrationPage";
import ClientConfig from "journey-shared/journey/ClientConfig";
import {ContentDivisionPage} from "../../pages/ResourcesPages/ContentDivisionPage";
import {ContentModulePage} from "../../pages/ResourcesPages/ContentModulePage";
import {ContentArticlePage} from "../../pages/ResourcesPages/ContentArticlePage";
import {ContentCategoryPage} from "../../pages/ResourcesPages/ContentCategoryPage";
import {LanguagesPage} from "../../pages/LanguagesPage/LanguagesPage";
import { PublicVideoPage } from "../../pages/PublicVideoPage/PublicVideoPage";
import {SsoLoginPage} from "../../pages/SsoLoginPage/SsoLoginPage";
import { MindfulBreak } from "../MindfulBreak/MindfulBreak";
import {ScheduleAppointmentModal} from "../ScheduleAppointment/ScheduleAppointmentModal";
import {ResourcesAskAQuestionEmailForm} from "../ResourcesAskAQuestionEmailForm/ResourcesAskAQuestionEmailForm";
import {TeacherPage} from "../../pages/TeacherPage/TeacherPage";
import {EapSupportPhones} from "../EapSupportPhones/EapSupportPhones";
import {GuinessRecordChallenge} from "../../pages/GuinessRecordChallenge/GuinessRecordChallenge";
import { PersonalPlanPage } from '../../pages/PersonalPlanPage/PersonalPlanPage';

const isMobileApp = (ClientConfig.device === "ios") || (ClientConfig.device === "android");

export const LoggedInNavigation: React.FC = () => {

  const {
    isMindfulBreakModalOpen,
    isDownloadMobileAppModalOpen,
    currentUser, isMobileWidth,
    isEnglishLanguage,
    setIsMindfulBreakModalOpen,
    isScheduleAppointmentModalOpen,
    setIsScheduleAppointmentModalOpen,
    isResourcesEmailFormModalOpen,
    setIsResourcesEmailFormModalOpen,
    isEapSupportPhonesModalOpen,
    setIsEapSupportPhonesModalOpen
  } = useContext(ApplicationContext);
  const FeatureFlagHomePage = currentUser?.featureFlags?.includes('personalised-homepage-v1') ? HomePage : VideoListingPage;

  const handleMindfulBreakModalDismiss = async () => {
    //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
    await new Promise(r => setTimeout(r, 10));
    setIsMindfulBreakModalOpen(false);
  }

  const handleScheduleAppointmentModalDismiss = async () => {
    //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
    await new Promise(r => setTimeout(r, 10));
    setIsScheduleAppointmentModalOpen(false);
  }

  const handleResourcesEmailFormModalDismiss = async () => {
    //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
    await new Promise(r => setTimeout(r, 10));
    setIsResourcesEmailFormModalOpen(false);
  }

  const handleEapSupportPhonesModalDismiss = async () => {
    //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
    await new Promise(r => setTimeout(r, 10));
    setIsEapSupportPhonesModalOpen(false);
  }

    return(
        <IonReactRouter>
            {isMobileWidth &&  <SideMenu/>}
            <AppUrlListener />
            <IonRouterOutlet id="main-content" className={`daily-journey-page ${isMindfulBreakModalOpen || isDownloadMobileAppModalOpen || isEapSupportPhonesModalOpen ? "blur-content" : ""}`} animated={isMobileApp}>
              <Route path={WATCH_PATH} component={VideoPage} exact={true} />
              <Route path={PARTIAL_WATCH_PATH} component={VideoPage} exact={true} />
              <Route path={PlAYLIST_PATH} component={PlaylistVideoPage} exact={true} />
              <Route path={CATEGORY_PATH} component={CategoryPage} exact={true} />
              <Route path={`${DAILY_JOURNEY_PATH}/:id?`} component={DailyJourneyPage} exact={true} />
              <Route path={DAILY_VIDEO_PATH} component={DailyVideoPage} exact={true} />
              <Route path={BENEFITS_PATH} component={BenefitsPage} exact={true} />
              <Route path={RESOURCES_PATH} component={ContentDivisionPage} exact={true} />
              <Route path={RESOURCES_PATH_DIVISION} component={ContentDivisionPage} exact={true} />
              <Route path={RESOURCES_PATH_MODULE} component={ContentModulePage} exact={true} />
              <Route path={RESOURCES_PATH_CATEGORY} component={ContentCategoryPage} exact={true}/>
              <Route path={RESOURCES_PATH_ARTICLE} component={ContentArticlePage} exact={true}/>
              <Route path={LIVE_VIDEO_PATH} component={LiveVideoPage} exact={true} />
              <Route path={SEARCH_PATH} component={SearchPage} exact={true} />
              <Route path={SEARCH_PATH_QUERY} component={SearchPage} exact={true} />
              <Route path={HOME_PATH} component={isEnglishLanguage ? FeatureFlagHomePage : ContentDivisionPage} exact={true} />
              <Route path={SETTINGS_PATH} component={SettingsPage} exact={true} />
              <Route path={LANGUAGES_PATH} component={LanguagesPage} exact={true} />
              <Route path={PRIVACY_POLICY_PATH} component={PrivacyPolicyPage} exact={true}/>
              <Route path={REGISTER_PATH} component={RegisterPage} exact={true}/>
              <Route path={COMMUNICATIONS_OPT_IN_PATH} component={CommunicationsOptInPage} exact={true}/>
              <Route path={CHAMPION_OPT_IN_PATH} component={ChampionRegistrationPage} exact={true}/>
              <Route path={PARTICIPANT_OPT_IN_PATH} component={ParticipantRegistrationPage} exact={true}/>
              <Route path={PUBLIC_WATCH_PATH} component={PublicVideoPage} exact={true}/>
              <Route path={SSO_LOGIN_PATH} component={SsoLoginPage} exact={true}/>
              <Route path={TEACHER_PATH} component={TeacherPage} exact={true} />
              <Route path={GUINESS_RECORD_CHALLENGE_PATH} component={GuinessRecordChallenge} exact={true}/>
              <Route path={PERSONAL_PLAN_PATH} component={PersonalPlanPage} exact={true} />
              <Route>
                <Redirect to={HOME_PATH} exact/>
              </Route>
            </IonRouterOutlet>
            <IonModal
                    className={"mindful-break-modal"}
                    showBackdrop={false}
                    isOpen={isMindfulBreakModalOpen}
                    onWillDismiss={handleMindfulBreakModalDismiss}>
                    <MindfulBreak isComponentVisible={isMindfulBreakModalOpen}/>
            </IonModal>
            <ScheduleAppointmentModal
                isModalOpen={isScheduleAppointmentModalOpen}
                handleModalDismiss={handleScheduleAppointmentModalDismiss}
            />
            <ResourcesAskAQuestionEmailForm
                isModalOpen={isResourcesEmailFormModalOpen}
                handleModalDismiss={handleResourcesEmailFormModalDismiss}
            />
            <IonModal
                className={"eap-support-phones-modal"}
                showBackdrop={false}
                isOpen={isEapSupportPhonesModalOpen}
                onWillDismiss={handleEapSupportPhonesModalDismiss}>
                <EapSupportPhones isComponentVisible={isEapSupportPhonesModalOpen}/>
            </IonModal>
        </IonReactRouter>
    );
};
