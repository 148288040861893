import React from "react";
import {useContext} from "react";
import {IonPage, IonContent} from "@ionic/react";
import "./PrivacyPolicyPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";

export const PrivacyPolicyPage: React.FC = () => {

    const {isMobileWidth} = useContext(ApplicationContext);

    return (
        <IonPage className="privacy-policy-page">
            {isMobileWidth ? <SideMenuButton title={"Privacy Policy"}/> : <Toolbar/>}
            <IonContent className="privacy-policy-content">
                <div className={"privacy-policy-text"}>
                    <h2>Privacy Policy and Terms &amp; Conditions</h2>
                    <p><strong>In General&nbsp;</strong></p>
                    <p>Protecting user privacy is important to Journey LIVE (“we”, “us” or “our”). This Privacy Policy
                        explains how we collect, use and disclose information about you. This Privacy Policy applies to
                        information we collect when you use our website (including all domains and sub-domains of
                        journey.live), web applications, mobile applications, application programming interfaces
                        (APIs), and other products and services (collectively, the “Products”) or when you otherwise
                        interact with us whether in electronic, paper, or verbal format.</p>
                    <p>The information we collect is governed by United States (“US”) law. By accessing or using our
                        Products or otherwise providing information to us, you consent to the processing and transfer of
                        information in and to the US and other countries.</p>
                    <p>For purposes of this Privacy Policy, “Personal Information” is defined as information that (1) is
                        recorded in any form; (2) is about, or pertains to a specific individual; and (3) can be linked to
                        that individual.</p>
                    <p><strong>Information We Collect</strong></p>
                    <p>When you use our Products, we collect the following types of information:</p>
                    <p><strong>Personal information:</strong>&nbsp;Your name, e-mail address, telephone number and
                        information collected by tracking technologies as described below that may identify you as an
                        individual or allow online or offline contact with you.</p>
                    <p><strong>Account information:</strong>&nbsp;When you use our Products you may need to create an
                        account with us. &nbsp;In order to create an account, you will need to provide us with login
                        credentials (which include your username and password), your name, email address, postal address,
                        and phone number.</p>
                    <p><strong>General usage data:</strong>&nbsp;When you use our Products we may collect information about
                        the technical properties of hardware and software you use in conjunction with our Products. For
                        example, we may collect the type and model of hardware and/or web browser you are using, referring
                        or exit web pages, and the operating system version you are using.</p>
                    <p>When you use our Products we may also collect information about how long you use our Product in each
                        session, features you use in our Products, how long you use our Products overall, the time and day
                        you accessed our Products, etc.</p>
                    <p>We may record mouse clicks, movements, and other interactions with our pages or features to help us
                        improve usability.</p>
                    <p><strong>Location information:</strong>&nbsp;&nbsp;When you use our Products, enable data services,
                        use the browser or location-based functionality on your device, location information associated to
                        your device (e.g. Global Positioning System (GPS) or similar satellite triangulation information,
                        Internet Protocol address (IP address) information, carrier or tower ID, the BSSID (Broadcast
                        Service Set Identifier) and MAC address (Media Access Control address) of Wi-Fi access points, and
                        signal strength of visible Wi-Fi hotspots or wireless towers) may be communicated to the Company or
                        our service providers. If we collect and subsequently store such information, it will be in a form
                        that does not personally identify you (e.g. we do not maintain a system that would link specific
                        individuals to a BSSID). We may process such information to provide you with or facilitate the
                        provision of information and location-based services (e.g. mapping services, measuring traffic
                        congestion, location-sensitive promotions or coupons). If you choose to use location-based services,
                        you agree that such geographic location information may be processed to provide you with such
                        services. If you choose to use location-based services or other applications to disclose your
                        location to other persons or entities, you assume responsibility for the risks involved in doing so,
                        and it is your responsibility to use them appropriately and according to applicable law. If you use
                        third party products which provide location-based services, please review the third party’s terms
                        and conditions and privacy policy regarding how your location and personal information will be
                        processed, and how to opt-out of any location-based services and advertising provided by the third
                        party.</p>
                    <p><strong>Third party information:</strong>&nbsp;If necessary to fulfill our identified purposes, we
                        may combine the information you submit to us with information obtained from other sources or third
                        party offerings. For example, we may receive personal information about you from third parties
                        involved in providing you with the products, services, hardware, software, and websites you are
                        utilizing, such as from your email, social media, and online file storage provider to facilitate the
                        integration of those products, services, software, and websites with our Products. &nbsp;We treat
                        all such personal information in accordance with this Policy and the terms and conditions between us
                        and such third parties, if any, may also apply. However, the third party’s own use of your personal
                        information in such cases will be determined by your agreement(s) with the third party, unless that
                        third party is one of our service providers.</p>
                    <p><strong>Quality assurance and customer service:</strong>&nbsp;Your customer service telephone calls
                        and emails with us and our service providers, if any, may be recorded, logged, and/or monitored for
                        quality assurance and customer service purposes such as to assist in addressing your inquiries,
                        troubleshooting, training and analytics to identify trends and make improvements to our Products and
                        customer service. &nbsp;As part of the recording and logging of the calls and emails, we will
                        collect information like the identity of the caller or sender of email, the date and time of the
                        call or email, and the subject and resolution of the issue.</p>
                    <p><strong>Cookies:&nbsp;</strong>We collect certain information about your use of our Products as
                        described below under the heading “Our Use of Cookies, Log Files, Web Beacons, and Embedded Scripts.
                    </p>
                    <p>Some of this information would not by itself identify you to us or be personally identifiable and is
                        therefore considered non-personal information. If we combine any such non-personal information with
                        other personal information available to us, the combined information will be treated as personal
                        information in accordance with this Policy.</p>
                    <p><strong>Purchase and Payment Information:</strong>&nbsp;We do not collect payment information via our
                        website. All payment arrangements are done off-line. While payments may be processed through a third
                        party, we may collect information to administer, manage, and fulfill the purchases you make. For
                        example, we may collect your name, email address, account information, what is ordered, the date and
                        time of the order, whether payment was made, method of payment, and purchase history.</p>
                    <p><strong>Our Use of Information We Collect</strong></p>
                    <p>We use the personal information we collect about you:</p>
                    <ul dir="ltr">
                        <li>To understand and meet your needs and preferences, and/or to provide you with our Products, for
                            example:
                            <ul>
                                <li>for purposes related to the billing, activation, provision, maintenance, support,
                                    troubleshooting, resolving of disputes, deactivation, upgrade, or update of Products;
                                </li>
                                <li>to process and fulfill your order, send you emails and newsletters to communicate with
                                    you about our services, and allow you to pay for services you order;
                                </li>
                                <li>to ensure our web site and other Products are technically functioning as intended and to
                                    help us identify and troubleshoot issues;
                                </li>
                                <li>to fulfill or enforce any agreements you enter into with us; and</li>
                                <li>to manage or respond to your inquiries;</li>
                            </ul>
                        </li>
                        <li>To enhance and upgrade our Products, and to communicate with you about our services and any
                            enhancements and upgrades to our Products;
                        </li>
                        <li>To manage and develop our business and operations, for example;
                            <ul>
                                <li>to detect, monitor, investigate, mitigate or attempt to prevent fraud and technical or
                                    security issues or to protect our property;
                                </li>
                                <li>to allow for business continuity and disaster recovery operations;</li>
                                <li>to enforce our legal rights;</li>
                                <li>for statistical purposes;</li>
                                <li>if we believe your actions are inconsistent with the spirit or language of our Terms and
                                    Conditions of Service, User Agreements or policies; and
                                </li>
                                <li>in connection with, or during negotiations of, any merger, sale of our assets,
                                    financing, or acquisition of all or a portion of our business to another entity;
                                </li>
                            </ul>
                        </li>
                        <li>To fulfill our legal obligations and regulatory requirements and to respond to emergency
                            situations, for example:
                            <ul>
                                <li>to respond to court orders, warrants or other lawful requests or legal processes;</li>
                                <li>to provide emergency assistance in situations that may threaten the life or physical
                                    safety of you or others; or
                                </li>
                                <li>for any other purposes for which you have consented, such as those that may be set out
                                    in Notices, and other purposes as permitted or required by any applicable law.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>Notwithstanding anything to the contrary contained in this Policy, we may remove personal identifiers
                        from your information and maintain and use it in a de-identified form that may be combined with
                        other information to generate aggregated information. Such de-identified and/or aggregated
                        information is owned by us and we shall have unrestricted rights, title, and interest in and to the
                        de-identified and/or aggregated information. This may include, without limitation, the right to use,
                        distribute, transmit, transfer, license, trade, rent, share, assign, and sell the de-identified
                        and/or aggregated information.</p>
                    <p>We will retain your personal information as long as necessary for the fulfillment of the foregoing
                        purposes or as otherwise necessary to comply with applicable laws.</p>
                    <p><strong>To whom we Disclose your Personal Information</strong></p>
                    <p>In accordance with this Policy, we may share your personal information:</p>
                    <ul>
                        <li>To our personnel who need access to that information for any of the purposes described
                            above &nbsp;and/or with our service providers such as a cloud service provider or data storage
                            provider;
                        </li>
                        <li>With our financial, insurance, legal, accounting or other advisors that provide professional
                            services to us;
                        </li>
                        <li>If we have your consent;</li>
                        <li>With third parties that help us provide our services to you, including but not limited to
                            payment processors;
                        </li>
                        <li>To legal or governmental authorities in order to &nbsp;fulfill our legal obligations, including
                            for example to respond to a subpoena, court order or government request;
                        </li>
                        <li>To protect, establish or exercise our legal rights or defend legal claims brought against us;
                        </li>
                        <li>To investigate, detect, suppress, prevent or take action regarding illegal or prohibited
                            activities, suspected fraud, situations involving potential threats to the reputation or
                            physical safety of any person; and
                        </li>
                        <li>With any acquirer or potential acquirer of our business if we are in talks to be sold, merged,
                            or amalgamated or substantially all of our assets are to be sold or disposed of. We will use
                            reasonable efforts to direct any potential or actual acquirer of our business to use personal
                            information you have provided to us in a manner that is consistent with this Policy. Following
                            any such a sale or transfer, you may contact the entity to which we transferred your personal
                            information with any inquiries concerning the processing of that information.
                        </li>
                    </ul>
                    <p>In addition, we may share information about your use of the Products and our services with your
                        employer or its agents if your employer has provided you with access to the Products.</p>
                    <p><strong>Your Decisions About Your Personal Information</strong></p>
                    <p>You can contact us at any time to: stop receiving emails from us; review the personal information
                        held by us in connection with your account; withdraw your consent for our use and disclosure of your
                        information; request a list of third parties to which we may have provided your personal
                        information; close your account; and amend your personal information, by contacting us by e-mail
                        at&nbsp;<a href="mailto:info@journey.live">info@journey.live</a>. &nbsp;You can
                        always unsubscribe from receiving promotional emails from us by simply clicking the “unsubscribe”
                        link provided at the bottom of every promotional email from us.</p>
                    <p>If you contact us to do any of the things listed above, we may require you to provide sufficient
                        personal information to allow us to identify you and tell you about the existence, use and
                        disclosure of your personal information and this personal information will only be used for this
                        purpose. If you contact us about your personal information, we will respond to your request within a
                        reasonable time and at minimal cost or no cost to you in accordance with applicable laws.</p>
                    <p>Generally, you may withdraw at any time your consent for us to collect, use, and disclose your
                        personal information in accordance with this Policy, subject to legal or contractual restrictions
                        and reasonable notice. As such, we may continue to collect, use, and disclose your personal
                        information as may be required to provide you with our Products, and to the extent that we are
                        contractually obligated to do so or as necessary to enforce any contractual obligations you may have
                        with us. If you refuse to provide us with the information we require or later contact us to withdraw
                        your consent for us to use and disclose your personal information, we may no longer be able to
                        provide you with our Products.</p>
                    <p><strong>Our Use of Cookies, Log Files, Web Beacons, and Embedded Scripts</strong></p>
                    <p>When you visit our website, we collect certain information by automated means, such as through server
                        log files, cookies (text files sent to and stored on your device when you access the website), web
                        beacons (also known as clear GIFs and pixel tags, which may be used to transmit information back to
                        us), and embedded scripts (programming code that is designed to collect information about your
                        interactions with our Products, such as the links you click on, and which is active only when you
                        are accessing the Products).</p>
                    <p>The information we collect in this manner may include details about the computer, mobile phone, or
                        other device you use to access our Products (such as browser type, operating system, and IP
                        address), referring URLs and information on actions taken or interaction with our digital
                        assets.</p>
                    <p>We may use third-party web analytics services to help us analyze how visitors use our Products. We
                        may permit these third parties to operate directly on our website, use their own technology (such as
                        cookies or web beacons), and collect information about you on our behalf.</p>
                    <p>We use the information we collect in this manner is collected to enhance the user experience for
                        persons that use our Products and to improve and add to the services we offer.</p>
                    <p>When you visit our website, we may allow some third parties (such as advertising networks and data
                        analytics companies) to collect information about your online activities over time and across
                        different websites. Your browser settings may allow you to automatically transmit a “Do Not Track”
                        signal to websites and online services you visit. However, there is no consensus among industry
                        participants as to what “Do Not Track” means in this context. Like many websites and online
                        services, we currently do not alter our practices when we receive a “Do Not Track” signal from a
                        visitor’s browser.</p>
                    <p>In some instances, we may also combine information we receive about you from third parties with
                        information we collect through our Products.</p>
                    <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set
                        your browser to remove or reject browser cookies. Please note that if you choose to remove or reject
                        cookies, our web applications, mobile applications, or certain features thereof, may no longer
                        function.</p>
                    <p><strong>Security</strong></p>
                    <p>Our goal is to provide you with a safe user experience. We have in place certain physical, technical,
                        and administrative safeguards designed to appropriately protect the security and privacy of your
                        personal information against loss, theft, and unauthorized access, disclosure, copying, use, or
                        modification. Please note, however, that we cannot guarantee that the measures we maintain will
                        guarantee the security of the information.</p>
                    <p>We limit access to your personal information within our company to individuals with a need to
                        know.</p>
                    <p><strong>Links to Other Sites</strong></p>
                    <p>We may provide links on our website to third party sites we think you will enjoy. These sites operate
                        independently of us and may have established their own privacy and security policies. Any personal
                        information you provide on linked pages or other sites is subject to that third party’s privacy
                        policy. We strongly encourage you to review these policies at any site you visit. This Policy does
                        not apply to such linked pages or other sites, and we are not responsible for the content or
                        practices of any linked websites which are provided solely for your convenience.</p>
                    <p><strong>Children’s Online Privacy Protection</strong></p>
                    <p id="yui_3_17_2_1_1556639608976_100">Our Products and services are not intended for use by children
                        under 18 years of age. &nbsp;We do not knowingly collect or use any personal information from any
                        children under 13 years of age. If we become aware that we have unknowingly collected personal
                        information from a child under the age of 13, we will make commercially reasonable efforts to delete
                        such personal information from our database.</p>
                    <p><strong>Questions or Comments</strong></p>
                    <p>If you have any questions or comments or wish to make a complaint about this Policy, please feel free
                        to contact us by email at info@journey.live. If you contact us about your personal
                        information, we will respond to your request within a reasonable time and at minimal cost or no cost
                        to you in accordance with applicable laws. Depending on the circumstances and applicable laws, we
                        may refuse to process certain access requests (for example, access requests that are unreasonably
                        repetitive or systematic, would be extremely impractical or require disproportionate technical
                        effort).</p>
                    <p><strong>(For California Residents) Your California Privacy Rights</strong></p>
                    <p>California law permits residents of California to request certain details about how their information
                        is shared with third parties for direct marketing purposes. If you are a California resident and
                        would like to make such a request, please email us at info@journey.live. However, please
                        note that under the law, services such as ours that permit California residents to opt in to, or opt
                        out of, this type of sharing are not required to provide such information upon receiving a request,
                        but rather may respond by notifying the user of his or her right to prevent the disclosure. To opt
                        out of having information about you shared with third parties for direct marketing purposes, please
                        email us at info@journey.live.</p>
                    <p><strong>(For Nevada Residents) Your Nevada Privacy Rights</strong></p>
                    <p>If you are a Nevada resident who visits our website you may review and request changes to any of your
                        personal information that is collected on our website. Please refer to this Policy for information
                        regarding information available to third parties from your activities on our website. To exercise
                        this right, please e-mail us at info@journey.live. Any revision to this Notice required by
                        changes to Nevada law will be posted on this website, so you are advised to review each time there
                        is an update posted.</p>

                    <p><strong>GDPR</strong></p>
                    <p>
                        If you are a resident of the European Economic Area and subject to the General Data Protection Regulation (GDPR), 
                        you have the following rights with respect to the Personal Information we hold about you, (i) Right of access; 
                        (ii) Right of portability; (iii) Right to rectification; (iv) Right to erasure; (v) Right to restriction; (vi) 
                        Right to withdraw consent; and (vii) Right to object.. If you would like to exercise these rights, please contact 
                        us via email at <a href="mailto:info@journey.live">info@journey.live</a>. You also have the right to lodge a complaint 
                        to your local data protection authority. Information about how to contact your local data protection authority is 
                        available here 
                        (<a href="https://ec.europa.eu/newsroom/article29/items/612080" target="_blank">
                            https://ec.europa.eu/newsroom/article29/items/612080</a>). 
                        Due to the confidential nature of data processing we may ask you to provide proof of identity when exercising 
                        the above rights.
                    </p>

                    <p><strong>Modifications to this Privacy Policy</strong></p>
                    <p>We may make modifications, deletions, and/or additions to this Privacy Policy at any time. Users are
                        advised to check the date listed at the top of this Privacy Policy for any new updates. By using our
                        Products you will be deemed to have agreed to the terms of this Privacy Policy as modified, so you
                        are advised to review each time there is an update posted.</p>
                    <p><strong>Contact Us</strong></p>
                    <p>Questions, comments, or complaints regarding Journey LIVE’s Privacy Policy or data collection
                        and processing practices can be emailed to info@journey.live.</p>
                    <p><strong>Effective Date: March 2, 2022</strong></p>
                </div>
            </IonContent>
        </IonPage>
    );

}

