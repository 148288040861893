import './LiveVideoPage.scss';

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import {SideMenuButton} from "../../components/SideMenuButton/SideMenuButton";
import {Toolbar} from "../../components/Toolbar/Toolbar";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import AnalyticsService from "../../misc/AnalyticsService";
import {MuxLiveVideoPlayer} from "../../components/MuxLiveVideoPlayer/MuxLiveVideoPlayer";
import {ApiLiveStream} from "journey-shared/journey/ApiTypes";
import {useLocation} from "react-router-dom";

export const LiveVideoPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {isMobileWidth } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();

    useEffect(() => {
        setIsLoading(false)
    }, [isComponentVisible]);

    const trackPlayEvent = async (liveStream: ApiLiveStream) => {
        let eventProperties = {
            live_video_title: liveStream.title,
            live_video_id: liveStream.id,
            utc_one_time_schedule_date: liveStream.oneTimeScheduleDate,
        }
        await AnalyticsService.trackUserAction("live_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async (liveStream: ApiLiveStream) => {
        let eventProperties = {
            live_video_title: liveStream.title,
            live_video_id: liveStream.id,
            utc_one_time_schedule_date: liveStream.oneTimeScheduleDate,
        }
        await AnalyticsService.trackUserAction("live_video_pause", location.pathname, eventProperties);
    }

    return (
        <IonPage className={`live-video-page`} id="main-content">
            {isMobileWidth ?  <SideMenuButton title={"Live"}/> : <Toolbar/>}
            <IonContent>
                {
                    (function() {
                        if (isLoading) {
                            return <PleaseWait/>;
                        } else if (!isLoading) {
                            return <MuxLiveVideoPlayer
                                trackPauseEvent={trackPauseEvent}
                                trackPlayEvent={trackPlayEvent}/>;
                        }
                    })()
                }
            </IonContent>
        </IonPage>
    );

}
