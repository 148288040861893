import React from 'react';
import "./LiveStreamStyle.scss";
import {IonIcon} from "@ionic/react";
import Play from "../../../../assets/images/play.svg";
import {ApiLiveStream, VideoResponse} from "journey-shared/journey/ApiTypes";
import {BACKGROUND_TILE_COLOR} from "journey-shared/journey/utils";

export type VideoStyleProps = {
    liveStream: ApiLiveStream;
};

export function LiveStreamStyle(props: VideoStyleProps)  {

    return(
        <div className={`video-style-component ${BACKGROUND_TILE_COLOR[props.liveStream.id % BACKGROUND_TILE_COLOR.length]}`}>
            <div className={`image-container`}>
                <div className={"title-container"}>
                    <div className={"title subtitle2-variant"}>{props.liveStream.title}</div>
                </div>
                <IonIcon className="play-icon" src={Play} />
            </div>
        </div>
    )
}