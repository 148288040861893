import "./ResourcesHelpTile.scss";
import * as React from 'react';


interface ResourcesAvailableHomePageTileProps {
    onClick: () => void;
    image: string;
    topText: string;
    bottomText: string | null;
    bottomTextClassName?: string;
    addRightDivider?: boolean;
    href?: string;
}

export const ResourcesHelpTile: React.FC<ResourcesAvailableHomePageTileProps> = (props: ResourcesAvailableHomePageTileProps) => {


    return(
        <div className={`resources-home-tile-component ${props.addRightDivider && "divider"}`} onClick={props.onClick}>
            <div className="resources-home-tile-image-container"><img className="resources-home-tile-image" src={props.image}/></div>
            <div className="resources-home-tile-text-container" style={{flexDirection: props.bottomText ? "column" : "row"}}>
                <div
                    style={{alignSelf: props.bottomText ? "flex-start" : "center"}}
                    className="resources-home-tile-top-text button-medium-variant"
                >{props.topText}</div>
                <div
                    style={{display: props.bottomText ? "inherit" : "none"}}
                    className={`resources-home-tile-bottom-text ${props.bottomTextClassName ? props.bottomTextClassName : "button-medium-variant"}`}
                >{props.bottomText}</div>
            </div>
        </div>
    )
};
