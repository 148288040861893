import * as React from "react";
import {useContext} from "react";
import "./ProfileMenu.scss";
import {
    IonContent,
    IonFooter,
    IonItem,
    IonLabel,
    IonToolbar
} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import { ProfileMenuList } from "./ProfileMenuList";
import { useTranslation } from 'react-i18next';

export const ProfileMenu: React.FC = () => {

    const { t } = useTranslation();
    const {handleLogout, isMobileWidth, isEnglishLanguage } = useContext(ApplicationContext);

    return (
    <div className={`profile-menu-component`}>
            {isEnglishLanguage && <ProfileMenuList hideProgressBar={false}/>}
            {!isMobileWidth &&
                <IonFooter>
                   <IonToolbar className="profile-menu-toolbar" >
                        <IonItem detail={false} routerLink="/" onClick={handleLogout}>
                            <IonLabel className="caption">{t('Log out')}</IonLabel>
                        </IonItem>
                    </IonToolbar>
                </IonFooter>
            }
        </div>
    )
}
