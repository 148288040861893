import React, {useContext, useState} from 'react';
import './ScheduleCarousel.scss';
import {IonButton, IonIcon} from "@ionic/react";
import ForwardArrow from "../../assets/images/forward-arrow-blue.svg";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import {FreeMode, Navigation} from "swiper";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {v4 as uuidv4} from "uuid";
import {AcuityClinicianAvailableSlots, ApiClinician} from "journey-shared/journey/ApiTypes";
import {format, getDate, getDay} from "date-fns";
import {RadioButtonAssessmentQuestion} from "../Assessment/RadioButtonAssessmentQuestion";


export interface ScheduleCarouselProps{
    isMobileWidth: boolean;
    clinician: ApiClinician;
    daysAvailableArray: string[];
    daysTimeMap: Map<string, string[]>;
    onAppointmentClick: (localeDayString: Date, time: string, clinician: ApiClinician) => void;
}

export function ScheduleCarousel(props: ScheduleCarouselProps) {
    const { isMobileWidth } = useContext(ApplicationContext);
    const [uniqueId, setUniqueId] = useState<string>(uuidv4());


    return(
        <div className={`schedule-carousel`}>
            <IonButton fill="clear" className={`schedule_prev_${uniqueId} arrow-button left`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <IonButton fill="clear" className={`schedule_next_${uniqueId} arrow-button right`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <Swiper
                className="schedule-swiper"
                modules={[Navigation, FreeMode]}
                scrollbar={false}
                freeMode={true}
                navigation={{
                    nextEl: `.schedule_next_${uniqueId}`,
                    prevEl: `.schedule_prev_${uniqueId}`,
                    disabledClass: 'hide-arrow'
                }}
                spaceBetween={15}
                slidesPerView={props.isMobileWidth ? 2 : 5} >
                {
                    props.daysAvailableArray.map((day, i) => {
                        let timesArray: string[] = props.daysTimeMap.get(day) ?? [];
                        return(
                            <SwiperSlide key={i}>
                                <div className={"date-tile"}>
                                    <div className="date-container overline">
                                        {format(new Date(day), 'EE, MMMM d')}
                                    </div>
                                    <RadioButtonAssessmentQuestion
                                        clearAnswerOnSelection={true}
                                        assessmentQuestion={""}
                                        assessmentValueOptions={timesArray}
                                        onValueChange={(time) => props.onAppointmentClick(new Date(day), time, props.clinician)}
                                    />
                                </div>
                            </SwiperSlide>

                        )
                    })
                }
            </Swiper>

        </div>
    )
}