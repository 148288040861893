import * as React from "react";
import "./CopyToClipboardModal.scss";
import {IonButton, IonItem, IonIcon, IonModal, IonText} from "@ionic/react";
import GlobalMessage from "../GlobalMessage/GlobalMessage";
import {useContext, useState} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";


interface Props {
    link: string;
    isModalOpen: boolean;
    dismissModal: () => void;
}

export const CopyToClipboardModal: React.FC<Props> = ({link, isModalOpen, dismissModal}) => {

    const [globalHeader, setGlobalHeader] = useState<string | null>(null);
    const {isMobileWidth} = useContext(ApplicationContext);

    async function handleCopyButtonClick () {
        await navigator.clipboard.writeText(link);
        setGlobalHeader("Link copied");
        setTimeout( () => {
            setGlobalHeader(null);
            dismissModal();
        }, 2500);
    }

    return(
        <IonModal
            className={"copy-to-clipboard-modal"}
            showBackdrop={true}
            isOpen={isModalOpen}
            initialBreakpoint={isMobileWidth ? .8 : undefined}
            onWillDismiss={dismissModal}>
            <div className="body-container header-6">
                Share Daily Journey
                <div className="item-container">
                    <IonItem className="clipboard-item">
                        <div className="clipboard-item-container">
                            <IonText id={"text"} className="clipboard-text body-small">{link}</IonText>
                            {!isMobileWidth && <IonButton className="clipboard-button" onClick={handleCopyButtonClick}>Copy</IonButton>}
                        </div>
                    </IonItem>
                </div>
                {isMobileWidth && <IonButton className="clipboard-button button-small-variant" onClick={handleCopyButtonClick}>Copy</IonButton>}
            </div>
            <GlobalMessage message={link} header={globalHeader} onClose={() => null} isSuccessMessage={true} />
        </IonModal>
    )
}
