import React, {SyntheticEvent} from 'react';
import "./TeacherStyle.scss";
import {VideoPlaylistResponse} from "journey-shared/journey/ApiTypes";
import {AddToPlaylist} from "../../../AddToPlaylist/AddToPlaylist";
import {BACKGROUND_TILE_COLOR} from "journey-shared/journey/utils";

export type VideoStyleProps = {
    playlist: VideoPlaylistResponse;
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
};

export function TeacherStyle(props: VideoStyleProps)  {

    return(
        <div className={`teacher-style-component `}>
            <div className={`image-container  ${BACKGROUND_TILE_COLOR[props.playlist.id % 4]}`}>
                <img className={"image"} src={props.playlist.teacher?.videoThumbnailImageS3Path ?? ""}/>
                <div className={"tile-name-container"}>
                    <div className={"tile-name overline"}>TEACHERS</div>
                </div>
                <div className={"author-container"}>
                    <div className={"author-name video-feature-tile"}>{props.playlist.teacher?.firstName}</div>
                </div>
                <div className={"add-to-playlist-container"}>
                    <AddToPlaylist isSaved={props.isSaved} handleRemoveFromList={props.handleRemoveFromList} handleAddToList={props.handleAddToList}/>
                </div>
            </div>
        </div>
    )
}